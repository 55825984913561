import { IonSelectOption } from "@ionic/react";
import "./theme/style.css";

interface Props {
  options: any;
}

function genSelData(resdata: any) {
  const gendata = resdata;
  gendata.forEach((element: any) => {
    element["label"] = element.id;
    element["value"] = element.name;
  });
  return gendata;
}

function SelectOpt(label: any, value: any, index: number) {
  return (
    <IonSelectOption key={index} value={value}>
      {label}
    </IonSelectOption>
  );
}

function SelectOpts(options: any) {
  options = genSelData(options);
  return options.map((item: any, index: number) => {
    return SelectOpt(item.value, item.label, index);
  });
}

export default function SelectOptions(props: Props) {
  return SelectOpts(props.options);
}
