import { IonCol, IonGrid, IonPopover, IonRow } from "@ionic/react";
import "../Shared/theme/style.css";
import { useState } from "react";

export default function BenefitsHero() {
  const [showPopover, _showPopover] = useState(false);

  return (
    <div className="">
      <IonGrid class="ion-no-padding">
        <IonRow>
          <IonCol size="6">
            <div
              style={{
                fontWeight: 500,
                fontSize: 24,
                padding: "22px 0px 22px 22px",
                display: "inline-block",
              }}
            >
              Benefits+
            </div>
            <div
              id="click-trigger"
              style={{
                display: "inline-block",
                verticalAlign: "top",
                paddingTop: 16,
              }}
            >
              <div
                onClick={() => {
                  _showPopover(true);
                }}
                id="open-popover"
              >
                <i className="icon bi-exclamation-circle"></i>
              </div>

              <IonPopover
                onDidDismiss={() => {
                  _showPopover(false);
                }}
                isOpen={showPopover}
                side="bottom"
                trigger="open-popover"
              >
                <div
                  style={{ width: 50 }}
                  onClick={() => {
                    _showPopover(false);
                  }}
                >
                  <i className="icon bi-x-square"></i>
                </div>
                <div style={{ padding: 8 }}>
                  <div>
                    Benefits is where you can discover all the great benefits
                    that make life/work for you offered by your company.
                    <br />
                  </div>
                </div>
              </IonPopover>
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
}
