import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  IonListHeader,
} from "@ionic/react";
import _ from "lodash";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import "../Shared/theme/style.css";
import ActivityItem from "../Activity/ActivityItem";
import ActivityService, {
  GetActivityId,
  activityState,
} from "../Shared/services/ActivityService";
import { darkmodeState } from "../Shared/services/MemberService";
import { Activity } from "../Shared/types/activity";

interface Props {
  cardIndex: any;
}

export default function CardActivity(props: Props) {
  const [activities, _activities] = useRecoilState<any>(activityState);
  const [dark, _dark] = useRecoilState<any>(darkmodeState);
  const [page, _page] = useState<any>(1);
  const history = useHistory();

  useEffect(() => {
    // call api or anything
    ActivityService.getActivities().then((res) => {
      _activities(res.data);
    });
  }, []);

  function ActivityList(activities: any) {
    if (activities !== null) {
      const actdata = _.orderBy(
        activities,
        (x: Activity) => {
          return GetActivityId(x);
        },
        ["desc"]
      );
      return actdata
        .filter((x) => {
          return x.activityType && x.activityStatus?.name != "pending";
        })

        .map((activity: any, index: number) => {
          // console.log(activity);
          if (
            (activity &&
              activity.title &&
              props.cardIndex == 0 &&
              activity.cardType == 1) ||
            (props.cardIndex == 1 && activity.cardType == 2) ||
            (props.cardIndex == 2 && activity.activityType.isRegalos == true)
          ) {
            return (
              <ActivityItem
                activity={activity}
                activities={activities}
                avatar={false}
                key={index}
                itemClicked={(a: any, b: any) => {
                  history.push("/related-activity/" + a.id, {
                    activity: a,
                    activities: b,
                  });
                }}
              />
            );
          }
        });
    }
  }

  return (
    <IonList>
      {activities ? (
        <>
          {activities && activities.length == 0 ? (
            <>
              <div className="" style={{ padding: "4px 4px 4px 16px" }}>
                <Skeleton
                  style={{ marginTop: 10 }}
                  count={1}
                  height={24}
                  width={100}
                />
              </div>
              <div style={{ padding: 16 }}>
                <Skeleton style={{ marginTop: 6 }} count={10} height={60} />
              </div>
            </>
          ) : (
            <div
              style={{
                border: dark ? "1px solid black" : "",
                borderRadius: 10,
              }}
            >
              {ActivityList(activities)}
              <IonInfiniteScroll
                onIonInfinite={(e) => {
                  ActivityService.getActivities(page).then((res) => {
                    if (res.data.length > 0) {
                      _page(page + 1);
                      _activities(activities.concat(res.data));
                    }
                    e.target.complete();
                  });
                }}
                threshold="100px"
              >
                <IonInfiniteScrollContent
                  loadingText="Getting more..."
                  loadingSpinner="bubbles"
                ></IonInfiniteScrollContent>
              </IonInfiniteScroll>
            </div>
          )}
        </>
      ) : (
        <div style={{ padding: 16 }}>
          <Skeleton style={{ marginTop: 12 }} count={5} height={60} />
        </div>
      )}
    </IonList>
  );
}
