import { useHistory } from "react-router";
import "../Shared/theme/style.css";
import Avatar from "../Shared/Avatar";
import { Activity } from "../Shared/types/activity";
import { GetActivityId } from "../Shared/services/ActivityService";
import { IonAvatar } from "@ionic/react";
import { MxImg } from "../Shared/services/ImageService";

interface Props {
  activity: Activity;
  activities?: any;
  size?: number;
  itemClicked?: (activity: Activity, activities: any) => void;
}

export default function ActivityAvatar(props: Props) {
  const history = useHistory();

  return (
    <>
      <IonAvatar
        style={{
          width: props.size ? props.size : 56,
          height: props.size ? props.size : 56,
        }}
        onClick={() => {
          props.itemClicked &&
            props.itemClicked(props.activity, props.activities);
        }}
        slot="start"
        className="ActivityAvatar"
      >
        <>
          {props.activity &&
          props.activity.merchant &&
          props.activity.merchant.picture ? (
            <>
              <MxImg
                style={{
                  backgroundColor: "white",
                  width: "100%",
                  height: "100%",
                }}
                src={props.activity.merchant.picture}
              />
            </>
          ) : (
            <>
              {props.activity &&
              props.activity.merchant &&
              props.activity.merchant.name ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={props.size ? props.size : 56}
                  height={props.size ? props.size : 56}
                >
                  <text
                    x="50%"
                    y="50%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    fill="#B3BAC0"
                    fontSize={props.size ? props.size / 2 : 56 / 2}
                  >
                    {props.activity &&
                      props.activity.merchant &&
                      props.activity.merchant.name &&
                      props.activity.merchant.name.split(" ") &&
                      props.activity.merchant.name.split(" ")[0] &&
                      props.activity.merchant.name
                        .split(" ")[0][0]
                        .toUpperCase()}
                    {props.activity &&
                      props.activity.merchant &&
                      props.activity.merchant.name &&
                      props.activity.merchant.name.split(" ") &&
                      props.activity.merchant.name.split(" ")[1] &&
                      props.activity.merchant.name
                        .split(" ")[1][0]
                        .toUpperCase()}
                  </text>
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={props.size ? props.size : 56}
                  height={props.size ? props.size : 56}
                >
                  <text
                    x="50%"
                    y="55%"
                    dominantBaseline="middle"
                    textAnchor="middle"
                    fill="#B3BAC0"
                    fontSize={props.size ? props.size / 2 : 56 / 2}
                  >
                    {props.activity &&
                      props.activity.title &&
                      props.activity.title.split(" ") &&
                      props.activity.title.split(" ")[0] &&
                      props.activity.title.split(" ")[0][0].toUpperCase()}
                    {props.activity &&
                      props.activity.title &&
                      props.activity.title.split(" ") &&
                      props.activity.title.split(" ")[1] &&
                      props.activity.title.split(" ")[1][0].toUpperCase()}
                  </text>
                </svg>
              )}
            </>
          )}
        </>
      </IonAvatar>
    </>
  );
}
