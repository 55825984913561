import { IonButton, IonContent, IonPage, IonText } from "@ionic/react";
import "../Shared/theme/style.css";
import { useEffect, useState } from "react";
import EmployerService from "../Shared/services/EmployerService";
import ImageService from "../Shared/services/ImageService";

interface Props {
  nextStep?: any;
}

export default function OBIntro(props: Props) {
  const [employer, setEmployer] = useState<any>();

  useEffect(() => {
    EmployerService.currentEmployer().then((res) => {
      setEmployer(res.data);
    });
  }, []);

  const Continue = () => {
    props.nextStep();
  };

  return (
    <IonPage>
      <div className="main-container" style={{ paddingTop: 0 }}>
        <IonContent>
          <div
            style={{
              width: "100%",
              paddingTop: 30,
              backgroundColor: "#fcfcfc",
            }}
          >
            <div
              style={{
                width: "90%",
                marginRight: "auto",
                marginLeft: "auto",
                marginTop: 0,
                backgroundColor: "#fcfcfc",
              }}
            >
              <IonText>
                <h1 style={{ paddingTop: 30, textAlign: "center" }}>
                  Welcome to Maxwell
                </h1>
              </IonText>
              {employer && (
                <img
                  src={ImageService.Url(employer.logo)}
                  alt="logo"
                  style={{
                    display: "block",
                    maxWidth: 300,
                    maxHeight: 200,
                    margin: "0 auto",
                  }}
                />
              )}
              {employer && (
                <>
                  <div
                    style={{
                      maxWidth: 480,
                      margin: "12px auto",
                      border: "1.5px solid #000",
                      paddingLeft: 20,
                      paddingRight: 20,
                      borderRadius: 8,
                    }}
                  >
                    <p>
                      {employer.name.trim()} is providing you with Maxwell to
                      create many moments of joy.
                    </p>
                    <p>
                      With Maxwell, you can receive and give budget provided by{" "}
                      {employer.name.trim()}.
                    </p>
                    <p>
                      Whether it&apos;s a meal subscription or a new pair of
                      shoes using your Maxwell Visa Card, you have control over
                      how <i>you</i> want to use your budget.
                    </p>
                    <p>Let&apos;s get started with a brief onboarding.</p>
                  </div>
                  <IonButton onClick={Continue} expand="block" color="dark">
                    Get Started
                  </IonButton>
                </>
              )}
            </div>
          </div>
        </IonContent>
      </div>
    </IonPage>
  );
}
