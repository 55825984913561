import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";
import PlasticCard from "./PlasticCard";
import memberService from "../Shared/services/MemberService";
import { lockOpen } from "ionicons/icons";
import { useState } from "react";

interface Props {
  member: any;
  virtualCard: any;
  showCodeModal: (e: boolean) => void;
}

export default function SlideVirtualCard(props: Props) {
  const [cardActive, setCardActive] = useState(
    props.virtualCard.status == "active"
  );

  return (
    <>
      {" "}
      <div className="slide-card">
        <h3 style={{ textAlign: "left" }}>Virtual Card</h3>
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol sizeMd="6">
              <div style={{ minWidth: 200 }}>
                <PlasticCard
                  amount={props.member.balance}
                  last4={props.virtualCard?.last4}
                  type={"virtual"}
                  name={props.member.firstName + " " + props.member.lastName}
                />
              </div>
            </IonCol>
            <IonCol sizeMd="6">
              <div style={{ paddingTop: 14 }}>
                <IonButton
                  onClick={() => {
                    props.showCodeModal(true);
                    void memberService.sendSmsCode();
                  }}
                  expand="block"
                  color="dark"
                >
                  <IonIcon icon={lockOpen} />
                  &nbsp;&nbsp; Unlock Card
                </IonButton>
                <div style={{ paddingTop: 14 }}>
                  {props.virtualCard && (
                    <IonItem>
                      <IonLabel>
                        Card {cardActive ? "Active" : "Inactive"}
                      </IonLabel>
                      <IonButton
                        onClick={(e) => {
                          // console.log("e", e.target.checked);
                          if (!cardActive) {
                            setCardActive(true);
                            memberService
                              .activateCard(props.virtualCard.id)
                              .then((res: any) => {
                                // console.log("res", res);
                              });
                          } else {
                            setCardActive(false);
                            memberService
                              .deactivateCard(props.virtualCard.id)
                              .then((res: any) => {
                                // console.log("res", res);
                              });
                          }
                        }}
                        slot="end"
                        color={"dark"}
                        expand="block"
                      >
                        {!cardActive ? "Activate Card" : "Deactivate Card"}
                      </IonButton>
                    </IonItem>
                  )}
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
}
