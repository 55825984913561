import { IonButton, IonContent, IonPage, IonText } from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import "../Shared/theme/style.css";
import WizardProgress from "./WizardProgress";
import memberService from "../Shared/services/MemberService";
import MaxwellForm from "../Shared/services/MaxwellForm";

interface Props {
  prevStep?: any;
  nextStep?: any;
  handleChange: (input: any, e: any) => void;
  values?: any;
  linkable?: boolean;
}

export default function OBWorkAddress(props: Props) {
  const [address1, _address1] = useState<any>();
  const [address2, _address2] = useState<any>();
  const [city, _city] = useState<any>();
  const [state, _state] = useState<any>();
  const [zip, _zip] = useState<any>();
  const [member, _member] = useState<any>();

  const states = [
    { id: "AL", name: "AL" },
    { id: "AK", name: "AK" },
    { id: "AZ", name: "AZ" },
    { id: "AR", name: "AR" },
    { id: "CA", name: "CA" },
    { id: "CZ", name: "CZ" },
    { id: "CO", name: "CO" },
    { id: "CT", name: "CT" },
    { id: "DE", name: "DE" },
    { id: "DC", name: "DC" },
    { id: "FL", name: "FL" },
    { id: "GA", name: "GA" },
    { id: "GU", name: "GU" },
    { id: "HI", name: "HI" },
    { id: "ID", name: "ID" },
    { id: "IL", name: "IL" },
    { id: "IN", name: "IN" },
    { id: "IA", name: "IA" },
    { id: "KS", name: "KS" },
    { id: "KY", name: "KY" },
    { id: "LA", name: "LA" },
    { id: "ME", name: "ME" },
    { id: "MD", name: "MD" },
    { id: "MA", name: "MA" },
    { id: "MI", name: "MI" },
    { id: "MN", name: "MN" },
    { id: "MS", name: "MS" },
    { id: "MO", name: "MO" },
    { id: "MT", name: "MT" },
    { id: "NE", name: "NE" },
    { id: "NV", name: "NV" },
    { id: "NH", name: "NH" },
    { id: "NJ", name: "NJ" },
    { id: "NM", name: "NM" },
    { id: "NY", name: "NY" },
    { id: "NC", name: "NC" },
    { id: "ND", name: "ND" },
    { id: "OH", name: "OH" },
    { id: "OK", name: "OK" },
    { id: "OR", name: "OR" },
    { id: "PA", name: "PA" },
    { id: "PR", name: "PR" },
    { id: "RI", name: "RI" },
    { id: "SC", name: "SC" },
    { id: "SD", name: "SD" },
    { id: "TN", name: "TN" },
    { id: "TX", name: "TX" },
    { id: "UT", name: "UT" },
    { id: "VT", name: "VT" },
    { id: "VI", name: "VI" },
    { id: "VA", name: "VA" },
    { id: "WA", name: "WA" },
    { id: "WV", name: "WV" },
    { id: "WI", name: "WI" },
    { id: "WY", name: "WY" },
  ];

  useEffect(() => {
    memberService.getMember().then((res) => {
      _member(res.data);
    });
  }, []);

  // Form Validation Handling
  const [formErrors, _formErrors] = useState<any>([""]);
  function HandleInputErrors(errs: any) {
    const errors = formErrors.filter(
      (x: any) => x !== "" //|| x !== "Missing Required Fields"
    );
    if (errs.valid) {
      _formErrors(errors.filter((x: any) => x !== errs.error));
    } else {
      _formErrors([...errors, errs.error]);
    }
  }

  const history = useHistory();
  const Continue = (e: { preventDefault: () => void }) => {
    memberService
      .postAddress({
        name: "Work",
        address1,
        address2,
        city,
        state,
        zip,
        country: "US",
      })
      .then((res) => {
        if (res.data) {
          e.preventDefault();
          props.nextStep();
        }
      });
  };

  const Back = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    props.prevStep();
  };

  return (
    <IonPage>
      <div className="main-container">
        <IonContent>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "90%",
                marginRight: "auto",
                marginLeft: "auto",
                marginTop: 40,
              }}
            >
              <WizardProgress
                list={[
                  { icon: "bi-building", complete: true },
                  { icon: "bi-clipboard-heart" },
                  { icon: "bi-people" },
                  { icon: "bi-question" },
                ]}
              />
              <IonText>
                <h1 style={{ paddingTop: 30 }}>Where is work?</h1>
              </IonText>

              <MaxwellForm.Input
                label="Address"
                placeholder="Enter address"
                value={address1}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _address1(value);
                }}
              />

              <MaxwellForm.Input
                label="Address 2"
                placeholder="Enter address line 2"
                value={address2}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _address2(value);
                }}
              />

              <MaxwellForm.Input
                label="City"
                placeholder="Enter city"
                value={city}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _city(value);
                }}
              />

              <MaxwellForm.Select
                label="State"
                options={states}
                value={state}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _state(value);
                }}
              />

              <MaxwellForm.Input
                label="Zip Code"
                placeholder="Enter zip code"
                value={zip}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _zip(value);
                }}
              />
              <IonButton
                style={{ marginTop: 40 }}
                onClick={Continue}
                expand="block"
                color="light"
                class="ion-color ion-color-light ios button button-block button-solid ion-activatable ion-focusable"
              >
                Continue
              </IonButton>
              <IonButton
                style={{ marginTop: 30 }}
                className="backButton"
                expand="block"
                onClick={Back}
                color="light"
              >
                Back
              </IonButton>
              {formErrors.map((error: any) => {
                return (
                  <IonText key={error} color="danger" className="mw-center">
                    <p>{error}</p>
                  </IonText>
                );
              })}
            </div>
          </div>
        </IonContent>
      </div>
    </IonPage>
  );
}
