import { IonButton, IonIcon } from "@ionic/react";
import { animated, useSpring } from "@react-spring/web";
import { close } from "ionicons/icons";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import isMobile from "../Shared/services/IsMobile";
import "../Shared/theme/style.css";

interface Props {
  item?: any;
  closed: () => void;
}

export default function PartnerFeaturedItemPopUp(props: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(true);
  const [cords, setCords] = useState<any>();

  const history = useHistory();

  const [spring, springApi] = useSpring(() => ({
    from: { display: "block", x: 0, y: 0, scale: 1 },
    to: { display: "block", x: 0, y: 0 },
  }));

  // format amount in usd

  useEffect(() => {
    springApi.start({
      from: { display: "block", x: 16, y: 0, scale: 0.9 },
      to: { display: "block", x: 16, y: 0, scale: 1 },
      config: { friction: 50 },
    });
  }, []);

  function closeItem() {
    springApi.start({
      from: { display: "block", x: 16, y: 0, scale: 1 },
      to: {
        display: "block",
        x: 16,
        y: 0,
        scale: 0.9,
      },
      config: { friction: 30 },
    });

    setTimeout(() => {
      setIsOpen(false);
      props.closed();
      springApi.start({
        from: { display: "none" },
        to: {
          display: "none",
        },
      });
    }, 300);
  }

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <>
      <div
        style={{
          display: isOpen ? "block" : "none",
          paddingLeft: 24,
          paddingTop: 60,
          paddingRight: 24,
          position: "fixed",
          width: "100%",
          top: 70,
          left: 0,
          zIndex: 9,
        }}
      >
        <div
          style={{
            position: "fixed",
            top: 60,
            right: 0,

            width: "100vw",
            height: "100%",
          }}
          onClick={(e) => {
            closeItem();
          }}
        ></div>
        <animated.div
          style={{
            width: "calc(100% - 24px)",
            zIndex: 99999,
            ...spring,
          }}
        >
          <div
            style={{
              maxWidth: isMobile() ? 300 : 400,

              marginLeft: "auto",
              marginRight: "auto",
              borderRadius: 14,
              border: "1px solid black",
              borderBottom: "3px solid black",
              borderRight: "2px solid black",
              backgroundColor: "#fff",
              cursor: "pointer",
              zIndex: 9999,
              boxShadow: "0px 3px 15px -1px rgba(0,0,0,0.25)",
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 10,
                left: 10,
                fontWeight: 800,
                fontSize: 24,
              }}
              onClick={(e) => {
                closeItem();
              }}
            >
              <IonIcon icon={close} />
            </div>
            <div
              style={{
                flex: 2,
                backgroundImage: "url(" + props.item.picture + ")",
                backgroundSize: "cover",
                backgroundPosition: "center center",
                backgroundColor: "#fff",
                borderRadius: "14px 14px 0px 0px",
                minHeight: 200,
              }}
            ></div>
            <div
              style={{
                flex: 1,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 10,
              }}
            >
              <div style={{ fontWeight: 500, fontSize: 14, display: "flex" }}>
                <div style={{ flex: 1 }}>{props.item.name}</div>
                <div>{formatter.format(props.item.price * 0.01)}</div>
              </div>
              <div style={{ fontWeight: 400, fontSize: 12 }}>
                {props.item.description}
              </div>
              <IonButton
                onClick={(e) => {
                  setTimeout(() => {
                    const newCartLocal = localStorage.getItem("cart");
                    const newCart = [];
                    // if (newCartLocal) {
                    //   let parsedCart = JSON.parse(newCartLocal);
                    //   if (parsedCart && parsedCart[0]) {
                    //     newCart = parsedCart;
                    //   }
                    // }

                    newCart.push(props.item);

                    //console.log("add to cart", newCart);

                    localStorage.setItem("cart", JSON.stringify(newCart));
                    if (props.item.inputs || props.item.inputs) {
                      history.push("/partner_item_form/" + props.item.id);
                    } else {
                      history.push("/checkout/");
                    }
                  }, 500);
                }}
                color={"light"}
                expand="block"
              >
                Checkout
              </IonButton>
            </div>
          </div>
        </animated.div>
      </div>
    </>
  );
}
