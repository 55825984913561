import {
  IonButton,
  IonContent,
  IonItem,
  IonLabel,
  IonLoading,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonToggle,
  useIonViewDidEnter,
} from "@ionic/react";
import "../Shared/theme/style.css";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import _ from "lodash";
import MemberService, { memberState } from "../Shared/services/MemberService";

import { MxImg } from "../Shared/services/ImageService";
import RegalosService, {
  regalosActivityState,
} from "../Shared/services/RegalosService";
import MaxwellForm from "../Shared/services/MaxwellForm";
import { useRecoilState } from "recoil";
import { Member } from "../Shared/types/member";
import { CardHolder } from "../Shared/types/cardHolder";
import HeadSubpage from "../Nav/HeaderSubpage";

export default function RegalosSend() {
  const history = useHistory();
  const { id } = useParams<any>();
  const [member, _member] = useRecoilState<any>(memberState);
  const [toMember, _toMember] = useState<Member>();
  const [amount, _amount] = useState<any>(0);
  const [regalosAmount, _regalosAmount] = useState<any>(0);
  const [activities, _activities] = useRecoilState<any>(regalosActivityState);
  const [title, _title] = useState<any>();
  const [note, _note] = useState<any>();
  const [isPublic, _isPublic] = useState<any>(false);
  const [showLoading, _showLoading] = useState(false);
  const [cardHolder, _cardHolder] = useState<CardHolder>();

  useEffect(() => {
    MemberService.getMembers([id]).then((res: any) => {
      console.log(res.data[0]);
      _toMember(res.data[0]);
    });
    MemberService.getMember().then((res: any) => {
      // console.log(res.data.cardHolders[0]);
      _member(res.data);
      _cardHolder(res.data.cardHolders[0]);
    });
  }, []);

  function currency(number: any) {
    return _.round(parseFloat(number), 2).toFixed(2);
  }

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className="main-content">
        <div className="main-container leave-room-for-menu ion-padding">
          <div
            style={{
              justifySelf: "center",
              height: 200,
              width: 200,
              borderRadius: 10,
              border: "2px solid black",
              overflow: "hidden",
              marginLeft: "calc(50% - 100px)",
            }}
          >
            {toMember && toMember.picture && (
              <MxImg
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
                src={toMember?.picture}
              />
            )}
          </div>
          <div style={{ textAlign: "center" }}>
            <h3>
              {toMember?.firstName} {toMember?.lastName}
            </h3>
          </div>

          <IonItem lines="none">
            <IonLabel>
              <i className="bi bi-credit-card"></i>
              &nbsp; Regalos{" "}
              <div style={{ fontSize: 12 }}>
                Balance: $
                {member &&
                  member.regalosBalance &&
                  currency(member.regalosBalance * 0.01)}
              </div>
            </IonLabel>

            <IonLabel slot="end">
              <MaxwellForm.ItemDollars
                value={regalosAmount}
                onChange={(value) => _regalosAmount(value)}
              />
            </IonLabel>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>
              <i className="bi bi-credit-card-fill"></i>
              &nbsp; Max Card
              <div style={{ fontSize: 12 }}>
                Balance: $
                {member && member.balance && currency(member.balance * 0.01)}
              </div>
            </IonLabel>
            <IonLabel slot="end">
              <MaxwellForm.ItemDollars
                value={amount}
                onChange={(value) => _amount(value)}
              />
            </IonLabel>
          </IonItem>
          <IonItem>
            <IonLabel position="fixed">Label*</IonLabel>
            <IonSelect
              onIonChange={(e: any) => {
                _title(e.target.value);
              }}
              slot="end"
              placeholder="Select an option..."
              className="inplace"
              interface="action-sheet"
            >
              <IonSelectOption>Kudos</IonSelectOption>
              <IonSelectOption>Support</IonSelectOption>
              <IonSelectOption>Thank you</IonSelectOption>
              <IonSelectOption>Congratulations</IonSelectOption>
              <IonSelectOption>Act of Kindness</IonSelectOption>
            </IonSelect>
          </IonItem>
          <div style={{ marginTop: 20 }}>
            Message*
            <textarea
              onChange={(e: any) => {
                _note(e.target.value);
              }}
              placeholder="Message for recipient"
              rows={5}
              style={{
                width: "100%",
                resize: "none",
                borderRadius: 10,
                padding: 5,
                backgroundColor: "white !important",
                boxShadow: "",
              }}
            ></textarea>
          </div>
          <IonItem>
            Public
            <IonToggle
              slot="end"
              checked={isPublic}
              onIonChange={(e: any) => {
                _isPublic(e.detail.checked);
              }}
            ></IonToggle>
          </IonItem>

          <div style={{ paddingTop: 40, textAlign: "right", padding: 12 }}>
            {member &&
            member.regalosBalance &&
            regalosAmount < member.regalosBalance * 0.01 &&
            member.balance &&
            regalosAmount < member.balance * 0.01 &&
            (amount > 0.009 || regalosAmount > 0.009) &&
            title &&
            note ? (
              <IonButton
                onClick={() => {
                  _showLoading(true);
                  const regalos = {
                    amount: parseFloat(currency(amount)) * 100,
                    regalosAmount: parseFloat(currency(regalosAmount)) * 100,
                    title,
                    note,
                    isPublic,
                    receiverId: toMember?.id,
                  };
                  RegalosService.postRegalos(regalos).then((res) => {
                    _showLoading(false);
                    history.replace("/regalos");
                  });
                }}
                expand="block"
                color="dark"
              >
                Send
              </IonButton>
            ) : (
              <IonButton disabled expand="block" color="light">
                Missing Required
              </IonButton>
            )}
          </div>
        </div>
        <IonLoading isOpen={showLoading} />
      </IonContent>
    </IonPage>
  );
}
