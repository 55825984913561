import { IonButton } from "@ionic/react";
import { useState } from "react";
import { PaymentElement } from "@stripe/react-stripe-js";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import "../Shared/theme/style.css";
import { useHistory } from "react-router";
import OrderService from "../Shared/services/OrderService";

interface Props {
  onPaymentSuccess: () => void;
  fees?: number;
}

export default function AddFundsForm(props: Props) {
  const stripe = useStripe();
  const elements = useElements();

  const history = useHistory();

  const [isProcessing, _isProcessing] = useState<any>(false);

  return (
    <>
      <PaymentElement />
      {elements && stripe && (
        <div style={{ marginTop: 40 }}>
          <IonButton
            expand="block"
            disabled={isProcessing}
            color="dark"
            onClick={() => {
              try {
                _isProcessing(true);
                stripe
                  .confirmPayment({
                    elements,
                    redirect: "if_required",
                  })
                  .then((result) => {
                    if (result.error) {
                      alert(result.error.message);
                      _isProcessing(false);
                    } else {
                      alert("Payment Successful");
                    }

                    if (!result.error) {
                      // console.log(result);
                      OrderService.putPaymentIntent(
                        result.paymentIntent.id,
                        props.fees
                      )
                        .then((r) => {
                          // console.log(r);
                          _isProcessing(false);
                          props.onPaymentSuccess();
                        })
                        .catch((e) => {
                          _isProcessing(false);
                          alert(e.error.message);
                        });
                    }
                  })
                  .catch((e) => {
                    _isProcessing(false);
                    alert(e.error.message);
                  });
              } catch (e) {
                // console.log(e);
                alert(
                  "There was an error processing your payment. Please try again."
                );
              }
            }}
          >
            {isProcessing ? "Processing..." : "Add Funds"}
          </IonButton>
        </div>
      )}
    </>
  );
}
