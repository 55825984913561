import {
  IonButton,
  IonContent,
  IonIcon,
  IonPage,
  useIonViewDidEnter,
} from "@ionic/react";
import "../Shared/theme/style.css";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import BenefitService from "../Shared/services/BenefitService";
import ImageService from "../Shared/services/ImageService";
import partnerService from "../Shared/services/PartnerService";
import OrderService from "../Shared/services/OrderService";
import MaxwellForm from "../Shared/services/MaxwellForm";
import { useRecoilState } from "recoil";
import { balanceVisibleState } from "../Shared/services/ActivityService";
import { memberState } from "../Shared/services/MemberService";
import { Benefit as BenefitType } from "../Shared/types/benefit";
import HeadSubpage from "../Nav/HeaderSubpage";
import { logoPaypal, logoVenmo } from "ionicons/icons";

export default function Reimbursement() {
  const [item, _item] = useState<BenefitType>();
  const [file1, _file1] = useState<any>();
  const [file1Image, _file1Image] = useState<any>();
  const [file2, _file2] = useState<any>();
  const [file2Image, _file2Image] = useState<any>();
  const [file3, _file3] = useState<any>();
  const [file3Image, _file3Image] = useState<any>();
  const [member, _member] = useRecoilState<any>(memberState);
  const [amount, _amount] = useState<any>();
  const [date, _date] = useState<any>();
  const [receiveVenmo, _receiveVenmo] = useState<any>();
  const [receivePaypal, _receivePaypal] = useState<any>();
  const [provider, _provider] = useState<any>();
  const [isProcessing, _isProcessing] = useState<boolean>(false);
  const [accountType, _accountType] = useState<any>("venmo");
  const [balanceVisible, _balanceVisible] =
    useRecoilState<any>(balanceVisibleState);

  const [venmoOptions, _venmoOptions] = useState<any>([
    { id: "new", name: "New Venmo Account" },
  ]);
  const [paypalOptions, _paypalOptions] = useState<any>([
    { id: "new", name: "New Paypal Account" },
  ]);
  const [selectedOptions, _selectedOptions] = useState<any>([]);

  const history = useHistory();
  const { type, id } = useParams<any>();

  useEffect(() => {
    if (type == "benefit") {
      BenefitService.getBenefitItem(id).then((res) => {
        _item(res.data);
      });
    } else {
      partnerService.getPartnerItem(id).then((res) => {
        //console.log(res.data);
        _item(res.data);
      });
    }
    _balanceVisible(true);

    OrderService.getVenmoHandles().then((res) => {
      const options = res.data.map((x: any) => {
        return { id: x.handle, name: x.handle };
      });
      _venmoOptions([...options, { id: "new", name: "New Venmo Account" }]);
    });

    OrderService.getReimbursementAccounts().then((res) => {
      const options = res.data.map((x: any) => {
        return { id: x.handle, name: x.handle };
      });
      _paypalOptions([...options, { id: "new", name: "New Paypal Account" }]);
    });
  }, [id]);

  useIonViewDidEnter(() => {
    _balanceVisible(true);
  });

  // Form Validation Handling
  const [formErrors, _formErrors] = useState<any>([""]);
  function HandleInputErrors(errs: any) {
    const errors = formErrors.filter(
      (x: any) => x !== "" //|| x !== "Missing Required Fields"
    );
    if (errs.valid) {
      _formErrors(errors.filter((x: any) => x !== errs.error));
    } else {
      _formErrors([...errors, errs.error]);
    }
  }

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className="main-content">
        {item && (
          <div className="main-container ion-padding">
            <div style={{ paddingTop: 20 }}>
              <h2>{item.name}</h2>
              <p>{item.description}</p>
              <br />
              <MaxwellForm.Input
                label="How much did you spend?"
                placeholder="e.g. $12.34"
                type={"number"}
                required={true}
                value={amount}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _amount(value);
                }}
              />
              <MaxwellForm.DateTime
                label="When did it happen?"
                required={true}
                value={date}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _date(value);
                }}
              />
              <div style={{ marginTop: -15 }}>
                <MaxwellForm.Input
                  label="Who was the provider?"
                  placeholder="i.e. ABC Care"
                  required={true}
                  value={provider}
                  validation={(data: any) => {
                    HandleInputErrors(data);
                  }}
                  onChange={(value: string) => {
                    _provider(value);
                  }}
                />
              </div>
              <div style={{ textAlign: "center" }}>
                {!file1 ? (
                  <ImageService.Uploadable
                    selectedImage={(e: any) => {
                      if (e.target.files) {
                        //console.log(e.target.files[0]);
                        _file1(e.target.files[0]);
                        if (e.target.files && e.target.files[0]) {
                          _file1Image({
                            data: URL.createObjectURL(e.target.files[0]),
                          });
                        }
                      }
                    }}
                  >
                    <div
                      style={{
                        border: "1px solid black",
                        marginTop: 40,
                        borderRadius: 10,
                        height: 50,
                        backgroundColor: "#ccc",
                        textAlign: "center",
                        fontWeight: "bold",
                        color: "#888",
                        paddingTop: 15,
                      }}
                    >
                      Receipt Uploaded
                    </div>{" "}
                  </ImageService.Uploadable>
                ) : (
                  <>
                    {/* preview uploaded image */}
                    <div
                      style={{
                        width: 100,
                        height: 100,
                        border: "1px solid black",
                        marginTop: 2,
                        borderRadius: 10,
                        overflow: "hidden",
                        backgroundImage: "url(" + file1Image.data + ")",
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        display: "inline-block",
                        marginRight: 10,
                      }}
                    ></div>
                  </>
                )}

                {file1 && (
                  <>
                    {!file2 ? (
                      <ImageService.Uploadable
                        selectedImage={(e: any) => {
                          if (e.target.files) {
                            //console.log(e.target.files[0]);
                            _file2(e.target.files[0]);
                            if (e.target.files && e.target.files[0]) {
                              _file2Image({
                                data: URL.createObjectURL(e.target.files[0]),
                              });
                            }
                          }
                        }}
                      >
                        <div
                          style={{
                            border: "1px solid black",
                            marginTop: 10,
                            borderRadius: 10,
                            height: 50,
                            backgroundColor: "#ccc",
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "#888",
                            paddingTop: 15,
                          }}
                        >
                          Upload Another
                        </div>
                      </ImageService.Uploadable>
                    ) : (
                      <>
                        {/* preview uploaded image */}
                        <div
                          style={{
                            width: 100,
                            height: 100,
                            border: "1px solid black",
                            marginTop: 2,
                            borderRadius: 10,
                            overflow: "hidden",
                            backgroundImage: "url(" + file2Image.data + ")",
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            display: "inline-block",
                            marginRight: 10,
                          }}
                        ></div>
                      </>
                    )}
                  </>
                )}
                {file2 && (
                  <>
                    {!file3 ? (
                      <ImageService.Uploadable
                        selectedImage={(e: any) => {
                          if (e.target.files) {
                            //console.log(e.target.files[0]);
                            _file3(e.target.files[0]);
                            if (e.target.files && e.target.files[0]) {
                              _file3Image({
                                data: URL.createObjectURL(e.target.files[0]),
                              });
                            }
                          }
                        }}
                      >
                        <div
                          style={{
                            border: "1px solid black",
                            marginTop: 10,
                            borderRadius: 10,
                            height: 50,
                            backgroundColor: "#ccc",
                            textAlign: "center",
                            fontWeight: "bold",
                            color: "#888",
                            paddingTop: 15,
                          }}
                        >
                          Upload Another
                        </div>
                      </ImageService.Uploadable>
                    ) : (
                      <>
                        {/* preview uploaded image */}
                        <div
                          style={{
                            width: 100,
                            height: 100,
                            border: "1px solid black",
                            marginTop: 2,
                            borderRadius: 10,
                            overflow: "hidden",
                            backgroundImage: "url(" + file3Image.data + ")",
                            backgroundSize: "contain",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            display: "inline-block",
                          }}
                        ></div>
                      </>
                    )}
                  </>
                )}
              </div>
              <br />
              <div>
                {" "}
                <IonButton
                  size="small"
                  color={accountType == "venmo" ? "dark" : "light"}
                  onClick={() => {
                    _selectedOptions(venmoOptions[0].id);
                    _accountType("venmo");
                  }}
                >
                  <IonIcon icon={logoVenmo} /> &nbsp; Venmo
                </IonButton>
                <IonButton
                  size="small"
                  color={accountType == "paypal" ? "dark" : "light"}
                  onClick={() => {
                    _selectedOptions(paypalOptions[0].id);
                    _accountType("paypal");
                  }}
                >
                  <IonIcon icon={logoPaypal} /> &nbsp; Paypal
                </IonButton>
              </div>
              {accountType == "venmo" && (
                <MaxwellForm.Select
                  label="Venmo Handle"
                  options={venmoOptions}
                  value={selectedOptions}
                  required={true}
                  validation={(data: any) => {
                    HandleInputErrors(data);
                  }}
                  onChange={(value: any) => {
                    if (value !== "new") {
                      _receiveVenmo(value);
                    }
                    _selectedOptions(value);
                  }}
                />
              )}
              {accountType == "paypal" && (
                <MaxwellForm.Select
                  label="Paypal Account"
                  options={paypalOptions}
                  value={selectedOptions}
                  required={true}
                  validation={(data: any) => {
                    HandleInputErrors(data);
                  }}
                  onChange={(value: any) => {
                    if (value !== "new") {
                      _receivePaypal(value);
                    }
                    _selectedOptions(value);
                  }}
                />
              )}
              {selectedOptions == "new" && accountType == "venmo" && (
                <MaxwellForm.Input
                  label="Venmo Handle"
                  required={true}
                  value={receiveVenmo}
                  validation={(data: any) => {
                    HandleInputErrors(data);
                  }}
                  onChange={(value: string) => {
                    _receiveVenmo(value);
                  }}
                />
              )}
              {selectedOptions == "new" && accountType == "paypal" && (
                <MaxwellForm.Input
                  label="Paypal Account"
                  required={true}
                  value={receivePaypal}
                  validation={(data: any) => {
                    HandleInputErrors(data);
                  }}
                  onChange={(value: string) => {
                    _receivePaypal(value);
                  }}
                />
              )}
              <br />
              <IonButton
                disabled={
                  isProcessing ||
                  !file1 ||
                  !amount ||
                  !provider ||
                  formErrors.length > 0 ||
                  amount > member.balance + member.regalosBalance
                }
                onClick={() => {
                  _isProcessing(true);
                  const formData = new FormData();
                  file1 && formData.append("file1", file1);
                  file2 && formData.append("file2", file2);
                  file3 && formData.append("file3", file3);

                  if (type == "benefit") {
                    formData.append("benefitItemId", item.id.toString());
                  } else {
                    formData.append("partnerItemId", item.id.toString());
                  }

                  formData.append("amount", amount);
                  formData.append("provider", provider);
                  formData.append("receiveVenmo", receiveVenmo);
                  formData.append("receivePaypal", receivePaypal);
                  formData.append(
                    "date",
                    date ? date : new Date().toISOString()
                  );
                  OrderService.postReimbursement(formData).then((res) => {
                    //console.log(res);
                    _isProcessing(false);
                    history.goBack();
                  });
                }}
                color="light"
                expand="block"
              >
                {isProcessing ? "Processing..." : "Submit"}
              </IonButton>
              {formErrors && formErrors.length > 0 && (
                <div style={{ color: "red" }}>
                  {formErrors.map((x: any, index: number) => (
                    <div key={index}>{x}</div>
                  ))}
                </div>
              )}
            </div>
            {amount * 100 > member.balance + member.regalosBalance && (
              <div style={{ color: "red" }}>
                Amount cannot be greater than your balance
              </div>
            )}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
}
