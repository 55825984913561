import axios from "axios";
import { atom } from "recoil";

const APIURL = process.env.REACT_APP_API_HOST;

const LocationService = {
  async getLocation(id: number) {
    return axios.get(`${APIURL}/Locations/GetLocation/${id}`);
  },
  async getLocations() {
    return axios.get(`${APIURL}/Locations/GetLocations`);
  },
  async setLocation(id: number) {
    return axios.put(`${APIURL}/Locations/SetLocation/${id}`);
  },
};

export default LocationService;
