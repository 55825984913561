import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
} from "@ionic/react";
import "../Shared/theme/style.css";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import _ from "lodash";
import MemberService, { memberState } from "../Shared/services/MemberService";
import RegalosService from "../Shared/services/RegalosService";
import { useRecoilState } from "recoil";
import HeadSubpage from "../Nav/HeaderSubpage";
import Lottie from "lottie-react";
import cartLottie from "../Shared/lottie/84952-transfer-money.json";
import memberService from "../Shared/services/MemberService";

export default function RegalosTransfer() {
  const history = useHistory();
  const [member, _member] = useRecoilState<any>(memberState);
  const [amount, _amount] = useState<any>(0);
  const [regalosBalance, _regalosBalance] = useState<number>(0);
  const [showTransferModal, _showTransferModal] = useState(false);

  useEffect(() => {
    MemberService.getMember().then((res: any) => {
      _member(res.data);
      const x: number = parseFloat(res.data.regalosBalance) * 0.01;
      _regalosBalance(x);
    });
  }, []);

  function currency(number: number) {
    return _.round(number, 2).toFixed(2);
  }

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className="main-content">
        <div className="main-container leave-room-for-menu ion-padding">
          <h3 style={{ paddingTop: 20 }}>Transfer Budget</h3>
          <p style={{ fontWeight: "bold" }}>
            Transfer your Regalos to your Maxwell Card and use them on the go
          </p>
          <p>
            Once transferred, the funds will follow the same rules and
            expiration as your Maxwell Card. It is best to transfer when you are
            ready to use them.
          </p>

          <IonItem>
            <IonLabel slot="end">${currency(regalosBalance - amount)}</IonLabel>
            <IonLabel slot="start">
              Regalos&nbsp;&nbsp;&nbsp;
              <i className="bi bi-credit-card"></i>
            </IonLabel>
          </IonItem>

          <IonItem lines="none" style={{ width: "100%" }}>
            <IonLabel slot="start">Transfer to Maxwell Card</IonLabel>
            {member && member.regalosBalance && (
              <IonInput
                type="number"
                onIonChange={(e: any) => {
                  _amount(currency(e.target.value));
                }}
                max={currency(member?.regalosBalance * 0.01)}
                style={{ textAlign: "right" }}
                placeholder="0.00"
              ></IonInput>
            )}
          </IonItem>
          <IonItem>
            <IonLabel slot="end">
              $
              {member &&
                member.balance &&
                (
                  parseFloat(currency(member.balance * 0.01)) +
                  (amount | 0)
                ).toFixed(2)}
            </IonLabel>
            <IonLabel slot="start">
              Max Card&nbsp;&nbsp;&nbsp;
              <i className="bi bi-credit-card-fill"></i>
            </IonLabel>
          </IonItem>
          <div style={{ marginTop: 30, textAlign: "right", padding: 12 }}>
            {member &&
            member.regalosBalance &&
            member.regalosBalance >= amount * 100 &&
            amount > 0 ? (
              <IonButton
                onClick={() => {
                  _showTransferModal(true);
                  RegalosService.postTransfer(Math.floor(amount * 100)).then(
                    (res) => {
                      const newMember = structuredClone(member);
                      newMember.balance = newMember.balance + res.data.amount;
                      newMember.regalosBalance =
                        newMember.regalosBalance - res.data.amount;
                      _member(newMember);
                      memberService.balanceMember().then(() => {
                        memberService.getMember().then((res) => {
                          _member(res.data);
                          _showTransferModal(false);
                          setTimeout(() => {
                            history.goBack();
                          }, 300);
                        });
                      });
                    }
                  );
                }}
                expand="block"
                color="dark"
              >
                Transfer
              </IonButton>
            ) : (
              <>
                {amount < 0 ? (
                  <div
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      marginTop: 20,
                    }}
                  >
                    Enter Amount To Transfer
                  </div>
                ) : (
                  <div
                    style={{
                      fontWeight: "bold",
                      textAlign: "center",
                      marginTop: 20,
                      color: "#ED3213",
                    }}
                  >
                    Enter a value less than &nbsp;$
                    {member &&
                      member.regalosBalance &&
                      currency(member.regalosBalance * 0.01)}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <IonModal isOpen={showTransferModal}>
          <IonContent fullscreen className="main-content ">
            <div className="main-container leave-room-for-menu ion-padding">
              <div style={{ paddingTop: 20, textAlign: "center" }}>
                <Lottie animationData={cartLottie} loop={true} />
                <h1>Moving Money!</h1>
                <div style={{ paddingTop: 30 }}>
                  We are working on moving that money now!
                </div>

                <div style={{ fontWeight: "bold", paddingTop: 30 }}>
                  Here&apos;s to making <br />
                  life/work with you.
                </div>
                <div style={{ paddingTop: 30 }}></div>
              </div>
            </div>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
}
