import axios from "axios";
import { notifications } from "ionicons/icons";
import { atom } from "recoil";

const APIURL = process.env.REACT_APP_API_HOST;

export const notificationsState = atom({
  key: "notificationsState", // unique ID (with respect to other atoms/selectors)
  default: [],
});

const NotificationService = {
  async getNotifications() {
    return axios.get(`${APIURL}/Notifications/GetNotifications`);
  },
  async getNotification(id: any) {
    return axios.get(`${APIURL}/Notifications/GetNotification/` + id);
  },
  async notificationSeen(id: any) {
    return axios.put(`${APIURL}/Notifications/SeenNotification/` + id, {});
  },
  async notificationRead(id: any) {
    return axios.put(`${APIURL}/Notifications/ReadNotification/` + id, {});
  },
  //SeenAllNotifications
  async seenAllNotifications() {
    return axios.put(`${APIURL}/Notifications/SeenAllNotifications`, {});
  },
  //SendTestSMS
  async sendTestSMS() {
    return axios.get(`${APIURL}/Notifications/SendTestSMS`, {});
  },
};

export default NotificationService;
