import {
  IonChip,
  IonContent,
  IonItemDivider,
  IonList,
  IonPage,
} from "@ionic/react";
import "../Shared/theme/style.css";
import { useParams } from "react-router";
import _ from "lodash";
import { useEffect, useState } from "react";
import ActivityService from "../Shared/services/ActivityService";
import { Activity } from "../Shared/types/activity";
import ActivityItem from "./ActivityItem";
import HeadSubpage from "../Nav/HeaderSubpage";
import ActivityDetails from "./ActivityDetails";

export default function RelatedActivity() {
  const [activity, _activity] = useState<any>();
  const [activities, _activities] = useState<any>();
  const [title, _title] = useState<any>();
  const { id } = useParams<any>();

  useEffect(() => {
    ActivityService.getActivity(id).then((res) => {
      const act: Activity = res.data[0];
      _activity(act);
      _title(act.title);
      if (act.merchant) {
        ActivityService.getMerchantActivities(act.merchant.id).then((res) => {
          _activities(res.data);
        });
      }
    });
  }, []);

  function ActivityList(activities: any) {
    if (activities !== null) {
      const actdata = _.orderBy(activities, ["date", "asc"]);
      return actdata.slice(0, 5).map((activity: any, index: number) => {
        if (activity && activity.title) {
          return (
            <ActivityItem
              activity={activity}
              activities={activities}
              avatar={false}
              key={index}
              itemClicked={(a: any, b: any) => {
                _activity(a);
                _title(a.title);
              }}
            />
          );
        }
      });
    }
  }

  function TagList(activity: any) {
    if (activity && activity.activityTags && activity.activityTags[0]) {
      return activity.activityTags.map((at: any, index: number) => {
        return <IonChip key={index}>{at.tag.name}</IonChip>;
      });
    }
  }

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className="main-content">
        <div className="main-container leave-room-for-menu">
          <ActivityDetails activity={activity} clicked={() => null} />
          <IonList>
            <IonItemDivider>Related Activity</IonItemDivider>
            {activities && ActivityList(activities)}
          </IonList>
        </div>
      </IonContent>
    </IonPage>
  );
}
