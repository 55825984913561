import { IonAlert } from "@ionic/react";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";

const APIURL = process.env.REACT_APP_API_HOST;

export function MxImg(props: any) {
  let src = props.src;

  if (src && !src.includes("://")) {
    src = ImageService.Url(props.src);
  }

  if (props.params) {
    const srcUrl = new URL(src);
    for (const [key, value] of Object.entries(props.params)) {
      srcUrl.searchParams.append(key, value as string);
    }
    src = srcUrl.toString();
  }

  return (src && <img {...props} src={src} />) || <Skeleton height={"100%"} />;
}

const ImageService = {
  Url(id: string) {
    // cache image by url

    if (id && id.includes("://")) {
      return id;
    }
    return id
      ? APIURL + "/Image/File/" + id
      : "https://api.maxwell.community/api/Image/File/DefaultProfilePicture.png";
  },
  Uploadable(props: any) {
    const [img, setImg] = useState<React.ChangeEvent<HTMLInputElement> | null>(
      null
    );
    return (
      <div
        style={{
          position: "relative",
          height: "100%",
          width: "100%",
        }}
      >
        <input
          type="file"
          accept="image/*"
          style={{
            opacity: 0,
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            zIndex: 100,
            backgroundColor: "purple",
          }}
          onChange={(e) => {
            if (props.enablePreview) {
              setImg(e);
            } else {
              props.selectedImage(e);
            }
          }}
        />
        <IonAlert
          isOpen={!!img}
          header={"Preview your document"}
          message={`<img src="${
            img?.target.files?.[0] && URL.createObjectURL(img.target.files[0])
          }" alt="g-maps" style="border-radius: 2px">`}
          buttons={[
            {
              text: "Cancel",
              role: "cancel",
              handler: () => {
                setImg(null);
              },
            },
            {
              text: "Upload",
              role: "confirm",
              handler: () => {
                props.selectedImage(img);
                setImg(null);
              },
            },
          ]}
        />
        {props.children}
      </div>
    );
  },
};

export default ImageService;
