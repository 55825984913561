import {
  IonContent,
  IonPage,
  useIonAlert,
  useIonViewDidLeave,
} from "@ionic/react";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import HeadSubpage from "../Nav/HeaderSubpage";
import { balanceVisibleState } from "../Shared/services/ActivityService";
import { announcementsState } from "../Shared/services/AnnouncementService";
import EmployerService, {
  employerState,
} from "../Shared/services/EmployerService";
import MemberService, {
  darkmodeState,
  memberState,
} from "../Shared/services/MemberService";
import { notificationsState } from "../Shared/services/NotificationService";
import Util, { featureFlagState } from "../Shared/services/UtilService";
import { features } from "../Shared/services/features";
import { Member } from "../Shared/types/member";
import CONFIG from "../config";
import HomeActivity from "./HomeActivity";
import HomeAnnouncements from "./HomeAnnouncements";
import HomeBalance from "./HomeBalance";
import Joyride from "react-joyride";
import { BeaconComponent, tourLocale, tourSteps, tourStyle } from "./HomeTour";

function Home() {
  const [member, _member] = useRecoilState<any>(memberState);
  const [image, _image] = useState();
  const [userStatus, _userStatus] = useState(0);
  const history = useHistory();
  const [dark, _dark] = useRecoilState(darkmodeState);
  const [balanceMember, _balanceMember] = useState(true);
  const [notifications, setNotifications] =
    useRecoilState<any>(notificationsState);

  const [balanceVisible, _balanceVisible] =
    useRecoilState<any>(balanceVisibleState);
  const [employer, _employer] = useRecoilState<any>(employerState);
  const [hideAnnouncements, _hideAnnouncements] = useState(false);
  const [announcements, _announcements] =
    useRecoilState<any>(announcementsState);
  const [presentAlert] = useIonAlert();
  const [featureFlags, setFeatureFlags] = useRecoilState(featureFlagState);
  const [run, setRun] = useState(false);

  useEffect(() => {
    if (featureFlags == null) {
      Util.featureFlags().then((res) => {
        setFeatureFlags(res);
      });
    }

    features(employer);

    if (balanceMember) {
      _balanceMember(false);
      MemberService.balanceMember().then((res) => {
        if (res.data.current_cardholder_id == null) {
          MemberService.balanceMember().then((res) => {
            //_member(res.data);
            if (announcements && announcements.length < 0) {
              _hideAnnouncements(true);
              setTimeout(() => {
                _hideAnnouncements(false);
              }, 500);
            }
          });
        }
      });
    }

    if (!employer || employer.name == null) {
      EmployerService.currentEmployer().then((res) => {
        //console.log("currentEmployer", res.data);
        _employer(res.data);
      });
    }

    if (!member || member.id == null || member.balance == null) {
      MemberService.getMember().then((res) => {
        if (!res.data.accountPinSet) {
          history.push("/setup-pin");
        }
        _member(res.data);
        const m: Member = res.data;
        window.heap.addEventProperties({ env: process.env.NODE_ENV });
        window.heap.identify(m.id.toString());
        window.heap.addUserProperties({
          email: m.emailAddress,
          employer: m.currentMemberEmployerId,
          name: m.firstName + " " + m.lastName,
          locationId: m.locationId,
          version: CONFIG.APPVERSION,
          env: process.env.NODE_ENV,
        });

        if (res.data.primaryAddressId == null) {
          presentAlert({
            header: "A primary address is required",
            buttons: ["Take me there"],
            onDidDismiss: () => {
              history.push("/addresses");
            },
          });
        }
      });
    }

    MemberService.getOnboardingStatus("HomePage").then((res) => {
      if (!res.data) {
        setRun(true);
      }
    });
  }, [member, balanceMember]);

  useIonViewDidLeave(() => {
    _balanceVisible(false);
  });

  return (
    <IonPage>
      {userStatus !== 3 ? (
        <>
          <HeadSubpage />

          <IonContent fullscreen className="main-content">
            <div className="main-container">
              <div
                style={{
                  position: dark ? "sticky" : "relative",
                  top: 0,
                }}
              >
                <Joyride
                  steps={tourSteps}
                  run={run}
                  locale={tourLocale}
                  styles={tourStyle}
                  callback={(data) => {
                    if (data.status === "finished") {
                      MemberService.updateOnboardingStatus("HomePage");
                    }
                  }}
                  beaconComponent={BeaconComponent}
                  scrollToFirstStep
                  continuous
                />
                <div id="the-big-number">
                  {(member && member.balance != null && <HomeBalance />) || (
                    <>
                      <div
                        style={{
                          width: "100%",
                          margin: 0,
                          display: "inline-block",
                        }}
                      >
                        <div
                          style={{
                            display: "inline-block",
                            verticalAlign: "top",
                            marginLeft: 20,
                            marginRight: 10,
                            marginTop: 20,
                            marginBottom: 20,
                          }}
                        >
                          <Skeleton height={102} width={120} />
                        </div>
                        <div
                          style={{
                            display: "inline-block",
                            marginTop: 20,
                            verticalAlign: "top",
                          }}
                        >
                          <Skeleton height={64} width={120} />
                          <Skeleton height={24} width={120} />
                        </div>
                      </div>
                      <div
                        style={{
                          width: "49%",
                          display: "inline-block",
                          verticalAlign: "top",
                          textAlign: "right",
                        }}
                      >
                        {/* <div
                        style={{
                          display: "inline-block",
                          verticalAlign: "top",
                          marginLeft: 20,
                          marginRight: 10,
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                      >
                        <Skeleton height={102} width={220} /> 
                      </div> */}
                      </div>
                    </>
                  )}
                </div>
                {/* {member ? (
                  <div
                    style={{
                      paddingLeft: 20,
                      paddingBottom: 8,
                      marginTop: dark ? -42 : -16,
                      color: dark ? "white" : "black",
                      backgroundColor: dark ? "#1c1c1c" : "white",
                    }}
                  >
                    {!dark && <>Hello, {member.firstName}</>}
                  </div>
                ) : (
                  <div
                    style={{
                      margin: 10,
                      width: 160,
                      marginTop: -20,
                      marginBottom: 9,
                      marginLeft: 20,
                    }}
                  >
                    <Skeleton height={20} style={{ marginTop: -16 }} />
                  </div>
                )} */}
                <div
                  id="home-announcements-container"
                  style={{
                    backgroundColor: dark ? "white" : "white",
                    marginBottom: -10,
                    paddingBottom: 10,
                  }}
                >
                  {!hideAnnouncements && <HomeAnnouncements />}
                </div>
              </div>
              <div
                id="home-activity-container"
                style={{
                  borderRadius: dark ? 10 : 0,
                  overflow: "hidden",
                  zIndex: "999 !important",
                }}
              >
                <HomeActivity />
              </div>
            </div>
          </IonContent>
        </>
      ) : (
        <>
          {/* move this to a page */}
          <div style={{ textAlign: "center" }}>
            <div style={{ marginTop: 50, fontSize: 34 }}>Hold tight. </div>
            <p>We have not created your account yet. </p>
            Check back later
          </div>
        </>
      )}
    </IonPage>
  );
}

export default Home;
