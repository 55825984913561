import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonToggle,
} from "@ionic/react";
import PlasticCard from "./PlasticCard";
import {
  addCircleOutline,
  giftOutline,
  lockOpen,
  shareOutline,
} from "ionicons/icons";
import { useHistory } from "react-router";

interface Props {
  member: any;
  regalos: number;
}

export default function SlideRegalosCard(props: Props) {
  const history = useHistory();
  return (
    <>
      <div className="slide-card">
        <h3 style={{ textAlign: "left" }}>Regalos Budget</h3>
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol sizeMd="6">
              <div style={{ minWidth: 200 }}>
                <PlasticCard
                  amount={props.regalos}
                  type={"regalos"}
                  name={props.member.firstName + " " + props.member.lastName}
                />
              </div>
            </IonCol>
            <IonCol sizeMd="6">
              <div style={{ paddingTop: 8 }}>
                <div style={{ paddingTop: 4, fontSize: 14, marginBottom: 12 }}>
                  These are your personal funds. Use on any of Maxwell support
                  services. They never expire.
                </div>
                <div style={{ paddingTop: 8 }}>
                  <IonButton
                    onClick={() => {
                      history.push("/regalos-transfer");
                    }}
                    expand="block"
                    color="dark"
                  >
                    <IonIcon icon={shareOutline} />
                    &nbsp;&nbsp; Transfer to Maxwell Card
                  </IonButton>
                </div>
                <div style={{ paddingTop: 4 }}>
                  <IonButton
                    onClick={() => {
                      history.push("/addfundsamount");
                    }}
                    expand="block"
                    color="light"
                  >
                    <IonIcon icon={addCircleOutline} />
                    &nbsp;&nbsp; Add More Funds
                  </IonButton>
                </div>
                <div
                  className={
                    "i " +
                    (localStorage.getItem("hideRegalosTab") == "true" && "hide")
                  }
                  style={{ paddingTop: 4 }}
                >
                  <IonButton
                    onClick={() => {
                      history.push("/members");
                    }}
                    expand="block"
                    color="light"
                  >
                    <IonIcon icon={giftOutline} />
                    &nbsp;&nbsp; Send Regalos
                  </IonButton>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
}
