import {
  IonButton,
  IonContent,
  IonFab,
  IonFabButton,
  IonHeader,
  IonIcon,
  IonPage,
  IonTitle,
  IonToolbar,
  useIonViewDidEnter,
} from "@ionic/react";
import { add, arrowBack } from "ionicons/icons";
import "../Shared/theme/style.css";
import { useState } from "react";
import { useHistory } from "react-router";
import MemberService, { memberState } from "../Shared/services/MemberService";
import { useRecoilState } from "recoil";
import AddressItem from "./AddressItem";
import HeadSubpage from "../Nav/HeaderSubpage";

export default function Addresses() {
  // const [segment, _segment] = useState<any>("card");
  const [addresses, _addresses] = useState<any>();
  const [member, _member] = useRecoilState<any>(memberState);
  const history = useHistory();

  useIonViewDidEnter(() => {
    MemberService.getMember().then((res) => {
      _member(res.data);
    });

    MemberService.getAddresses().then((res) => {
      _addresses(res.data);
      // addresses && console.log(addresses);
    });
  });

  function AddressList() {
    if (addresses !== null) {
      return addresses.map((address: any, index: number) => {
        return (
          <AddressItem
            isPrimary={address.id == member.primaryAddressId}
            address={address}
            index={index}
            key={index}
            reload={() => {
              MemberService.getAddresses().then((res) => {
                //addresses && console.log(addresses);
                _addresses(res.data);
              });
            }}
          />
        );
      });
    }
  }

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className="main-content">
        <div className="main-container leave-room-for-menu ion-padding">
          <div style={{ paddingTop: 24 }}>
            <h3> Your Addresses</h3>
            {addresses && AddressList()}
          </div>
          <div style={{ paddingTop: 24 }}>
            <IonButton
              expand="block"
              color="light"
              onClick={() => {
                history.push("/address", { replace: true });
              }}
            >
              Add Address
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
