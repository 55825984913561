import { IonPage } from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router";
import ImageService from "../../Shared/services/ImageService";
import isMobile from "../../Shared/services/IsMobile";

interface Props {
  regalosGenericTags: any;
  regalosTags: any;
  onSelect: (id: number) => void;
  member: any;
}

export default function Regalos_Sent_Modal(props: Props) {
  const [regalosTags, setRegalosTags] = useState<any>(props.regalosTags);
  const [regalosGenericTags, setRegalosGenericTags] = useState<any>(
    props.regalosGenericTags
  );

  // id param from url

  const history = useHistory();

  function OptionBox(options: {
    title: string;
    image: string;
    color: string;
    id: number;
  }) {
    return (
      <div
        style={{
          padding: 8,
          flex: 1,
          width: 160,
          maxWidth: 140,
          textAlign: "left",
          display: "flex",
          flexDirection: "column",
        }}
        onClick={() => {
          props.onSelect(options.id);
        }}
      >
        <div
          style={{
            alignSelf: "end",
            justifySelf: "end",
            width: 120,
            height: 120,
            border: "1px solid black",
            borderRadius: 10,
            margin: "auto",
            backgroundImage: "url(" + ImageService.Url(options.image) + ")",
            backgroundSize: "contain",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            backgroundColor: options.color,
          }}
        ></div>
        <div
          style={{
            fontSize: 12,
            flex: 1,
            justifyContent: "end",
            paddingLeft: 0,
            paddingRight: 10,
            paddingTop: 4,
            flexDirection: "column-reverse",
            color: "black !important",
          }}
        >
          {options.title}
        </div>
      </div>
    );
  }

  return (
    <IonPage>
      <div
        style={{
          height: "100vh",
          width: "100vw",
          maxWidth: 900,
          backgroundColor: "#fcfcfc",
          borderRadius: 0,
          borderLeft: isMobile() ? "0px solid white" : "2px solid black",
          borderRight: isMobile() ? "0px solid white" : "2px solid black",
          boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.75)",
          padding: 18,
          textAlign: "center",
          overflow: "hidden",
          paddingTop: 80,
          marginLeft: "auto",
          marginRight: "auto",
          color: "black !important",
        }}
      >
        <div
          style={{
            position: "relative",
            height: 130,
          }}
        >
          {" "}
          <div
            style={{
              border: "2px solid black",
              padding: 16,
              borderRadius: 10,
              height: 120,
              backgroundColor: "#acd5c9",
              position: "absolute",
              top: 6,
              left: 5,
              width: "100%",
              boxShadow: "0px 0px 14px 0px rgba(0,0,0,0.25)",
            }}
          ></div>
          <div
            style={{
              border: "2px solid black",
              padding: 16,
              paddingLeft: 12,
              paddingRight: 12,
              paddingTop: 0,
              borderRadius: 10,
              height: 120,
              textAlign: "left",
              backgroundColor: "white",
              zIndex: 999,
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              fontSize: 14,
              color: "black !important",
            }}
          >
            <h4>You made their day!</h4>

            <div style={{ textAlign: "left", color: "black !important" }}>
              One last thing. Why are you recognizing {props.member?.firstName}?
              <div style={{ paddingTop: 8 }}>Select a reason below.</div>
            </div>
          </div>
        </div>
        <div
          style={{
            textAlign: "left",
            paddingTop: 40,
            paddingLeft: 10,

            fontSize: 18,
            fontWeight: 500,
          }}
        >
          Your company values.
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            marginTop: 10,
            marginLeft: -18,
            marginRight: -18,
            paddingLeft: 18,
            paddingRight: 38,
            maxHeight: "calc(100vh - 190px)",
            overflowX: "auto",
            overflowY: "hidden",
            paddingBottom: 10,
          }}
        >
          {regalosTags &&
            regalosTags.map((item: any) => {
              return (
                <OptionBox
                  key={item.id}
                  title={item.title}
                  image={item.image}
                  color={item.color}
                  id={item.id}
                />
              );
            })}
        </div>
        <div
          style={{
            textAlign: "left",
            paddingTop: 40,
            paddingLeft: 10,
            fontSize: 18,
            fontWeight: 500,
          }}
        >
          More reasons for Regalos.
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "nowrap",
            marginTop: 30,
            marginLeft: -18,
            marginRight: -18,
            maxHeight: "calc(100vh - 190px)",
            overflowX: "auto",
            overflowY: "hidden",
            paddingBottom: 10,
            paddingLeft: 18,
            paddingRight: 38,
          }}
        >
          {regalosGenericTags &&
            regalosGenericTags.map((item: any) => {
              return (
                <OptionBox
                  key={item.id}
                  title={item.title}
                  image={item.image}
                  color={item.color}
                  id={item.id}
                />
              );
            })}
        </div>
      </div>
    </IonPage>
  );
}
