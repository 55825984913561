import axios from "axios";
import { atom } from "recoil";

const APIURL = process.env.REACT_APP_API_HOST;

export const employerState = atom({
  key: "employerState", // unique ID (with respect to other atoms/selectors)
  default: [],
});

const EmployerService = {
  async currentEmployer() {
    return axios.get(`${APIURL}/Employer/CurrentEmployer`);
  },
  async regalosTags() {
    return axios.get(`${APIURL}/Employer/RegalosTags`);
  },
  //RegalosGenericTags
  async regalosGenericTags() {
    return axios.get(`${APIURL}/Employer/RegalosGenericTags`);
  },
};

export default EmployerService;
