import axios from "axios";
import { atom } from "recoil";

const APIURL = process.env.REACT_APP_API_HOST;

export const cartState = atom({
  key: "cartState", // unique ID (with respect to other atoms/selectors)
  default: {}, // default value (aka initial value)
});

export const partnersState = atom({
  key: "partnersState", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
});

export const favoritesPartnersState = atom({
  key: "favoritesPartnersState", // unique ID (with respect to other atoms/selectors)
  default: { favIds: [] }, // default value (aka initial value)
});

const partnerService = {
  async getPartner(id: number) {
    return axios.get(`${APIURL}/Partners/GetPartner/${id}`);
  },
  async getPartners() {
    return axios.get(`${APIURL}/Partners/GetPartners`);
  },
  async getPartnerTypes() {
    return axios.get(`${APIURL}/PartnerTypes/GetPartnerTypes`);
  },
  // get partner item
  async getPartnerItem(id: number) {
    return axios.get(`${APIURL}/Partners/GetPartnerItem/${id}`);
  },
  // get featured items
  async featuredItems() {
    return axios.get(`${APIURL}/Partners/FeaturedItems`);
  },
};

export default partnerService;
