import { IonCol, IonGrid, IonPopover, IonRow } from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router";
import "../Shared/theme/style.css";

interface Props {
  amount: number;
}

export default function RegalosHero(props: Props) {
  const [showPopover, _showPopover] = useState(false);

  const history = useHistory<any>();

  // format amount as usd currency
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  function HeroButton(options: { title: string; icon: string; route: string }) {
    return (
      <div
        style={{
          width: 80,
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
        onClick={() => {
          history.push(options.route);
        }}
      >
        <div
          style={{
            width: 44,
            height: 44,
            border: "1px solid black",
            boxShadow: "1px 2px 5px 0px rgba(0,0,0,0.50)",
            borderRadius: 10,
            marginLeft: "auto",
            marginRight: "auto",
            fontSize: 20,
            paddingTop: 9,
          }}
        >
          <i className={"icon bi-" + options.icon}></i>
        </div>
        <div
          style={{
            fontSize: 10,
            fontWeight: 500,
            paddingTop: 8,
          }}
        >
          {options.title}
        </div>
      </div>
    );
  }

  return (
    <div className="">
      <IonGrid class="ion-no-padding">
        <IonRow>
          <IonCol size="6">
            <div
              style={{
                fontWeight: 500,
                fontSize: 24,
                padding: "22px 0px 0px 22px",
                display: "inline-block",
              }}
            >
              Regalos
            </div>
            <div
              id="click-trigger"
              style={{
                display: "inline-block",
                verticalAlign: "top",
                paddingTop: 16,
              }}
            >
              <div
                onClick={() => {
                  _showPopover(true);
                }}
                id="open-popover"
              >
                <i className="icon bi-exclamation-circle"></i>
              </div>

              <IonPopover
                onDidDismiss={() => {
                  _showPopover(false);
                }}
                isOpen={showPopover}
                side="bottom"
                className="mw-popover"
                trigger="open-popover"
              >
                <div
                  style={{ width: 50 }}
                  onClick={() => {
                    _showPopover(false);
                  }}
                >
                  <i className="icon bi-x-square"></i>
                </div>
                <div style={{ padding: 8 }}>
                  <div>
                    Regalos, meaning gifts in Spanish, is where you can send
                    funds with messages to your team. Say ‘Good job’, celebrate
                    a birthday, or make someone’s day by sending a Regalos.
                    <br />
                    <br />
                    Did you receive some Regalos? You can use these funds in-app
                    or transfer over to your Maxwell Card whenever you would
                    like. The plus side of Regalos is that they never expire —
                    even at the end of the year.
                    <br />
                  </div>
                </div>
              </IonPopover>
            </div>
          </IonCol>
          <IonCol>
            <div
              style={{
                width: "100%",
                textAlign: "right",
                paddingRight: 15,
                paddingTop: 15,
                fontSize: 24,
                fontWeight: 500,
              }}
            >
              {/* {formatter.format(props.amount * 0.01)} */}
            </div>
          </IonCol>
        </IonRow>
        <div
          style={{
            height: 110,
            display: "flex",
            paddingLeft: 20,
            paddingTop: 6,
          }}
        >
          <HeroButton
            title="Send Regalos"
            icon="heart-half"
            route="/regalos_send"
          />
          <HeroButton
            title="Transfer"
            icon="shuffle"
            route="/regalos-transfer"
          />
          <HeroButton
            title="View Team"
            icon="people-fill"
            route="/regalos_send"
          />
        </div>
      </IonGrid>
    </div>
  );
}
