import { IonCol, IonGrid, IonRow } from "@ionic/react";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import ImageService from "../Shared/services/ImageService";
import memberService from "../Shared/services/MemberService";
import "../Shared/theme/style.css";

export default function RegalosFavorites() {
  const [members, _members] = useState<any>();

  const history = useHistory();

  useEffect(() => {
    memberService.getMembers([0]).then((res) => {
      const m = _.shuffle(res.data);
      _members(m);
    });
  }, []);

  function RegFavList(mems: any) {
    if (mems !== null) {
      const memdata = mems;
      return memdata.map((member: any, index: number) => {
        return (
          <div
            style={{
              display: "inline-block",
            }}
            key={index}
          >
            <div
              onClick={() => {
                history.push("/regalos_send_amount/" + member.id);
              }}
              style={{
                width: 92,
                height: 92,
                display: "inline-block",
                backgroundImage:
                  "url(" + ImageService.Url(member.picture) + ")",
                borderRadius: 10,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                marginLeft: 10,
                verticalAlign: "top",
                textAlign: "center",
                fontSize: 10,
                fontWeight: "bold",
                paddingTop: 20,
                boxShadow: "0px 0px 5px #00000033",
                border: "1px solid black",
                backgroundColor: "#eee",

                zIndex: 1,
              }}
            ></div>
            <div
              style={{
                fontWeight: 500,
                textAlign: "center",
                paddingLeft: 10,
              }}
            >
              {member.preferredName || member.firstName || "Name"}
            </div>
          </div>
        );
      });
    }
  }

  return (
    <div className="">
      <div className="regalos-header padding lines">
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol style={{ paddingLeft: 12, fontWeight: 400 }}>
              <span className="rubik">Your Peers</span>
            </IonCol>
            {/* <IonCol
              onClick={() => {
                history.push("/members");
              }}
              style={{ textAlign: "right" }}
            >
              <a className="raleway">See All</a>
            </IonCol> */}
          </IonRow>
        </IonGrid>
      </div>
      <div className="favorite-cards hide-scrollbar">
        <div className="hide-scrollbar" style={{ width: "100vw" }}>
          <div className="horizontal-container hide-scrollbar">
            {members && RegFavList(members)}
            {/* <div
              onClick={() => {
                history.push("/members");
              }}
              style={{
                width: 92,
                height: 92,
                borderRadius: 10,
                backgroundColor: "#eee",
                border: "1px solid black",
                marginLeft: 10,
                verticalAlign: "top",
                display: "inline-block",
                textAlign: "center",
                fontSize: 10,
                fontWeight: "bold",
                paddingTop: 20,

                marginTop: 4,
              }}
            >
              <i className="bi bi-people-fill" style={{ fontSize: 28 }}></i>
              <br />
              View Team
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
