import { IonButton, IonContent, IonPage } from "@ionic/react";
import { useEffect, useState } from "react";
import "../Shared/theme/style.css";
import petsService from "../Shared/services/PetService";
import MaxwellForm from "../Shared/services/MaxwellForm";

interface Props {
  prevStep?: any;
  nextStep?: any;
  gotoStep: (goStep: number) => void;
  handleChange: (input: any, e: any) => void;
  values?: any;
}

export default function OBAddPet(props: Props) {
  const [pets, _pets] = useState<any>();
  const [pet, _pet] = useState<any>();
  const [name, _name] = useState<any>("");
  const [petType, _petType] = useState<any>("");
  const [weight, _weight] = useState<any>(0);

  useEffect(() => {
    petsService.getPets().then((res) => {
      _pets(res.data);
    });
    if (props.values.s1099_EditPet !== null) {
      _pet(props.values.s1099_EditPet);
      _name(props.values.s1099_EditPet.name);
      _petType(props.values.s1099_EditPet.petTypeId);
      _weight(props.values.s1099_EditPet.weight);
    }
  }, []);

  // Form Validation Handling
  const [formErrors, _formErrors] = useState<any>([""]);
  function HandleInputErrors(errs: any) {
    const errors = formErrors.filter(
      (x: any) => x !== "" //|| x !== "Missing Required Fields"
    );
    if (errs.valid) {
      _formErrors(errors.filter((x: any) => x !== errs.error));
    } else {
      _formErrors([...errors, errs.error]);
    }
  }

  return (
    <IonPage>
      <div className="main-container">
        {/* <OBHeader prevStep={() => props.gotoStep(10)} /> */}
        <IonContent>
          <div
            style={{
              marginTop: 80,
              width: "100%",
            }}
          >
            <div
              style={{
                width: "90%",
                marginRight: "auto",
                marginLeft: "auto",
              }}
            >
              <MaxwellForm.Input
                label="Pet Name"
                placeholder="Pet Name"
                value={name}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  // pet.name = value;
                  _name(value);
                }}
              />

              <MaxwellForm.Select
                label="Pet Type"
                placeholder="Select all the apply."
                options={props.values.pettypes.map((x: any) => {
                  return { id: x.id, name: x.name };
                })}
                value={petType}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  // pet.petTypeId = value;
                  _petType(value);
                }}
              />

              <MaxwellForm.Input
                label="Weight"
                placeholder="Weight in Pounds"
                value={weight}
                type="number"
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  // pet.weight = value;
                  _weight(value);
                }}
              />

              <IonButton
                onClick={() => {
                  const thisPet: any = {
                    name: name,
                    petTypeId: petType,
                    weight: weight,
                  };
                  if (pet && pet.id) {
                    thisPet.id = pet.id;
                    petsService.putPet(pet.id, thisPet).then((res) => {
                      props.gotoStep(10);
                    });
                  } else {
                    petsService.postPet(thisPet).then((res) => {
                      props.gotoStep(10);
                    });
                  }
                }}
                expand="block"
                color="light"
              >
                {pet && pet.id ? "Update Pet" : "Add Pet"}
              </IonButton>
              {pet && pet.id && (
                <IonButton
                  style={{ marginTop: 30 }}
                  onClick={() => {
                    //delete
                    petsService.deletePet(pet.id).then((res) => {
                      props.gotoStep(10);
                    });
                  }}
                  expand="block"
                  color="dark"
                >
                  Delete
                </IonButton>
              )}
              <IonButton
                style={{ marginTop: 30, position: "fixed", top: 0, left: 0 }}
                className="backButton"
                size="small"
                onClick={() => {
                  _pet(undefined);
                  props.gotoStep(10);
                }}
                color="light"
              >
                Back
              </IonButton>
            </div>
          </div>
        </IonContent>
      </div>
    </IonPage>
  );
}
