import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonToggle,
} from "@ionic/react";
import PlasticCard from "./PlasticCard";
import { card, refresh, wallet } from "ionicons/icons";
import memberService from "../Shared/services/MemberService";
import { useState } from "react";

interface Props {
  member: any;
  showOrderCardModal: (e: boolean) => void;
}

export default function SlideOrderCard(props: Props) {
  return (
    <>
      <div className="slide-card">
        <h3
          style={{
            textAlign: "left",
          }}
        >
          Physical Card
        </h3>
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol sizeMd="6">
              <div style={{ minWidth: 200 }}>
                <PlasticCard
                  amount={props.member.balance}
                  last4={"0000"}
                  type={"physical"}
                  name={"Physical Card"}
                />
              </div>
            </IonCol>
            <IonCol sizeMd="6">
              <div style={{ padding: 12 }}>
                You currently only have a Virtual card. <br />
                <br /> Would you like to order a physical card?
              </div>
              <div style={{ paddingTop: 14 }}>
                <IonButton
                  onClick={() => {
                    props.showOrderCardModal(true);
                  }}
                  expand="block"
                  color="dark"
                >
                  <IonIcon icon={card} />
                  &nbsp;&nbsp; Order Physical Card
                </IonButton>
              </div>
              <div style={{ paddingTop: 14 }}></div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
}
