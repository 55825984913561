import {
  IonAvatar,
  IonCol,
  IonGrid,
  IonIcon,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { notificationsOutline } from "ionicons/icons";
import { useState } from "react";
import { useHistory } from "react-router";
import { MxImg } from "../Shared/services/ImageService";
import "../Shared/theme/style.css";
import MenuFooter from "./MenuFooter";
import MenuHeader from "./MenuHeader";
import MenuList from "./MenuList";

interface Props {
  linkable?: boolean;
  avatar?: boolean;
  member: any;
}

export default function DesktopNavbar(props: Props) {
  const [modal, _modal] = useState(false);
  const [showMenu, _showMenu] = useState(false);
  const history = useHistory();

  return (
    <>
      <div
        className="desktop-tabs"
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "stretch",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "inline-flex",
            width: 120,
            paddingTop: 0,
            paddingLeft: 20,
          }}
        >
          <img
            style={{ objectFit: "contain" }}
            alt="maxwell-white-logo"
            src="/assets/Maxwell-150_dpi-white-logo.png"
          />
        </div>
        <div
          style={{
            display: "inline-flex",
            flex: 2,
            paddingTop: 14,
            verticalAlign: "middle",
          }}
        >
          <IonGrid className="ion-no-padding">
            <IonRow>
              <IonCol>
                <div
                  className="desktop-tab"
                  onClick={() => {
                    history.push("/home");
                  }}
                >
                  <IonLabel>Home</IonLabel>
                </div>

                <div
                  className={
                    "desktop-tab MarketplaceTab " +
                    (localStorage.getItem("hideMarketplaceTab") == "true" &&
                      "hide")
                  }
                  onClick={() => {
                    history.push("/market", {
                      animation: "none",
                    });
                  }}
                >
                  <IonLabel>Market</IonLabel>
                </div>

                <div
                  className={
                    "desktop-tab BenefitsTab " +
                    (localStorage.getItem("hideBenefitsTab") == "true" &&
                      "hide")
                  }
                  onClick={() => {
                    history.push("/benefits");
                  }}
                >
                  <IonLabel>Benefits</IonLabel>
                </div>

                <div
                  className={
                    "desktop-tab RegalosTab " +
                    (localStorage.getItem("hideRegalosTab") == "true" && "hide")
                  }
                  onClick={() => {
                    history.push("/regalos");
                  }}
                >
                  <IonLabel>Regalos</IonLabel>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>
        </div>
        <div
          style={{
            display: "inline-flex",
            flex: 1,
            color: "white",
            padding: 4,
            textAlign: "right",
          }}
        >
          <div style={{ flex: 1, paddingTop: 10, paddingRight: 20 }}>
            <IonIcon
              onClick={() => {
                history.push("/notifications");
              }}
              style={{ fontSize: 40 }}
              icon={notificationsOutline}
            />
          </div>
          <div style={{ width: 70, textAlign: "right" }}>
            <IonAvatar
              onClick={() => {
                _showMenu(!showMenu);
              }}
              style={{ margin: 5, width: 50, height: 50 }}
            >
              {props.member && props.member.picture && (
                <MxImg
                  alt="Silhouette of a person's head"
                  src={props.member.picture}
                />
              )}
            </IonAvatar>
          </div>
        </div>
      </div>
      {showMenu && (
        <div
          style={{
            position: "fixed",
            top: 50,
            right: 40,
            backgroundColor: "white",
            border: "2px solid black",
            width: 360,
            minHeight: 200,
            zIndex: 100,
            borderRadius: 10,
            maxHeight: "calc(100vh - 60px)",
            overflowY: "scroll",
          }}
        >
          <MenuHeader />
          <MenuList />
          <MenuFooter />
        </div>
      )}
    </>
  );
}
