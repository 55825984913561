import {
  IonButton,
  IonContent,
  IonPage,
  IonText,
  IonIcon,
  IonItem,
  IonLabel,
} from "@ionic/react";
import { add } from "ionicons/icons";
import "../Shared/theme/style.css";
import { useEffect } from "react";
import WizardProgress from "./WizardProgress";
import petsService from "../Shared/services/PetService";

interface Props {
  prevStep?: any;
  nextStep?: any;
  gotoStep: (goStep: number) => void;
  handleChange: (input: any, e: any) => void;
  editPet: (state: any, value: any) => void;
  values?: any;
}

export default function OBPets(props: Props) {
  useEffect(() => {
    petsService.getPets().then((res) => {
      props.editPet("s10_Pets", res.data);
    });
  }, []);

  function EditPet(editPet: any) {
    props.editPet("s1099_EditPet", editPet);
    props.gotoStep(10999);
  }

  function PetsList() {
    if (props.values.s10_Pets !== null) {
      return props.values.s10_Pets.map((pet: any, index: number) => {
        return (
          <IonItem onClick={() => EditPet(pet)} key={index}>
            <IonLabel>
              <i className={"fas fa-" + pet.petType.icon}></i> &nbsp; {pet.name}
            </IonLabel>
          </IonItem>
        );
      });
    }
  }

  const Continue = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    props.nextStep();
  };

  const Back = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    props.prevStep();
  };

  const AddPet = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    props.editPet("s1099_EditPet", null);
    props.gotoStep(10999);
  };

  return (
    <IonPage>
      <div className="main-container">
        <IonContent>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "90%",
                marginRight: "auto",
                marginLeft: "auto",
                marginTop: 40,
              }}
            >
              <WizardProgress
                list={[
                  { icon: "bi-clipboard-heart", complete: true },
                  { icon: "bi-people" },
                  { icon: "bi-question" },
                ]}
              />
              <IonText>
                {/* <h1 style={{ textAlign: "center" }}>Do you have any pets?</h1> */}
                <h1 style={{ paddingTop: 30 }}>Do you have any pets?</h1>
                <h3>
                  This will be useful if you ever want to use our Per Services.
                </h3>
              </IonText>
              <div className="mw-full-width" style={{ marginBottom: 20 }}>
                <IonButton expand="block" onClick={AddPet} color="light">
                  <IonIcon slot="start" icon={add} />
                  Add Pet
                </IonButton>
              </div>
              {PetsList()}
              {props.values.s10_Pets && props.values.s10_Pets.length > 0 && (
                <IonButton
                  style={{ marginTop: 40 }}
                  onClick={Continue}
                  expand="block"
                  color="dark"
                >
                  Continue
                </IonButton>
              )}
              {props.values.s10_Pets && props.values.s10_Pets.length <= 0 && (
                <IonButton
                  style={{ marginTop: 40 }}
                  onClick={Continue}
                  expand="block"
                  color="dark"
                  class="ion-color ion-color-dark ios button button-block button-solid ion-activatable ion-focusable"
                >
                  Skip
                </IonButton>
              )}
              <IonButton
                style={{ marginTop: 20 }}
                className="backButton"
                expand="block"
                onClick={Back}
                color="light"
              >
                Back
              </IonButton>
            </div>
          </div>
        </IonContent>
      </div>
    </IonPage>
  );
}
