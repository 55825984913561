import axios from "axios";
import { atom } from "recoil";

const APIURL = process.env.REACT_APP_API_HOST;

export const favoritesBenefitsState = atom({
  key: "favoritesBenefitsState", // unique ID (with respect to other atoms/selectors)
  default: { favIds: [] },
});

const FavoriteService = {
  async getFavorites(category: string) {
    return axios.get(`${APIURL}/Favorites/GetFavorites/${category}`);
  },
  async getFavorite(id: number) {
    return axios.get(`${APIURL}/Favorites/GetFavorite/${id}`);
  },
  async postFavorite(item: any) {
    return axios.post(`${APIURL}/Favorites/PostFavorite`, item);
  },
  // Favorite an item
  async Favorite(id: any, categoryId: number) {
    const item = {
      id: parseInt(id),
      categoryId,
    };

    return axios.post(`${APIURL}/Favorites/Favorite`, item);
  },
  // Unfavorite an item
  async Unfavorite(id: any, categoryId: number) {
    const item = {
      id: parseInt(id),
      categoryId,
    };
    return axios.post(`${APIURL}/Favorites/Unfavorite`, item);
  },
  async deleteFavorite(id: number) {
    return axios.delete(`${APIURL}/Favorites/DeleteFavorite/${id}`);
  },
};

export default FavoriteService;
