import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
} from "@ionic/react";
import "../Shared/theme/style.css";
import React, { useState } from "react";
import { useHistory } from "react-router";
import auth from "../Shared/services/auth";
import CONFIG from "../config";
import AuthCode from "react-auth-code-input";
import PhoneInputWithCountrySelect from "react-phone-number-input";
import { cloudUploadOutline, downloadOutline } from "ionicons/icons";
import isMobile, { isApple } from "../Shared/services/IsMobile";

export default function Login() {
  const [loginUser, _loginUser] = useState();
  const [segment, _segment] = useState<any>("email");
  const [email, _email] = useState<any>();
  const [phone, _phone] = useState<any>();
  const [code, _code] = useState<any>();
  const [loginError, _loginError] = useState<any>();
  const [phoneError, _phoneError] = useState<any>();
  const [disableButton, _disableButton] = useState(false);
  const [showCode, _showCode] = useState(false);
  const history = useHistory();

  const onSendCode = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    _loginError(undefined);
    if (disableButton == false) {
      _disableButton(true);
      try {
        const data = await auth.signIn({ email, phoneNumber: phone });
        _loginUser(data);
        _showCode(true);
        console.log("data", data);
      } catch (error) {
        console.log(error);
        _loginError("Invalid Email");
      }
      _disableButton(false);
    }
  };

  const onLogin = async () => {
    _disableButton(true);
    try {
      const data = await auth.authCode(loginUser, code);
      console.log("checked User", data);

      const accessToken = data.signInUserSession.accessToken.jwtToken;
      const idToken = data.signInUserSession.idToken.jwtToken;

      if (accessToken) {
        const res = await auth.postFinishAuth(accessToken, idToken);
        console.log("FinishAuth Response: ", res);

        if (res.data.onboarded) {
          window.location.href = "/home";
        } else {
          window.location.href = "/onboarding";
        }
      } else {
        _loginError("Invalid Code");
      }
    } catch (error: any) {
      console.log(error);
      if (error.message) {
        _loginError(error.message);
      } else {
        _loginError(error);
      }
    }
  };

  return (
    <IonPage>
      <IonContent fullscreen className="main-content">
        <IonGrid>
          <IonRow>
            <IonCol offsetSm="0" sizeSm="12" offsetLg="4" sizeLg="4">
              <div style={{ marginTop: -74 }}>
                {!showCode ? (
                  <div
                    style={{ padding: 24, textAlign: "center", marginTop: 175 }}
                  >
                    <img
                      style={{ width: 210, height: "auto" }}
                      src="/assets/Maxwell-150_dpi-black-logo.png"
                    />
                    <div style={{ paddingTop: 10 }}>
                      <h3> Login</h3>
                      <IonGrid
                        className="ion-no-padding"
                        style={{ maxWidth: 400 }}
                      >
                        <IonRow>
                          <IonCol size="6">
                            <div
                              style={{ fontWeight: 500 }}
                              onClick={() => {
                                _segment("email");
                              }}
                              className={
                                segment != "phone"
                                  ? "login-segment-button-left-selected"
                                  : "login-segment-button-left"
                              }
                            >
                              Email
                            </div>
                          </IonCol>
                          <IonCol size="6">
                            <div
                              style={{ fontWeight: 500 }}
                              onClick={() => {
                                _segment("phone");
                              }}
                              className={
                                segment == "phone"
                                  ? "login-segment-button-right-selected"
                                  : "login-segment-button-right"
                              }
                            >
                              Phone
                            </div>
                          </IonCol>
                        </IonRow>
                      </IonGrid>
                      {segment !== "phone" ? (
                        <form onSubmit={onSendCode}>
                          <div style={{ paddingTop: 10 }}>
                            <div
                              style={{
                                textAlign: "left",
                                fontSize: 14,
                                marginBottom: 10,
                              }}
                            >
                              Email Address
                            </div>
                            <IonInput
                              style={{ textAlign: "left" }}
                              className="mw-input"
                              value={email}
                              onIonChange={(e) => {
                                _email(e.target.value);
                              }}
                              type="email"
                              placeholder="name@example.com"
                              required
                            />
                          </div>
                          <div style={{ marginTop: 34 }}>
                            <IonButton
                              disabled={disableButton}
                              expand="block"
                              color="dark"
                              type="submit"
                            >
                              {disableButton ? "Loading..." : "Send Code"}
                            </IonButton>
                          </div>
                        </form>
                      ) : (
                        <form onSubmit={onSendCode}>
                          <div style={{ paddingTop: 40 }}>
                            <div
                              style={{
                                textAlign: "left",
                                fontSize: 14,
                              }}
                            >
                              Enter Phone
                            </div>

                            <PhoneInputWithCountrySelect
                              label="Mobile Phone*"
                              countries={["US", "MX", "CA"]}
                              defaultCountry="US"
                              value={phone}
                              style={{
                                fontSize: 16,
                                backgroundColor: "white",
                                marginTop: 10,
                              }}
                              placeholder="Enter phone number"
                              onChange={(value) => {
                                _phone(value);
                                // props.handleChange("s1_MobileNumber", value, false);
                              }}
                            />
                          </div>
                          <div style={{ marginTop: 34 }}>
                            <IonButton
                              disabled={disableButton}
                              expand="block"
                              color="dark"
                              type="submit"
                            >
                              {disableButton ? "Loading..." : "Send Code"}
                            </IonButton>
                            {phoneError && (
                              <div
                                style={{
                                  color: "red",
                                  fontSize: 12,
                                  marginTop: 10,
                                }}
                              >
                                {phoneError}
                              </div>
                            )}
                          </div>
                        </form>
                      )}
                      <div style={{ marginTop: 24 }}></div>
                    </div>
                  </div>
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <div
                      style={{
                        paddingTop: 100,
                        paddingBottom: 50,
                      }}
                    >
                      <img
                        style={{ width: 210, height: "auto" }}
                        src="/assets/Maxwell-150_dpi-black-logo.png"
                      />
                    </div>

                    <h2>Enter Code</h2>
                    <p>
                      We sent a code to <b>{email ? email : phone}</b>
                    </p>
                    <div style={{ marginBottom: 50 }}>
                      <AuthCode
                        inputClassName="mw-code-input"
                        onChange={(code) => _code(code)}
                        allowedCharacters="numeric"
                        autoFocus={true}
                        length={6}
                      />
                    </div>
                    {!loginError && (
                      <IonButton
                        color="dark"
                        disabled={disableButton}
                        expand="block"
                        onClick={onLogin}
                      >
                        {disableButton ? "Loading..." : "Login"}
                      </IonButton>
                    )}
                    <div style={{ color: "red" }}>{loginError}</div>
                    <IonButton
                      style={{ marginTop: 20 }}
                      color="light"
                      className="backButton"
                      size="small"
                      onClick={() => {
                        _loginError(undefined);
                        _loginUser(undefined);
                        _email(undefined);
                        _code(undefined);
                        _disableButton(false);
                        _showCode(false);
                      }}
                    >
                      Try Again
                    </IonButton>
                  </div>
                )}
              </div>
              <div style={{ position: "fixed", bottom: 10, right: 20 }}>
                {CONFIG.APPVERSION}
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
        {isApple() && (
          <div
            style={{
              position: "fixed",
              bottom: 60,
              left: 0,
              backgroundColor: "white",
              width: "100%",
              fontWeight: "bold",
            }}
          >
            <IonButton
              onClick={() => {
                history.push("/install");
              }}
              size="large"
              color="light"
              expand="block"
            >
              Install Instructions
            </IonButton>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
}
