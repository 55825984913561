import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  IonText,
} from "@ionic/react";
import { useEffect, useState } from "react";
import "../Shared/theme/style.css";
import WizardProgress from "./WizardProgress";
import memberService from "../Shared/services/MemberService";
import onboardingService from "../Shared/services/OnboardingService";
import { Benefit } from "../Shared/types/benefit";

interface Props {
  prevStep?: any;
  nextStep?: any;
  handleChange: (input: any, e: any) => void;
  values?: any;
  linkable?: boolean;
}

export default function OBAdvantages(props: Props) {
  const [benefits, _benefits] = useState<any>();
  const [selected, _selected] = useState<any>();
  const [member, _member] = useState<any>();

  useEffect(() => {
    memberService.getMember().then((res) => {
      _member(res.data);
      onboardingService.getExistingBenefits().then((res2) => {
        _benefits(res2.data);
        // console.log("Benefits: ", res.data);
        const newSelected = [];
        for (const s of res2.data) {
          if (
            res.data.existingBenefits.filter((e: any) => e.id === s.id).length >
            0
          ) {
            newSelected.push(true);
            //console.log(s);
          } else {
            newSelected.push(false);
          }
        }
        _selected(newSelected);
      });
    });
  }, []);

  function List() {
    return benefits.map((item: Benefit, index: number) => {
      return (
        <IonItem key={index}>
          <IonCheckbox
            checked={selected[index]}
            onIonChange={(e) => {
              const newSelected = selected;
              newSelected[index] = !selected[index];
              //console.log(newSelected);
              _selected(newSelected);
            }}
            slot="start"
          ></IonCheckbox>
          <IonLabel> &nbsp; {item.name}</IonLabel>
        </IonItem>
      );
    });
  }

  async function Continue() {
    let index = 0;
    for (const s of selected) {
      // if (s === true) {

      await onboardingService.ExistingBenefitsUpdateMember({
        memberId: member.id,
        existingBenefitId: benefits[index].id,
        addToMember: s,
      });
      // }
      index++;
    }
    props.nextStep();
  }

  const Back = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    props.prevStep();
  };

  return (
    <IonPage>
      <div className="main-container">
        <IonContent>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "90%",
                marginRight: "auto",
                marginLeft: "auto",
                paddingTop: 30,
              }}
            >
              <WizardProgress
                list={[
                  {
                    icon: "bi-telephone",
                    complete: true,
                    clicked: () => props.prevStep(1),
                  },
                  {
                    icon: "bi-person",
                    complete: true,
                    clicked: () => props.prevStep(2),
                  },
                  {
                    icon: "bi-house",
                    complete: true,
                    clicked: () => props.prevStep(3),
                  },
                  {
                    icon: "bi-camera",
                    complete: true,
                    clicked: () => props.prevStep(4),
                  },
                  {
                    icon: "bi-lightning",
                    complete: true,
                    clicked: () => props.prevStep(5),
                  },
                  { icon: "bi-list-ul", complete: true },
                  { icon: "bi-person-gear" },
                ]}
              />
              <IonText>
                <h1 style={{ paddingTop: 30 }}>
                  Do you use any of these wellbeing benefits at work?
                </h1>
              </IonText>
              <h3>Check all you take advantage of. This helps us understand where you are currently finding value.</h3>
              <div style={{ marginBottom: 40 }}>
                {benefits && selected && <List />}
              </div>

              <IonButton onClick={Continue} expand="block" color="dark">
                Continue
              </IonButton>
              <IonButton
                className="backButton"
                expand="block"
                onClick={Back}
                color="light"
                style={{ marginTop: 20 }}
              >
                Back
              </IonButton>
            </div>
          </div>
        </IonContent>
      </div>
    </IonPage>
  );
}
