import { useSpring } from "@react-spring/web";
import { useState } from "react";
import { useHistory } from "react-router";
import isMobile from "../Shared/services/IsMobile";
import "../Shared/theme/style.css";

interface Props {
  item: any;
  selected: (item: any) => void;
}

export default function PartnerFeaturedItem(props: Props) {
  const [isOpen, setIsOpen] = useState<boolean>();
  const [cords, setCords] = useState<any>();

  const history = useHistory();

  const [spring, springApi] = useSpring(() => ({
    from: { display: "none", x: 0, y: 0, scale: 0.1 },
    to: { display: isOpen ? "block" : "none", x: 0, y: 0 },
  }));

  function openItem(x: number, y: number) {
    props.selected(props.item);
  }

  return (
    <div
      key={props.item.id}
      style={{
        height: "calc(39vw + 10px",
        padding: 6,
        position: "relative",
        minWidth: "calc(39% - 2px)",
        maxHeight: 300,
      }}
      onClick={(e) => {
        openItem(
          e.currentTarget.getBoundingClientRect().x,
          e.currentTarget.getBoundingClientRect().y
        );
      }}
    >
      <div
        style={{
          width: "calc(100% - 24px)",
          height: "calc(100% - 34px)",
          display: isOpen ? "none" : "block",
          borderRadius: 9,
          border: "1px solid black",
          backgroundColor: "#97D2D4",
          cursor: "pointer",
          position: "absolute",
          top: 8,
          left: 6,
          zIndex: 1,
          boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.75)",
        }}
      ></div>
      <div
        style={{
          position: "absolute" as any,
          width: "calc(100% - 24px)",
          height: "calc(100% - 34px)",
          display: isOpen ? "none" : "block",
          borderRadius: 9,
          border: "1px solid black",
          backgroundImage: "url(" + props.item.picture + ")",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundColor: "#fff",
          cursor: "pointer",
          top: 5,
          left: 4,
          zIndex: 2,
        }}
      ></div>
      <div
        style={{
          position: "absolute",
          top: "calc(100% - 21px)",
          display: isOpen ? "none" : "block",
          left: 0,
          paddingLeft: 6,
          paddingRight: 16,
          fontSize: isMobile() ? 12 : 14,
          fontWeight: 400,
          width: "100%",
        }}
      >
        {props.item.name}
      </div>
    </div>
  );
}
