import { IonButton, IonContent, IonPage } from "@ionic/react";
import "../Shared/theme/style.css";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import PetsService from "../Shared/services/PetService";
import _ from "lodash";
import PetItem from "./PetItem";
import HeadSubpage from "../Nav/HeaderSubpage";

export default function Pets() {
  const [segment, _segment] = useState<any>("card");
  const [pets, _pets] = useState<any>();
  const [reset, _reset] = useState<any>(0);
  const history = useHistory();
  const location = useLocation<any>();

  useEffect(() => {
    PetsService.getPets().then((res) => {
      _pets(res.data);
    });
  }, [reset]);

  function PetsList(ps: any) {
    if (ps !== null) {
      const petdata = _.orderBy(ps, ["name", "asc"]);
      return petdata.map((pet: any, index: number) => {
        return <PetItem pet={pet} index={index} key={index} />;
      });
    }
  }

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className="main-content">
        <div className="main-container leave-room-for-menu ion-padding">
          <div style={{ marginBottom: 30, marginTop: 30 }}>
            <h3> Pets</h3>
            {pets && PetsList(pets)}
          </div>
          <IonButton
            color="light"
            expand="block"
            onClick={() => {
              history.push("/pet");
            }}
          >
            Add Pet
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
}
