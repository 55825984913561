import { IonButton, IonChip, IonContent, IonModal } from "@ionic/react";
import "../Shared/theme/style.css";
import { useState } from "react";
import { useHistory } from "react-router";
import { PartnerItem } from "../Shared/types/partnerItem";
import Card from "../Shared/Card";
import { MxImg } from "../Shared/services/ImageService";

interface Props {
  item: PartnerItem;
  partner: any;
  isButton?: boolean;
}

export default function PartnerItemCheckout(props: Props) {
  const [isOpen, _isOpen] = useState(false);
  const history = useHistory();

  return (
    <>
      <div style={{ display: "inline-block" }}>
        {!props.isButton ? (
          <Card
            width={200}
            height={200}
            showAmount={true}
            amount={props.item.price ? props.item.price * 0.01 : 0}
            title={props.item.name}
            cover={true}
            picture={props.item.picture ? props.item.picture : ""}
            clicked={() => {
              _isOpen(true);
            }}
          />
        ) : (
          <IonButton
            onClick={() => {
              _isOpen(true);
            }}
            color={"dark"}
            expand={"block"}
          >
            {props.item.name}
          </IonButton>
        )}
      </div>
      <IonModal
        className="custom-modal"
        isOpen={isOpen}
        onDidDismiss={() => {
          _isOpen(false);
        }}
      >
        <IonContent className="ion-padding">
          <i
            onClick={() => {
              _isOpen(false);
            }}
            className="icon bi-x-square"
            style={{ fontSize: 28 }}
          />
          {props.item.discountText && (
            <IonChip style={{ position: "absolute", top: 10, right: 10 }}>
              {props.item.discountText}
            </IonChip>
          )}
          <h3>{props.item.name}</h3>
          <div>
            {props.item.price && "$" + (props.item.price * 0.01).toFixed(2)}{" "}
            {props.item.membership && "Monthly"}
          </div>
          <div
            style={{
              marginTop: 10,
              objectFit: "contain",
              textAlign: "center",
            }}
          >
            <MxImg
              style={{
                maxHeight: 200,
                borderRadius: 10,
                border: "1px solid black",
                borderBottom: "3px solid black",
                borderRight: "2px solid black",
              }}
              src={props.item.picture}
            />
          </div>
          <p>{props.item.description}</p>
          {props.item.reimbursement != true ? (
            <>
              {props.item.inApp != true ? (
                <IonButton
                  style={{ marginTop: 30 }}
                  color="dark"
                  expand="block"
                  onClick={() => {
                    _isOpen(false);
                    setTimeout(() => {
                      const newCartLocal = localStorage.getItem("cart");
                      const newCart = [];
                      // if (newCartLocal) {
                      //   let parsedCart = JSON.parse(newCartLocal);
                      //   if (parsedCart && parsedCart[0]) {
                      //     newCart = parsedCart;
                      //   }
                      // }

                      newCart.push(props.item);

                      //console.log("add to cart", newCart);

                      localStorage.setItem("cart", JSON.stringify(newCart));
                      if (props.item.inputs || props.partner.inputs) {
                        history.push("/partner_item_form/" + props.item.id);
                      } else {
                        history.push("/checkout/");
                      }
                    }, 500);
                  }}
                >
                  Checkout
                </IonButton>
              ) : (
                <IonButton
                  style={{ marginTop: 30 }}
                  color="dark"
                  expand="block"
                  href={
                    props.item.url
                      ? props.item.url
                      : props.partner.url
                      ? props.partner.url
                      : ""
                  }
                  target="_blank"
                >
                  Get Started
                </IonButton>
              )}
            </>
          ) : (
            <IonButton
              style={{ marginTop: 30 }}
              color="dark"
              expand="block"
              onClick={() => {
                _isOpen(false);
                setTimeout(() => {
                  history.push("/reimbursement/" + props.item.id + "/partner");
                }, 500);
              }}
            >
              Begin Reimbursement
            </IonButton>
          )}
          <IonButton
            onClick={() => {
              _isOpen(false);
            }}
            style={{ marginTop: 30 }}
            color="light"
            expand="block"
          >
            Cancel
          </IonButton>
        </IonContent>
      </IonModal>
    </>
  );
}
