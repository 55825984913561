import { IonAlert, IonCol, IonGrid, IonRow } from "@ionic/react";
import "../Shared/theme/style.css";
import { useState } from "react";
import Card from "../Shared/Card";
import { useHistory } from "react-router";
import _ from "lodash";
import FavoriteService, {
  favoritesBenefitsState,
} from "../Shared/services/FavoriteService";
import { useRecoilState } from "recoil";

interface Props {
  benefits: any;
  favorites: any;
}

export default function BenefitsList(props: Props) {
  const [segment, _segment] = useState<any>("traditional");
  const [showTraditionalAlert, _showTraditionalAlert] = useState(false);
  const [showReimbursementAlert, _showReimbursementAlert] = useState(false);
  const [favorites, _favorites] = useRecoilState<any>(favoritesBenefitsState);
  const history = useHistory();

  function favorited() {
    FavoriteService.getFavorites("Benefit").then((res) => {
      // console.log("res", res.data);
      _favorites(res.data);
    });
  }

  function BenefitList(benefits: any) {
    // console.log("benefits", benefits);
    if (benefits !== null) {
      return benefits.map((benefit: any) => {
        // console.log("favorite", favorites.favIds);

        return (
          <div
            key={benefit.id}
            style={{
              width: "50%",
              display: "inline-block",
              paddingLeft: 8,
              paddingRight: 8,
            }}
          >
            <Card
              id={benefit.id}
              categoryId={2}
              title={benefit.name}
              // date={benefit.updatedDatemmddyyyy}
              picture={benefit.picture}
              showFavorite={true}
              favorite={favorites.favIds?.includes(benefit.id)}
              favorited={() => {
                favorited();
              }}
              clicked={() => {
                history.push("/benefit/" + benefit.id);
              }}
              width={"100%"}
            />
          </div>
        );
      });
    }
  }

  return (
    <div className=" padding">
      <IonGrid className="ion-no-padding">
        <IonRow className="ion-no-padding">
          <IonCol className="ion-no-padding">
            <div>
              {segment === "traditional" && (
                <>
                  <IonAlert
                    isOpen={showTraditionalAlert}
                    onDidDismiss={() => _showTraditionalAlert(false)}
                    header="Traditional Benefits"
                    message="Here is some info on traditional benefits"
                    buttons={["OK"]}
                  />

                  <div style={{ paddingTop: 16 }}>
                    {favorites &&
                      favorites.favIds &&
                      BenefitList(props.benefits)}
                  </div>
                </>
              )}
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
}
