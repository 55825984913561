import { IonCol, IonGrid, IonPopover, IonRow } from "@ionic/react";
import "../Shared/theme/style.css";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import memberService, { memberState } from "../Shared/services/MemberService";
import LocationService from "../Shared/services/LocationServices";

export default function PartnerHero() {
  const [showPopover, _showPopover] = useState(false);
  const [locationOptions, _locationOptions] = useState([]);
  const [selectedLocation, _selectedLocation] = useState<any>();
  const [member, _member] = useRecoilState<any>(memberState);

  useEffect(() => {
    const options = [];
    if (!member.id) {
      memberService.getMember().then((res) => {
        _member(res.data);
        LocationService.getLocations().then((res) => {
          // console.log(res.data);
          _locationOptions(res.data);
          console.log(member.locationId);
          _selectedLocation(member.locationId);
        });
      });
    } else {
      LocationService.getLocations().then((res) => {
        // console.log(res.data);
        _locationOptions(res.data);
        // console.log(member.locationId);
        _selectedLocation(member.locationId);
      });
    }
  }, []);

  return (
    <div className="">
      <IonGrid class="ion-no-padding" style={{ zIndex: 1 }}>
        <IonRow>
          <IonCol size="6">
            <div
              style={{
                fontWeight: 500,
                fontSize: 24,
                padding: "22px 0px 22px 22px",
                display: "inline-block",
                zIndex: 1,
              }}
            >
              Marketplace
            </div>
            <div
              id="click-trigger"
              style={{
                display: "inline-block",
                verticalAlign: "top",
                paddingTop: 16,
                zIndex: 1,
              }}
            >
              <div
                onClick={() => {
                  _showPopover(true);
                }}
                id="open-popover"
              >
                <i className="icon bi-exclamation-circle"></i>
              </div>

              <IonPopover
                onDidDismiss={() => {
                  _showPopover(false);
                }}
                isOpen={showPopover}
                side="bottom"
                className="mw-popover"
                trigger="open-popover"
              >
                <div
                  style={{ width: 50 }}
                  onClick={() => {
                    _showPopover(false);
                  }}
                >
                  <i className="icon bi-x-square"></i>
                </div>
                <div style={{ padding: 8 }}>
                  <div>
                    The Maxwell Marketplace is where you can discover partners
                    that make life/work for you.
                    <br />
                    <br />
                    You can use both budgets for in-app partners or visit their
                    store off-app (indicated by tag).
                  </div>
                </div>
              </IonPopover>
            </div>
          </IonCol>
          <IonCol size="6">
            <div
              style={{
                padding: "18px 8px 0px 0px ",
                textAlign: "right",
                zIndex: 1,
              }}
            >
              {/* {member && locationOptions && selectedLocation && (
                <IonSelect
                  value={selectedLocation}
                  interface="popover"
                  placeholder="Select Market"
                  defaultValue={member.locationId}
                  onIonChange={(e) => {
                    _selectedLocation(e.detail.value);
                    LocationService.setLocation(e.detail.value).then((res) => {
                      // console.log(res.data);
                      let m = structuredClone(member);
                      m.locationId = e.detail.value;
                      _member(m);
                    });
                  }}
                >
                  {locationOptions.map((l: any) => {
                    return (
                      <IonSelectOption key={l.id} value={l.id}>
                        {l.name}
                      </IonSelectOption>
                    );
                  })}
                </IonSelect>
              )} */}
            </div>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
}
