import { IonContent, IonPage, IonToggle } from "@ionic/react";
import _ from "lodash";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router";
import HeadSubpage from "../../Nav/HeaderSubpage";
import FavoriteService from "../../Shared/services/FavoriteService";
import { MxImg } from "../../Shared/services/ImageService";
import memberService from "../../Shared/services/MemberService";
import { Member } from "../../Shared/types/member";
import Regalos_Select_Wallet from "./Regalos_Select_Wallet";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

export default function Regalos_Amount_Page() {
  const [amount, setAmount] = useState<number>(0);
  const [stringAmount, setStringAmount] = useState<string>("0");
  const [hasFocused, setHasFocused] = useState<string>();
  const [showWallets, setShowWallets] = useState(false);
  const [receiveMember, setReceiveMember] = useState<Member>();
  const [wallets, setWallets] = useState<any>();
  const [note, setNote] = useState<string>();
  const [isPublic, setIsPublic] = useState<boolean>(false);
  const [backAmount, setBackAmount] = useState<string>("0");
  const [favorite, _favorite] = useState<boolean>(false);

  const amountRef = useRef<any>();

  // get query params from url ID
  const { id } = useParams<any>();

  const defaultMaskOptions = {
    prefix: "$",
    suffix: "",
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ",",
    allowDecimal: true,
    decimalSymbol: ".",
    decimalLimit: 2, // how many digits allowed after the decimal
    integerLimit: 7, // limit length of integer numbers
    allowNegative: false,
    allowLeadingZeroes: false,
  };

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
  });

  useEffect(() => {
    if (id) {
      // get member by id
      memberService.getMembers([id]).then((res) => {
        console.log("member", res.data);
        setReceiveMember(res.data[0]);
        if (res.data[0].isFavorite) {
          _favorite(true);
        }
      });

      memberService.getWallets().then((res) => {
        console.log("wallets", res.data.wallets);
        setWallets(res.data.wallets);
        amountRef?.current.focus();
      });
    }
  }, []);

  function selectWallet(show: boolean) {
    console.log("show wallet");
    setShowWallets(true);
  }

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className="main-content">
        <div
          className="main-container  ion-padding"
          style={{
            paddingLeft: 22,
            paddingRight: 22,
          }}
        >
          <div style={{ height: 20 }}></div>

          {receiveMember && (
            <div style={{ padding: 10, width: "100%", textAlign: "center" }}>
              <div
                style={{
                  width: "100%",
                  position: "relative",
                }}
              >
                {!favorite ? (
                  <div
                    style={{
                      position: "absolute",
                      top: -20,
                      right: 0,
                      fontSize: 36,
                    }}
                    onClick={() => {
                      _favorite(true);
                      FavoriteService.Favorite(id, 6);
                    }}
                    color="light"
                  >
                    <i className="bi bi-heart"></i>
                  </div>
                ) : (
                  <div
                    style={{
                      position: "absolute",
                      top: -20,
                      right: 0,
                      fontSize: 36,
                    }}
                    onClick={() => {
                      _favorite(false);
                      FavoriteService.Unfavorite(id, 6);
                    }}
                    color="dark"
                  >
                    <i className="bi bi-heart-fill"></i>
                  </div>
                )}
                <div
                  style={{
                    height: 80,
                    width: 80,
                    borderRadius: 10,
                    border: "1px solid black",
                    overflow: "hidden",
                    marginTop: 0,
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginBottom: 10,
                  }}
                >
                  {receiveMember && receiveMember.picture && (
                    <MxImg
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                      }}
                      src={receiveMember?.picture}
                    />
                  )}
                </div>
              </div>

              <div style={{ flex: 1 }}>
                <div style={{ fontWeight: 500 }}>
                  {receiveMember.firstName} {receiveMember.lastName}
                </div>
                {receiveMember.birthdate && (
                  <div style={{ fontSize: 12 }}>
                    <i className="icon bi-gift"></i> &nbsp;
                    {moment(receiveMember.birthdate).format("MMMM Do")}
                  </div>
                )}
                <div style={{ fontSize: 12, paddingTop: 6 }}></div>
              </div>
            </div>
          )}

          <div style={{ width: "100%", paddingTop: 10, marginBottom: 100 }}>
            <MaskedInput
              mask={currencyMask}
              step=".01"
              ref={amountRef}
              placeholder="$0"
              autoFocus={true}
              onChange={(e) => {
                const v = e.target.value;
                setStringAmount(v);
              }}
              onFocus={() => {
                setHasFocused("amount");
              }}
              onBlur={() => {
                setHasFocused("none");
                const x = parseFloat(stringAmount.replace("$", ""));
                console.log("amount", x);
                setAmount(x);
              }}
              style={{
                width: "100%",
                height: 71,
                fontSize: 50,
                textAlign: "center",
                border: "none",
                outline: "none",
                backgroundColor: "transparent",
              }}
            />
            {/* <input
              step=".01"
              ref={amountRef}
              type="number"
              placeholder="0"
              pattern="^\d+(?:\.\d{1,2})?$"
              autoFocus={true}
              onChange={(e) => {
                let v = e.target.valueAsNumber;
                if (v >= 10000) {
                  v = 10000;
                }
                setAmount(v);

                if (
                  v.toString() !=
                    _.round(e.target.valueAsNumber, 2).toString() &&
                  e.target.value != ""
                ) {
                  amountRef.current.value = _.round(v, 2).toFixed(2);
                }
              }}
              onFocus={() => {
                setHasFocused("amount");
              }}
              onBlur={() => {
                setHasFocused("none");
                if (amount != 0) {
                  // setAmount(_.round(amount, 2).toFixed(2));
                }
              }}
              style={{
                width: "100%",
                height: 71,
                fontSize: 50,
                textAlign: "center",
                border: "none",
                outline: "none",
                backgroundColor: "transparent",
              }}
            /> */}
            <div style={{ paddingTop: 10 }}>
              <div
                style={{
                  maxWidth: 500,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <div style={{ fontWeight: 500, paddingBottom: 4 }}>
                  *Include a message!
                </div>
                <textarea
                  style={{
                    width: "100%",
                    backgroundColor: "#fcfcfc  ",
                    color: "black",
                    border: "1px solid black",
                    borderRadius: 10,
                    padding: 8,
                  }}
                  rows={6}
                  placeholder="Remember, people love specificity…"
                  onChange={(e) => {
                    setNote(e.target.value);
                  }}
                ></textarea>
              </div>
            </div>
            <div
              style={{
                maxWidth: 500,
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
              }}
            >
              <div
                style={{
                  flex: 1,
                  paddingTop: 14,
                  fontWeight: 500,
                }}
              >
                Public
              </div>
              <div>
                <IonToggle
                  onClick={() => {
                    setIsPublic(!isPublic);
                  }}
                ></IonToggle>
              </div>
            </div>
            {wallets && wallets.length > 0 && (
              <Regalos_Select_Wallet
                isOpen={showWallets}
                wallets={wallets}
                dismissed={() => {
                  setShowWallets(false);
                }}
                amount={amount}
                note={note}
                isPublic={isPublic}
                member={receiveMember}
              />
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
