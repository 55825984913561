import { IonButton, IonContent, IonItem, IonPage } from "@ionic/react";
import "../Shared/theme/style.css";
import { useState } from "react";
import { useRecoilState } from "recoil";
import { cartState } from "../Shared/services/PartnerService";
import Lottie from "lottie-react";
import successLottie from "../Shared/lottie/79952-successful.json";
import { useHistory } from "react-router";

function CheckoutSuccess() {
  const [segment, _segment] = useState<any>("card");
  const [cart, _cart] = useRecoilState<any>(cartState);
  const history = useHistory();

  function List() {
    if (cart && cart[0]) {
      return cart.items.map((item: any, index: number) => {
        <IonItem key={index}>{item.name}</IonItem>;
      });
    } else {
      return (
        <>
          <h2>Cart Is Empty</h2>
        </>
      );
    }
  }

  return (
    <IonPage>
      <IonContent fullscreen className="main-content ">
        <div className="main-container leave-room-for-menu ion-padding">
          <div style={{ paddingTop: 20, textAlign: "center" }}>
            <Lottie animationData={successLottie} loop={false} />
            <h1>Success!</h1>
            <div style={{ paddingTop: 30 }}>We have received your order.</div>
            <div style={{ paddingTop: 30 }}>
              We will review and reach out if needed.
            </div>
            <div style={{ fontWeight: "bold", paddingTop: 30 }}>
              Here&apos;s to making <br />
              life/work with you.
            </div>
            <div style={{ paddingTop: 30 }}>
              <IonButton
                color="dark"
                expand="block"
                onClick={() => {
                  history.replace("/market");
                }}
              >
                Close
              </IonButton>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}

export default CheckoutSuccess;
