import {
  IonAvatar,
  IonButton,
  IonContent,
  IonItem,
  IonList,
  IonLoading,
  IonPage,
  IonText,
  IonToggle,
} from "@ionic/react";
import "../Shared/theme/style.css";
import { useEffect, useState } from "react";
import OnboardingService from "../Shared/services/OnboardingService";
import _ from "lodash";
import MemberService, { memberState } from "../Shared/services/MemberService";
import HeadSubpage from "../Nav/HeaderSubpage";
import ImageService from "../Shared/services/ImageService";
import { useRecoilState } from "recoil";
import MaxwellForm from "../Shared/services/MaxwellForm";
import onboardingService from "../Shared/services/OnboardingService";
import ChangePhone from "./ChangePhone";
import NotificationService from "../Shared/services/NotificationService";

export default function UserProfile() {
  const [member, _member] = useRecoilState<any>(memberState);
  const [phone, _phone] = useState<any>();
  const [firstName, _firstName] = useState<any>();
  const [lastName, _lastName] = useState<any>();
  const [preferredName, _preferredName] = useState<any>();
  const [birthdate, _birthdate] = useState<any>();
  const [genderId, _genderId] = useState<any>();
  const [races, _races] = useState<any>();
  const [maritalId, _maritalId] = useState<any>();
  const [caregiverId, _caregiverId] = useState<any>();
  const [other, _other] = useState<any>();
  const [locationId, _locationId] = useState<any>();
  const [genders, _genders] = useState<any>();
  const [raceOptions, _raceOptions] = useState<any>();
  const [picture, _picture] = useState<any>();
  const [maritals, _maritals] = useState<any>();
  const [caregivers, _caregivers] = useState<any>();
  const [locations, _locations] = useState<any>();
  const [loadingImge, _loadingImage] = useState<any>(false);
  const [showPhoneModal, _showPhoneModal] = useState<any>(false);

  useEffect(() => {
    // call api or anything
    MemberService.getMember().then((res) => {
      _member(res.data);
      _firstName(res.data.firstName);
      _lastName(res.data.lastName);
      _preferredName(res.data.preferredName);
      _birthdate(res.data.birthdate);
      _genderId(res.data.genderId);
      _races(res.data.races.map((x: any) => x.id));
      _maritalId(res.data.maritalId);
      _caregiverId(res.data.careGiverId);
      _other(res.data.other);
      _locationId(res.data.locationId);
      _phone(res.data.phone);
      _picture(res.data.picture);
    });

    OnboardingService.getGenders().then((res) => {
      _genders(_.orderBy(res.data, ["name", "asc"]));
    });

    OnboardingService.getRaces().then((res) => {
      _raceOptions(_.orderBy(res.data, ["name", "asc"]));
    });

    OnboardingService.getMaritals().then((res) => {
      _maritals(_.orderBy(res.data, ["name", "asc"]));
    });

    OnboardingService.getCareGivers().then((res) => {
      _caregivers(_.orderBy(res.data, ["name", "asc"]));
    });

    OnboardingService.getLocations().then((res) => {
      _locations(_.orderBy(res.data, ["name", "asc"]));
    });
  }, []);

  const [formErrors, _formErrors] = useState<any>([]);
  function HandleInputErrors(errs: any) {
    const errors = formErrors.filter(
      (x: any) => x !== "" //|| x !== "Missing Required Fields"
    );
    if (errs?.valid) {
      _formErrors(errors.filter((x: any) => x !== errs.error));
    } else {
      _formErrors([...errors, errs.error]);
    }
  }

  function formatPhone(phone: string) {
    if (phone) {
      phone = phone.replace("+", "");
      if (phone[0] === "1") {
        phone = phone.substring(1);
      }
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    }
  }

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className="main-content">
        <div className="main-container leave-room-for-menu">
          <div style={{ padding: 24 }}>
            <IonAvatar
              style={{
                width: 150,
                height: 142,
                marginLeft: "calc(50% - 75px)",
              }}
            >
              <ImageService.Uploadable
                selectedImage={(e: any) => {
                  if (e.target.files) {
                    _picture(URL.createObjectURL(e.target.files[0]));
                    const formData = new FormData();
                    formData.append("file", e.target.files[0]);

                    _loadingImage(true);

                    MemberService.postMemberPicture(formData)
                      .then((res) => {
                        //console.log(res);
                        MemberService.getMember()
                          .then((mRes: any) => {
                            _member(mRes.data);
                            _loadingImage(false);
                          })
                          .catch((err) => {
                            _loadingImage(false);
                            alert(err);
                          });
                      })
                      .catch((err) => {
                        _loadingImage(false);
                        alert(err);
                      });
                  }
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundImage: "url(" + ImageService.Url(picture) + ")",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                  }}
                ></div>
              </ImageService.Uploadable>
            </IonAvatar>
          </div>{" "}
          <IonLoading isOpen={loadingImge} />
          <div style={{ textAlign: "center" }}>
            <h3>{member && member.name}</h3>
          </div>
          {member && genders && raceOptions && maritals && caregivers && (
            <IonList style={{ paddingLeft: 24, paddingRight: 24 }}>
              <div style={{ textAlign: "center" }}>
                <IonButton
                  color={"light"}
                  slot="end"
                  onClick={() => {
                    _showPhoneModal(true);
                  }}
                >
                  {phone ? (
                    <>
                      {formatPhone(phone)} {member.smsAuthSet ? "" : "⚠️"}{" "}
                    </>
                  ) : (
                    "Add Phone"
                  )}
                </IonButton>
                <ChangePhone
                  open={showPhoneModal}
                  onClose={() => {
                    _showPhoneModal(false);
                  }}
                  phone={phone}
                />
              </div>

              <MaxwellForm.Input
                label="First Name"
                required={true}
                placeholder="Enter First Name (i.e. Max)"
                value={firstName}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _firstName(value);
                }}
              />

              <MaxwellForm.Input
                label="Last Name"
                required={true}
                placeholder="Enter Last Name (i.e. Maxwell)"
                value={lastName}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _lastName(value);
                }}
              />

              <MaxwellForm.Input
                label="Preferred Name"
                required={true}
                placeholder="Enter Preferred Name (i.e. Max)"
                value={preferredName}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _preferredName(value);
                }}
              />
              {birthdate && (
                <MaxwellForm.DateTime
                  label="Birthday"
                  value={birthdate}
                  required={true}
                  validation={(data: any) => {
                    HandleInputErrors(data);
                  }}
                  onChange={(value: string) => {
                    console.log(value);
                    _birthdate(value);
                  }}
                />
              )}
              <MaxwellForm.Select
                label="Gender Pronoun"
                required={true}
                options={genders}
                value={genderId}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _genderId(value);
                }}
              />

              <MaxwellForm.Select
                label="Race/Ethnicity"
                multiple={true}
                required={true}
                options={raceOptions}
                value={races}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  console.log(value);
                  _races(value);
                }}
              />

              <MaxwellForm.Select
                label="Marital Status"
                required={true}
                options={maritals}
                value={maritalId}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _maritalId(value);
                }}
              />

              <MaxwellForm.Select
                label="Caregiver"
                required={true}
                options={caregivers}
                value={caregiverId}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _caregiverId(value);
                }}
              />

              <MaxwellForm.TextArea
                rows={4}
                label="Other information"
                required={false}
                placeholder="Enter more info"
                value={other}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _other(value);
                }}
              />

              <div style={{ padding: "34px 16px 16px 16px" }}>
                <IonButton
                  expand="block"
                  color="dark"
                  onClick={() => {
                    MemberService.putMember({
                      phone,
                      firstName,
                      lastName,
                      preferredName,
                      birthdate,
                      genderId,
                      maritalId,
                      caregiverId,
                      other,
                      locationId,
                    }).then((res) => {
                      //TODO
                      // console.log(res);
                      // history.goBack();

                      onboardingService.RacesSetMember({
                        raceIds: races,
                      });
                    });
                  }}
                >
                  Update
                </IonButton>
                {member.currentMemberEmployerId == 1 && (
                  <div style={{ paddingTop: 20 }}>
                    {" "}
                    <div>
                      <IonText>
                        {localStorage.getItem("showBeta") == "true"
                          ? "You are in BETA 🕶"
                          : "You are not in BETA"}
                      </IonText>
                    </div>
                    <div>
                      <IonItem lines="none">
                        <IonText>Beta</IonText>
                        <IonToggle
                          enableOnOffLabels={true}
                          checked={localStorage.getItem("showBeta") == "true"}
                          slot="end"
                          onIonChange={() => {
                            if (localStorage.getItem("showBeta") == "true") {
                              localStorage.setItem("showBeta", "false");
                            } else {
                              localStorage.setItem("showBeta", "true");
                              setTimeout(() => {
                                alert("Now entering BETA 🕶");
                              }, 400);
                            }
                          }}
                        ></IonToggle>
                      </IonItem>
                    </div>
                    <div>
                      <IonButton
                        color="dark"
                        onClick={() => {
                          NotificationService.sendTestSMS();
                        }}
                      >
                        Send Test SMS
                      </IonButton>
                    </div>
                  </div>
                )}
              </div>

              <div style={{ paddingBottom: 100 }}></div>
            </IonList>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
}
