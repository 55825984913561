import { IonButton, IonContent, IonPage, IonText } from "@ionic/react";
import { useEffect, useState } from "react";
import "../Shared/theme/style.css";
import WizardProgress from "./WizardProgress";
import memberService from "../Shared/services/MemberService";
import MaxwellForm from "../Shared/services/MaxwellForm";

interface Props {
  prevStep?: any;
  nextStep?: any;
  handleChange: (input: any, e: any) => void;
  values?: any;
  linkable?: boolean;
}

export default function OBTheBasics(props: Props) {
  const [firstName, _firstName] = useState<any>();
  const [lastName, _lastName] = useState<any>();
  const [preferredName, _preferredName] = useState<any>();
  const [genderId, _genderId] = useState<any>();
  const [locationId, _locationId] = useState<any>();

  const [member, _member] = useState<any>();

  useEffect(() => {
    setTimeout(() => {
      memberService.getMember().then((res) => {
        _firstName(res.data.firstName);
        _lastName(res.data.lastName);
        _preferredName(res.data.preferredName);
        _genderId(res.data.genderId);
        _locationId(res.data.locationId);
        _member(res.data);
      });
    }, 1000);
  }, []);

  // Form Validation Handling
  const [formErrors, _formErrors] = useState<any>([""]);
  function HandleInputErrors(errs: any) {
    const errors = formErrors.filter(
      (x: any) => x !== "" //|| x !== "Missing Required Fields"
    );
    if (errs.valid) {
      _formErrors(errors.filter((x: any) => x !== errs.error));
    } else {
      _formErrors([...errors, errs.error]);
    }
  }

  const Back = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    props.prevStep();
  };

  const Continue = (e: { preventDefault: () => void }) => {
    memberService
      .putMember({
        ...member,
        firstName,
        lastName,
        preferredName,
        genderId,
        locationId,
      })
      .then((res) => {
        e.preventDefault();
        props.nextStep();
      });
  };

  return (
    <IonPage>
      <div className="main-container">
        {/* <OBHeader prevStep={props.prevStep} /> */}
        <IonContent>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "90%",
                marginRight: "auto",
                marginLeft: "auto",
                paddingTop: 30,
              }}
            >
              <WizardProgress
                list={[
                  {
                    icon: "bi-telephone",
                    complete: true,
                    clicked: () => props.prevStep(),
                  },
                  { icon: "bi-person", complete: true },
                  { icon: "bi-house" },
                  { icon: "bi-camera" },
                  { icon: "bi-lightning" },
                  { icon: "bi-list-ul" },
                  { icon: "bi-person-gear" },
                ]}
              />
              <IonText>
                <h1 style={{ paddingTop: 30 }} className="">
                  Let&apos;s start with the basics
                </h1>
              </IonText>

              <MaxwellForm.Input
                label="First Name"
                required={true}
                value={firstName}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _firstName(value);
                }}
                autoCapitalize="words"
              />

              <MaxwellForm.Input
                label="Last Name"
                required={true}
                value={lastName}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _lastName(value);
                }}
                autoCapitalize="words"
              />

              <MaxwellForm.Input
                label="Preferred Name"
                required={true}
                placeholder="Enter Preferred Name (i.e. Max)"
                value={preferredName}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _preferredName(value);
                }}
                autoCapitalize="words"
              />

              <MaxwellForm.Select
                label="Gender Pronoun"
                required={true}
                options={props.values.genders}
                value={genderId}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _genderId(value);
                }}
              />

              <div style={{ marginTop: 40 }}>
                <IonButton
                  onClick={Continue}
                  disabled={formErrors.length !== 0 ? true : false}
                  expand="block"
                  color="dark"
                >
                  Continue
                </IonButton>
                <IonButton
                  className="backButton"
                  expand="block"
                  onClick={Back}
                  color="light"
                  style={{ marginTop: 20 }}
                >
                  Back
                </IonButton>
              </div>
              {formErrors.map((error: any) => {
                return (
                  <IonText key={error} color="danger" className="mw-center">
                    <p>{error}</p>
                  </IonText>
                );
              })}
            </div>
          </div>
        </IonContent>
      </div>
    </IonPage>
  );
}
