import UAParser from "ua-parser-js";

export function isApple() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document)
  );
}

export default function isMobile() {
  const userAgent = navigator.userAgent;
  const parser = new UAParser(userAgent);

  const device = parser.getDevice();

  let deviceType = false;

  if (device.type === undefined) {
    deviceType = false;
  } else if (device.type === "mobile") {
    deviceType = true;
  } else {
    deviceType = false;
  }

  if (deviceType === true) {
    return true;
  } else {
    return false;
  }
}
