import {
  IonButton,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
} from "@ionic/react";
import _ from "lodash";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import "../Shared/theme/style.css";
import ActivityItem from "../Activity/ActivityItem";
import ActivityService, {
  GetActivityId,
  activityState,
} from "../Shared/services/ActivityService";
import { darkmodeState } from "../Shared/services/MemberService";
import { Activity } from "../Shared/types/activity";

export default function HomeActivity() {
  const [activities, _activities] = useRecoilState<any>(activityState);
  const [dark, _dark] = useRecoilState<any>(darkmodeState);
  const [page, _page] = useState<any>(1);
  const [noActivity, _noActivity] = useState<any>(false); // [false, true
  const history = useHistory();

  useEffect(() => {
    // call api or anything
    ActivityService.getActivities().then((res) => {
      _activities(res.data);
      setTimeout(() => {
        ActivityService.getActivities().then((res2) => {
          if (activities != res2.data) {
            _activities(res2.data);
            if (res2.data.length > 0) {
              _noActivity(true);
            }
          }
        });
      }, 2000);
    });
  }, []);

  function ActivityList(activities: any) {
    if (activities !== null) {
      const actdata = _.orderBy(
        activities,
        (x: Activity) => {
          return GetActivityId(x);
        },
        ["desc"]
      );
      return actdata
        .filter((x) => {
          return (
            x.activityType &&
            x.activityType.isRegalos == false &&
            x.activityStatus?.name != "pending"
          );
        })
        .map((activity: any, index: number) => {
          if (activity && activity.title) {
            return (
              <ActivityItem
                activity={activity}
                activities={activities}
                avatar={false}
                key={index}
                itemClicked={(a: any, b: any) => {
                  history.push("/related-activity/" + a.id, {
                    activity: a,
                    activities: b,
                  });
                }}
              />
            );
          }
        });
    }
  }

  function PendingActivityList(activities: any) {
    if (activities !== null) {
      const actdata = _.orderBy(
        activities,
        (x: Activity) => {
          return x.id;
        },
        ["desc"]
      );
      return actdata
        .filter((x: Activity) => {
          return (
            x.activityType &&
            x.activityType.isRegalos == false &&
            x.activityStatus?.name == "pending"
          );
        })
        .map((activity: any, index: number) => {
          if (activity && activity.title) {
            return (
              <ActivityItem
                activity={activity}
                activities={activities}
                avatar={false}
                key={index}
                itemClicked={(a: any, b: any) => {
                  history.push("/related-activity/" + a.id, {
                    activity: a,
                    activities: b,
                  });
                }}
              />
            );
          }
        });
    }
  }

  return (
    <IonList>
      {activities ? (
        <>
          {noActivity && activities.length <= 0 && (
            <>
              <div style={{ padding: 16, textAlign: "center" }}>
                <div style={{ fontSize: 60 }}>🌤</div>
                <h1>No Activity Yet</h1>
                <div>
                  Looks like we haven&apos;t seen any activity in the last 30
                  days
                </div>
                <div style={{ paddingTop: 30, fontWeight: 500 }}>
                  Check these great partners
                </div>
                <IonButton
                  expand="block"
                  color="dark"
                  onClick={() => {
                    history.push("/marke");
                  }}
                >
                  <i className="icon bi-shop-window"></i> &nbsp; Marketplace
                </IonButton>
              </div>
            </>
          )}
          {activities && activities.length == 0 && !noActivity ? (
            <div style={{ padding: 16 }}>
              <Skeleton style={{ marginTop: 12 }} count={5} height={60} />
            </div>
          ) : (
            <>
              {activities && activities.length > 0 && (
                <div
                  style={{
                    border: dark ? "1px solid black" : "",
                    borderRadius: 10,
                  }}
                >
                  <div
                    className=""
                    style={{
                      padding: "4px 4px 4px 22px",
                      backgroundColor: "#fcfcfc",
                    }}
                  >
                    Activity
                  </div>
                  {PendingActivityList(activities)}
                  {ActivityList(activities)}
                  <IonInfiniteScroll
                    onIonInfinite={(e) => {
                      ActivityService.getActivities(page).then((res) => {
                        if (res.data.length > 0) {
                          _page(page + 1);
                          _activities(activities.concat(res.data));
                        }
                        e.target.complete();
                      });
                    }}
                    threshold="100px"
                  >
                    <IonInfiniteScrollContent
                      style={{ backgroundColor: "#fcfcfc" }}
                      loadingText="Getting more..."
                      loadingSpinner="bubbles"
                    ></IonInfiniteScrollContent>
                  </IonInfiniteScroll>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div style={{ padding: 16 }}>
          <Skeleton style={{ marginTop: 12 }} count={5} height={60} />
        </div>
      )}
    </IonList>
  );
}
