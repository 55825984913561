import {
  IonButton,
  IonIcon,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonList,
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
} from "@ionic/react";
import _, { set } from "lodash";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import RegalosItem from "./RegalosItem_V2";
import RegalosService, {
  regalosActivityState,
} from "../Shared/services/RegalosService";
import memberService, { memberState } from "../Shared/services/MemberService";
import { close } from "ionicons/icons";

interface Props {
  activities: any;
  member: any;
  updated: () => void;
  onIonInfinite: (page: number) => void;
}

export default function RegalosActivity(props: Props) {
  const [segment, _segment] = useState<any>("all");
  const [showActivities, _showActivities] = useState<any>(true);
  const [page, _page] = useState<any>(0);
  const [showPopover, setShowPopover] = useState(false);
  const [selectedTag, setSelectedTag] = useState<any>(null);
  const [popoverPosition, setPopoverPosition] = useState<any>({ x: 0, y: 0 });

  function isMine(regalos: any) {
    return (
      regalos.receiverMember.id == props.member.id ||
      regalos.senderMember.id == props.member.id
    );
  }

  function RegActList(acts: any) {
    return _.orderBy(acts, "id", "desc").map((activity: any, index: number) => {
      if (isMine(activity) || segment != "mine") {
        return (
          <RegalosItem
            key={index}
            activity={activity}
            onUpdated={() => {
              props.updated();
            }}
            showPopover={showPopover}
            setShowPopover={(tag, x, y) => {
              setSelectedTag(tag);
              setPopoverPosition({ x, y });
              setShowPopover(true);
            }}
          />
        );
      } else {
        return null;
      }
    });
  }

  return (
    <>
      <div className=" padding">
        <IonButton
          onClick={() => {
            _segment("all");
          }}
          size="small"
          color={segment == "all" ? "dark" : "light"}
        >
          All
        </IonButton>
        <IonButton
          onClick={() => {
            _segment("mine");
          }}
          size="small"
          color={segment != "all" ? "dark" : "light"}
        >
          Mine
        </IonButton>
      </div>

      <IonList>{props.activities && RegActList(props.activities)}</IonList>
      <div style={{ height: 20, width: "100%" }}></div>
      <IonInfiniteScroll
        onIonInfinite={(e) => {
          props.onIonInfinite(page);
          e.target.complete();
        }}
        threshold="100px"
      >
        <IonInfiniteScrollContent
          loadingText="Getting more..."
          loadingSpinner="bubbles"
        ></IonInfiniteScrollContent>
      </IonInfiniteScroll>
      {showPopover && selectedTag && (
        <div
          className="regalos-tag-popover"
          onClick={() => {
            setShowPopover(false);
          }}
        >
          <div
            className="regalos-tag-popover-content"
            style={{
              top: popoverPosition.y - 20,
              left: popoverPosition.x - 310,
            }}
          >
            <div>
              <IonIcon
                icon={close}
                style={{
                  fontWeight: 900,
                  fontSize: 20,
                }}
              />
            </div>
            <div
              style={{
                fontWeight: 500,
                paddingBottom: 8,
                paddingTop: 4,
              }}
            >
              {selectedTag.title}
            </div>
            {selectedTag.description && <div>{selectedTag.description}</div>}
          </div>
        </div>
      )}
    </>
  );
}
