import axios from "axios";

const APIURL = process.env.REACT_APP_API_HOST;

const OrderService = {
  async postCheckout(data: any) {
    return axios.post(`${APIURL}/Order/PostCheckout`, data);
  },
  async postCheckoutSuccess(data: any) {
    return axios.post(`${APIURL}/Order/PostCheckoutSuccess`, data);
  },
  // create a payment intent for stripe
  async postPaymentIntent(amount: any) {
    return axios.post(`${APIURL}/Order/PostPaymentIntent/` + amount, {});
  },
  // create a payment intent for stripe
  async putPaymentIntent(id: any, fees?: any) {
    let data = {};
    if (fees) {
      data = { fees: fees };
    }
    return axios.put(`${APIURL}/Order/PutPaymentIntent/` + id, data);
  },
  // Post reimbursement
  async postReimbursement(data: any) {
    return axios.post(`${APIURL}/Order/PostReimbursement`, data);
  },
  async getVenmoHandles() {
    return axios.get(`${APIURL}/Order/GetVenmoHandles`);
  },
  async getReimbursementAccounts() {
    return axios.get(`${APIURL}/Order/GetReimbursementAccounts`);
  },
  async GetPrecommitedFunds() {
    return axios.get(`${APIURL}/Order/GetPrecommitedFunds`);
  },
  async GetMemberships() {
    return axios.get(`${APIURL}/Order/GetMemberships`);
  },
};

export default OrderService;
