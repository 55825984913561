import { useState } from "react";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import { darkmodeState, memberState } from "../Shared/services/MemberService";
import { employerState } from "../Shared/services/EmployerService";
import ImageService from "../Shared/services/ImageService";

export default function MenuHeader() {
  const [member, _member] = useRecoilState<any>(memberState);
  const [employer, _employer] = useRecoilState<any>(employerState);
  const [dark, _dark] = useRecoilState<any>(darkmodeState);

  return (
    <>
      <div style={{ textAlign: "center", paddingTop: 30 }}>
        {employer && (
          <>
            <div
              style={{
                backgroundImage: "url(" + ImageService.Url(employer.logo) + ")",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                width: 140,
                height: 140,
                borderRadius: 10,
                textAlign: "center",
                marginLeft: "calc(50% - 70px)",
                border: "0px solid black",
              }}
            ></div>
          </>
        )}
      </div>
    </>
  );
}
