import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonSelect,
  IonSelectOption,
} from "@ionic/react";

import "../Shared/theme/style.css";
import { useEffect } from "react";
import { useHistory, useParams } from "react-router";
import MemberService from "../Shared/services/MemberService";
import { useForm, SubmitHandler } from "react-hook-form";

type Inputs = {
  name: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
};

export default function Address() {
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const history = useHistory();
  const { id } = useParams<any>();

  const onSubmit: SubmitHandler<Inputs> = (data: any) => {
    if (id) {
      MemberService.putAddress(id, data).then(() => {
        history.goBack();
      });
    } else {
      data.country = "US";
      MemberService.postAddress(data).then(() => {
        history.goBack();
      });
    }
  };

  useEffect(() => {
    if (id) {
      MemberService.getAddress(id).then((res) => {
        setValue("name", res.data.name);
        reset({ ...res.data });
      });
    }
  }, [id]);

  return (
    <IonPage>
      <IonContent fullscreen className="main-content">
        <div className="main-container leave-room-for-menu">
          <div style={{ paddingLeft: 16, paddingTop: 24 }}></div>

          <form onSubmit={handleSubmit(onSubmit)}>
            <IonList>
              <IonItem>
                <IonLabel position="stacked">Name</IonLabel>
                <IonInput {...register("name")} placeholder="Enter Name" />
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">Address</IonLabel>
                <IonInput
                  placeholder="Enter Address"
                  {...register("address1", { required: true })}
                />
              </IonItem>

              {errors.address1 && (
                <span className="error_text">This field is required</span>
              )}

              <IonItem>
                <IonLabel position="stacked">Address Line 2</IonLabel>
                <IonInput
                  placeholder="Enter Address Line 2"
                  {...register("address2")}
                />
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">City</IonLabel>
                <IonInput placeholder="Enter City" {...register("city")} />
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">State</IonLabel>
                <IonSelect
                  placeholder=""
                  class="mw-input"
                  value={watch("state")}
                  style={{
                    marginTop: 10,
                    marginBottom: 10,
                    paddingLeft: 10,
                  }}
                  {...register("state")}
                >
                  <IonSelectOption value="AL">AL</IonSelectOption>
                  <IonSelectOption value="AK">AK</IonSelectOption>
                  <IonSelectOption value="AZ">AZ</IonSelectOption>
                  <IonSelectOption value="AR">AR</IonSelectOption>
                  <IonSelectOption value="CA">CA</IonSelectOption>
                  <IonSelectOption value="CZ">CZ</IonSelectOption>
                  <IonSelectOption value="CO">CO</IonSelectOption>
                  <IonSelectOption value="CT">CT</IonSelectOption>
                  <IonSelectOption value="DE">DE</IonSelectOption>
                  <IonSelectOption value="DC">DC</IonSelectOption>
                  <IonSelectOption value="FL">FL</IonSelectOption>
                  <IonSelectOption value="GA">GA</IonSelectOption>
                  <IonSelectOption value="GU">GU</IonSelectOption>
                  <IonSelectOption value="HI">HI</IonSelectOption>
                  <IonSelectOption value="ID">ID</IonSelectOption>
                  <IonSelectOption value="IL">IL</IonSelectOption>
                  <IonSelectOption value="IN">IN</IonSelectOption>
                  <IonSelectOption value="IA">IA</IonSelectOption>
                  <IonSelectOption value="KS">KS</IonSelectOption>
                  <IonSelectOption value="KY">KY</IonSelectOption>
                  <IonSelectOption value="LA">LA</IonSelectOption>
                  <IonSelectOption value="ME">ME</IonSelectOption>
                  <IonSelectOption value="MD">MD</IonSelectOption>
                  <IonSelectOption value="MA">MA</IonSelectOption>
                  <IonSelectOption value="MI">MI</IonSelectOption>
                  <IonSelectOption value="MN">MN</IonSelectOption>
                  <IonSelectOption value="MS">MS</IonSelectOption>
                  <IonSelectOption value="MO">MO</IonSelectOption>
                  <IonSelectOption value="MT">MT</IonSelectOption>
                  <IonSelectOption value="NE">NE</IonSelectOption>
                  <IonSelectOption value="NV">NV</IonSelectOption>
                  <IonSelectOption value="NH">NH</IonSelectOption>
                  <IonSelectOption value="NJ">NJ</IonSelectOption>
                  <IonSelectOption value="NM">NM</IonSelectOption>
                  <IonSelectOption value="NY">NY</IonSelectOption>
                  <IonSelectOption value="NC">NC</IonSelectOption>
                  <IonSelectOption value="ND">ND</IonSelectOption>
                  <IonSelectOption value="OH">OH</IonSelectOption>
                  <IonSelectOption value="OK">OK</IonSelectOption>
                  <IonSelectOption value="OR">OR</IonSelectOption>
                  <IonSelectOption value="PA">PA</IonSelectOption>
                  <IonSelectOption value="PR">PR</IonSelectOption>
                  <IonSelectOption value="RI">RI</IonSelectOption>
                  <IonSelectOption value="SC">SC</IonSelectOption>
                  <IonSelectOption value="SD">SD</IonSelectOption>
                  <IonSelectOption value="TN">TN</IonSelectOption>
                  <IonSelectOption value="TX">TX</IonSelectOption>
                  <IonSelectOption value="UT">UT</IonSelectOption>
                  <IonSelectOption value="VT">VT</IonSelectOption>
                  <IonSelectOption value="VI">VI</IonSelectOption>
                  <IonSelectOption value="VA">VA</IonSelectOption>
                  <IonSelectOption value="WA">WA</IonSelectOption>
                  <IonSelectOption value="WV">WV</IonSelectOption>
                  <IonSelectOption value="WI">WI</IonSelectOption>
                  <IonSelectOption value="WY">WY</IonSelectOption>
                </IonSelect>
              </IonItem>

              <IonItem>
                <IonLabel position="stacked">Zip Code</IonLabel>
                <IonInput
                  placeholder="Enter Zip Code"
                  type="number"
                  {...register("zip")}
                />
              </IonItem>
            </IonList>

            <div style={{ padding: 16 }}>
              <IonButton type="submit" expand="block" color="dark">
                Save Address
              </IonButton>
            </div>
            <div>
              <IonButton
                onClick={() => history.goBack()}
                expand="block"
                color="light"
              >
                Back
              </IonButton>
            </div>
          </form>
        </div>
      </IonContent>
    </IonPage>
  );
}
