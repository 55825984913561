import { IonCol, IonGrid, IonItem, IonLabel, IonRow } from "@ionic/react";
import { animated, useInView } from "@react-spring/web";
import moment from "moment";
import "../Shared/theme/style.css";
import { Activity } from "../Shared/types/activity";
import ActivityAvatar from "./ActivityAvatar";

interface Props {
  rated?: boolean;
  linkable?: boolean;
  avatar?: boolean;
  activity: Activity;
  activities: any;
  picture?: string;
  itemClicked: (activity: any, activities: any) => void;
}

export default function ActivityItem(props: Props) {
  const [actRef, inView] = useInView(
    () => ({
      from: {
        opacity: 0,
        y: 5,
        x: 5,
        rotate: 5,
      },
      to: {
        opacity: 1,
        y: 0,
        x: 0,
        rotate: 0,
      },
    }),
    {
      rootMargin: "0% 0%",
      once: true,
    }
  );

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  return (
    <>
      <IonItem detail={false} button={props.linkable !== false} lines="none">
        <animated.div ref={actRef} style={{ width: 64, ...inView }}>
          <ActivityAvatar
            activities={props.activities}
            activity={props.activity}
            itemClicked={(activity, activities) => {
              props.itemClicked(activity, activities);
            }}
          />
        </animated.div>
        <IonLabel
          style={{
            verticalAlign: "top",
            height: 62,
            marginTop: 4,
          }}
        >
          <div
            onClick={() => {
              //console.log("clicked");
              props.itemClicked(props.activity, props.activities);
            }}
            style={{ fontWeight: 500, fontSize: 16 }}
          >
            {props.activity.title}
          </div>
          <IonGrid className="ion-no-padding">
            <IonRow className="ion-no-padding">
              <IonCol className="ion-no-padding">
                <div
                  onClick={() => {
                    //console.log("clicked");
                    props.itemClicked(props.activity, props.activities);
                  }}
                  style={{ fontSize: 14, fontWeight: "normal" }}
                >
                  {moment(props.activity.date).format("MMM DD, YYYY")}
                </div>
              </IonCol>
              <IonCol className="ion-no-padding">
                {props.activity.activityType?.credit === true ? (
                  <div
                    style={{
                      textAlign: "right",
                      fontSize: 16,
                      color: "green",
                    }}
                  >
                    + {formatter.format(props.activity.amount * 0.01)}
                  </div>
                ) : (
                  <div
                    style={{
                      textAlign: "right",
                      fontSize: 16,
                    }}
                  >
                    {formatter.format(props.activity.amount * 0.01)}
                  </div>
                )}
              </IonCol>
            </IonRow>
            <IonRow>
              {/* <IonCol size="8">
              {props.rated !== false &&
                props.activity.activityStatus &&
                props.activity.activityStatus.complete && (
                  <div style={{ marginTop: 8 }}>
                    <ActivityItemHearts
                      activity={props.activity}
                      hearts={props.activity.hearts}
                    />
                  </div>
                )}
            </IonCol> */}
              <IonCol
                onClick={() => {
                  props.itemClicked(props.activity, props.activities);
                }}
                style={{ textAlign: "right", fontSize: 12, paddingTop: 2 }}
              >
                {props.activity.activityType?.isRegalos == true && (
                  <span style={{ color: "black" }}>regalos </span>
                )}
                {/* {props.activity.activityStatus &&
                  props.activity.activityStatus.complete === false &&
                  props.activity.activityStatus.name != "pending" && (
                    <span>{props.activity.activityStatus?.name}</span>
                  )} */}
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonLabel>
        {/* <IonIcon icon={chevronForward} slot="end"></IonIcon> */}
      </IonItem>
      <div
        style={{
          backgroundColor: "black",
          height: 1,
          width: "calc(100% - 18px)",
          marginLeft: 18,
          marginTop: 1,
        }}
      ></div>
    </>
  );
}
