import {
  IonButton,
  IonContent,
  IonPage,
  IonText,
  IonLabel,
} from "@ionic/react";
import "../Shared/theme/style.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import WizardProgress from "./WizardProgress";
import memberService, { memberState } from "../Shared/services/MemberService";

interface Props {
  nextStep?: any;
  handleChange: (input: any, e: any, numonly?: boolean) => void;
  values?: any;
  linkable?: boolean;
}

export default function OBStayInTouch(props: Props) {
  const [phone, _phone] = useState<any>(null);

  const [member, _member] = useRecoilState<any>(memberState);

  useEffect(() => {
    memberService.getMember().then((res) => {
      _member(res.data);
      _phone(res.data.phone || null);
      memberService.OnboardingStarted();
    });
  }, []);

  const Continue = (e: { preventDefault: () => void }) => {
    memberService.putMember({ ...member, phone: phone }).then((res) => {
      console.log(res);
      e.preventDefault();
      props.nextStep();
    });
  };

  return (
    <IonPage>
      <div className="main-container" style={{ paddingTop: 0 }}>
        <IonContent>
          <div
            style={{
              // textAlign: "center",
              width: "100%",
              paddingTop: 30,
              backgroundColor: "#fcfcfc",
            }}
          >
            <div
              style={{
                width: "90%",
                marginRight: "auto",
                marginLeft: "auto",
                marginTop: 0,
                backgroundColor: "#fcfcfc",
              }}
            >
              <WizardProgress
                list={[
                  { icon: "bi-telephone", complete: true },
                  { icon: "bi-person" },
                  { icon: "bi-house" },
                  { icon: "bi-camera" },
                  { icon: "bi-lightning" },
                  { icon: "bi-list-ul" },
                  { icon: "bi-person-gear" },
                ]}
              />
              <IonText>
                <h1 style={{ paddingTop: 30 }} className="">
                  Help us stay in touch.
                </h1>
                <h3>This is where you will receive important notifications.</h3>
              </IonText>

              <div style={{ paddingTop: 24, paddingBottom: 24 }}>
                <IonLabel>Mobile phone*</IonLabel>
                {member && (
                  <PhoneInput
                    label="Mobile Phone*"
                    countries={["US", "MX", "CA"]}
                    defaultCountry="US"
                    value={phone}
                    style={{
                      fontSize: 18,
                      backgroundColor: "white",
                      marginTop: 10,
                    }}
                    placeholder="Enter phone number"
                    onChange={(value) => {
                      _phone(value);
                      // props.handleChange("s1_MobileNumber", value, false);
                    }}
                  />
                )}
              </div>

              <IonButton
                onClick={Continue}
                disabled={phone === null ? true : false}
                expand="block"
                color="dark"
              >
                Continue
              </IonButton>
            </div>
          </div>
        </IonContent>
      </div>
    </IonPage>
  );
}
