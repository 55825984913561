import {
  IonContent,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  useIonViewWillEnter,
} from "@ionic/react";

import "../Shared/theme/style.css";
import { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import BenefitService from "../Shared/services/BenefitService";
import ImageService, { MxImg } from "../Shared/services/ImageService";
import FavoriteService, {
  favoritesBenefitsState,
} from "../Shared/services/FavoriteService";
import { useRecoilState } from "recoil";
import { BenefitItem } from "../Shared/types/benefitItem";
import { Benefit as BenefitType } from "../Shared/types/benefit";
import HeadSubpage from "../Nav/HeaderSubpage";

const uploaderStyle = {
  border: "1px solid black",
  width: "100%",
  height: 200,
  borderRadius: 10,
  backgroundColor: "#eee",
  textAlign: "center" as const,
  fontWeight: "bold",
  color: "#a6a6a6",
};

export default function Benefit() {
  const [benefit, _benefit] = useState<BenefitType>();
  const history = useHistory();
  const [documentTimestamp, setDocumentTimestamp] = useState(
    new Date().getTime()
  );
  // favorite state
  const [favorite, _favorite] = useState<boolean>();
  const [favorites, _favorites] = useRecoilState<any>(favoritesBenefitsState);
  const location = useLocation<any>();
  const { id } = useParams<any>();

  useIonViewWillEnter(() => {
    BenefitService.getBenefit(id).then((res) => {
      // set favorite state
      if (res.data.isFavorite) {
        _favorite(true);
      } else {
        _favorite(false);
      }
      _benefit(res.data);
    });
  });

  function FavoriteIcon() {
    return (
      <div>
        {!favorite ? (
          <div
            style={{
              position: "absolute",
              top: 16,
              right: 20,
              fontSize: 34,
            }}
          >
            <div
              onClick={() => {
                _favorite(true);
                FavoriteService.Favorite(id, 2).then((res) => {
                  FavoriteService.getFavorites("Benefit").then((res) => {
                    _favorites(res.data);
                  });
                });
              }}
              color="light"
            >
              <i className="bi bi-heart"></i>
            </div>
          </div>
        ) : (
          <div
            style={{
              position: "absolute",
              top: 16,
              right: 20,
              fontSize: 34,
            }}
          >
            <div
              onClick={() => {
                _favorite(false);
                FavoriteService.Unfavorite(id, 2).then((res) => {
                  FavoriteService.getFavorites("Benefit").then((res) => {
                    _favorites(res.data);
                  });
                });
              }}
              color="dark"
            >
              <i className="bi bi-heart-fill"></i>
            </div>
          </div>
        )}
      </div>
    );
  }

  function BenefitItems() {
    return (
      benefit &&
      benefit.benefitItems &&
      benefit.benefitItems[0] &&
      benefit?.benefitItems.map((item: BenefitItem, index: number) => {
        return (
          <IonItem
            onClick={() => {
              if (item && item.url) {
                window.open(item.url, "_blank");
              } else {
                // category 3 == reimbursement
                if (benefit.categoryId === 3) {
                  history.push("/reimbursement/" + item.id);
                } else {
                  history.push("/benefit-item-document/" + item.id);
                }
              }
            }}
            key={index}
          >
            <IonLabel>{item.name}</IonLabel>
            <i slot="end" className="bi bi-caret-right"></i>
          </IonItem>
        );
      })
    );
  }

  if (!benefit) {
    return (
      <IonPage>
        <HeadSubpage />
        <IonContent fullscreen className="main-content" />
      </IonPage>
    );
  }

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className="main-content">
        <div className="main-container">
          <div
            style={{
              backgroundImage: `url(${
                benefit.picture && ImageService.Url(benefit.picture)
              })`,
              height: 300,
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
              position: "relative",
            }}
          >
            {" "}
            <FavoriteIcon />
          </div>
          <div style={{ padding: 24 }}>
            <h2>{benefit.name}</h2>
            <div style={{ textAlign: "center" }}>
              {/* Favorite a benefit */}
            </div>
            <p>{benefit.description}</p>
            <br />

            {benefit.canCreateDocument && (
              <>
                {benefit.documents && benefit.documents[0] ? (
                  <ImageService.Uploadable
                    enablePreview
                    selectedImage={(e: any) => {
                      if (e.target.files) {
                        const formData = new FormData();
                        formData.append("file", e.target.files[0]);
                        BenefitService.postBenefitDocument(formData, id).then(
                          (res) => {
                            // console.log(res);
                            console.log({ res });
                            BenefitService.getBenefit(id).then((mRes: any) => {
                              _benefit(mRes.data);
                              setDocumentTimestamp(new Date().getTime());
                            });
                          }
                        );
                      }
                    }}
                  >
                    <div
                      style={{
                        ...uploaderStyle,
                        overflow: "hidden",
                      }}
                    >
                      {benefit.documents &&
                        benefit.documents[benefit.documents.length - 1] &&
                        benefit.documents[benefit.documents.length - 1]
                          .pictureFront && (
                          <MxImg
                            style={{
                              objectFit: "fill",
                              height: "100%",
                            }}
                            src={
                              benefit.documents[benefit.documents.length - 1]
                                .pictureFront
                            }
                            params={{
                              t: documentTimestamp.toString(),
                            }}
                          />
                        )}
                    </div>
                  </ImageService.Uploadable>
                ) : (
                  <ImageService.Uploadable
                    selectedImage={(e: any) => {
                      if (e.target.files) {
                        const formData = new FormData();
                        formData.append("file", e.target.files[0]);
                        BenefitService.postBenefitDocument(formData, id).then(
                          (res) => {
                            // console.log(res);
                            BenefitService.getBenefit(id).then((mRes: any) => {
                              _benefit(mRes.data);
                            });
                          }
                        );
                      }
                    }}
                  >
                    <div
                      style={{
                        ...uploaderStyle,
                        paddingTop: 90,
                      }}
                    >
                      Upload Document
                    </div>
                  </ImageService.Uploadable>
                )}
              </>
            )}
            <br />

            {benefit.benefitItems && benefit.benefitItems.length > 0 && (
              <IonList>{BenefitItems()}</IonList>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
