import { IonContent, IonIcon, IonPage } from "@ionic/react";
import { ellipse } from "ionicons/icons";
import "../Shared/theme/style.css";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import _ from "lodash";
import Skeleton from "react-loading-skeleton";
import { useRecoilState } from "recoil";
import NotificationService, {
  notificationsState,
} from "../Shared/services/NotificationService";
import moment from "moment";
import isMobile from "../Shared/services/IsMobile";
import HeadSubpage from "../Nav/HeaderSubpage";
import Announcements from "../Announcements/Announcements_Page";

export default function Notifications() {
  const [segment, _segment] = useState<any>("card");
  const history = useHistory();
  const [showAll, _showAll] = useState<any>(false);
  const [notifications, _notifications] =
    useRecoilState<any>(notificationsState);

  useEffect(() => {
    // call api or anything
    NotificationService.getNotifications().then((res) => {
      _notifications(res.data);
      //console.log("notifications", res.data);
      NotificationService.seenAllNotifications();
    });
  }, []);

  function truncate(str: string, n: number) {
    return str.length > n ? str.slice(0, n - 1) + "..." : str;
  }

  function NotificationList(announcements: any) {
    if (announcements !== null) {
      const ans = _.orderBy(
        announcements,
        ["sentDate", "createdDate"],
        ["desc", "desc"]
      );
      return ans
        .filter((x: any) => {
          return !x.hasSeenAnnouncement || showAll;
        })
        .map((n: any, index: number) => {
          return (
            <div
              onClick={() => {
                history.push(`/notification/${n.id}`);
              }}
              key={index}
              style={{
                marginBottom: 8,
                padding: 2,
                borderBottom: n.isRead ? "1px solid gray" : "1px solid black",
                display: "flex",
                borderRadius: 0,
                flexDirection: "row-reverse",
                color: n.isRead ? "gray" : "black",
                marginRight: -24,
              }}
            >
              <div
                style={{
                  width: 24,
                  paddingTop: 8,
                  flex: 1,
                  textAlign: "right",
                  paddingRight: 8,
                }}
              >
                {!n.isSeen && (
                  <IonIcon
                    style={{ color: "red", fontSize: 18 }}
                    icon={ellipse}
                  ></IonIcon>
                )}
              </div>
              <div
                style={{
                  flex: 5,
                }}
              >
                <div>{n.title}</div>
                <div
                  style={{
                    paddingBottom: 2,
                    marginTop: -2,
                    textAlign: "left",
                    fontSize: "small",
                    color: "darkgray",
                  }}
                >
                  {moment(n.sentDate).fromNow()}
                </div>
                <div
                  style={{
                    fontSize: "small",
                  }}
                >
                  {truncate(n.body.replace(/<[^>]+>/g, ""), 80)}
                </div>
              </div>
            </div>
          );
        });
    }
  }

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className="main-content">
        <div className="main-container leave-room-for-menu">
          <div style={{ padding: 24, marginTop: isMobile() ? -40 : 0 }}>
            <h3> Your Notifications</h3>
            {(notifications && NotificationList(notifications)) || (
              <Skeleton height={60} style={{ marginBottom: 12 }} count={10} />
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
