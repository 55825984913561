import { useEffect, useState } from "react";
import {
  IonButton,
  IonCheckbox,
  IonContent,
  IonItem,
  IonLabel,
  IonPage,
  IonText,
} from "@ionic/react";
import "../Shared/theme/style.css";
import WizardProgress from "./WizardProgress";
import memberService from "../Shared/services/MemberService";
import onboardingService from "../Shared/services/OnboardingService";
import MaxwellForm from "../Shared/services/MaxwellForm";

interface Props {
  prevStep?: any;
  nextStep?: any;
  handleChange: (input: any, e: any) => void;
  values?: any;
  linkable?: boolean;
}

export default function OBKnowYou(props: Props) {
  const [birthdate, _birthdate] = useState<any>();
  const [allraces, _allraces] = useState<any>([]);
  const [races, _races] = useState<any>([]);
  const [maritalId, _martalId] = useState<any>();
  const [careGiverId, _careGiverId] = useState<any>();
  const [selected, _selected] = useState<any>();

  const [member, _member] = useState<any>();

  useEffect(() => {
    memberService.getMember().then((res) => {
      _member(res.data);
      _birthdate(res.data.birthdate);
      _races(res.data.races);
      _martalId(res.data.maritalId);
      _careGiverId(res.data.careGiverId);

      onboardingService.getRaces().then((res2) => {
        _allraces(res2.data);
        const newSelected = [];
        for (const s of res2.data) {
          if (res.data.races.filter((e: any) => e.id === s.id).length > 0) {
            newSelected.push(true);
          } else {
            newSelected.push(false);
          }
        }
        _selected(newSelected);
      });
    });
  }, []);

  // Form Validation Handling
  const [formErrors, _formErrors] = useState<any>([""]);
  function HandleInputErrors(errs: any) {
    const errors = formErrors.filter(
      (x: any) => x !== "" //|| x !== "Missing Required Fields"
    );
    if (errs.valid) {
      _formErrors(errors.filter((x: any) => x !== errs.error));
    } else {
      _formErrors([...errors, errs.error]);
    }
  }

  function List() {
    return allraces.map((item: any, index: number) => {
      return (
        <IonItem key={index}>
          <IonCheckbox
            checked={selected[index]}
            onIonChange={(e) => {
              const newSelected = selected;
              newSelected[index] = !selected[index];
              _selected(newSelected);
            }}
            slot="start"
          ></IonCheckbox>
          <IonLabel> &nbsp; {item.name}</IonLabel>
        </IonItem>
      );
    });
  }

  const Continue = async (e: { preventDefault: () => void }) => {
    let index = 0;
    for (const s of selected) {
      await onboardingService.RacesUpdateMember({
        memberId: member.id,
        raceId: allraces[index].id,
        addToMember: s,
      });
      index++;
    }
    _member(member);

    memberService
      .putMember({ ...member, birthdate, maritalId, careGiverId })
      .then((res) => {
        e.preventDefault();
        props.nextStep();
      });
  };

  const Back = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    props.prevStep();
  };

  return (
    <IonPage>
      <div className="main-container">
        <IonContent>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "90%",
                marginRight: "auto",
                marginLeft: "auto",
                paddingTop: 30,
              }}
            >
              <WizardProgress
                list={[
                  {
                    icon: "bi-telephone",
                    complete: true,
                    clicked: () => props.prevStep(1),
                  },
                  {
                    icon: "bi-person",
                    complete: true,
                    clicked: () => props.prevStep(2),
                  },
                  {
                    icon: "bi-house",
                    complete: true,
                    clicked: () => props.prevStep(3),
                  },
                  {
                    icon: "bi-camera",
                    complete: true,
                    clicked: () => props.prevStep(4),
                  },
                  {
                    icon: "bi-lightning",
                    complete: true,
                    clicked: () => props.prevStep(5),
                  },
                  {
                    icon: "bi-list-ul",
                    complete: true,
                    clicked: () => props.prevStep(6),
                  },
                  { icon: "bi-person-gear", complete: true },
                ]}
              />
              <IonText>
                {/* <h1 style={{ textAlign: "center" }}> */}
                <h1 style={{ paddingTop: 30 }}>Help us get to know you.</h1>
              </IonText>

              <MaxwellForm.DateTime
                label="Birthday"
                value={birthdate}
                required={true}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _birthdate(value);
                }}
              />

              <IonLabel>Race/Ethnicity (Check all apply)*</IonLabel>
              {selected && <List />}
              {/* <MaxwellForm.Select
                label="Race/Ethnicity"
                placeholder="Select all the apply."
                options={props.values.races}
                value={raceIds}
                required={true}
                multiple={true}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: any) => {
                  console.log(value);
                  _raceIds(value);
                }}
              /> */}
              <br />

              <MaxwellForm.Select
                label="Marital Status"
                options={props.values.maritals}
                value={maritalId}
                required={true}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: number) => {
                  _martalId(value);
                }}
              />

              <MaxwellForm.Select
                label="Caregiver Status"
                options={props.values.caregivers}
                value={careGiverId}
                required={true}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _careGiverId(value);
                }}
              />

              <IonButton
                style={{ marginTop: 40 }}
                onClick={Continue}
                disabled={formErrors.length !== 0 ? true : false}
                expand="block"
                color="dark"
              >
                Continue
              </IonButton>
              <IonButton
                style={{ marginTop: 20 }}
                className="backButton"
                expand="block"
                onClick={Back}
                color="light"
              >
                Back
              </IonButton>
              {formErrors.map((error: any) => {
                return (
                  <IonText key={error} color="danger" className="mw-center">
                    <p>{error}</p>
                  </IonText>
                );
              })}
            </div>
          </div>
        </IonContent>
      </div>
    </IonPage>
  );
}
