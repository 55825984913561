import {
  IonAvatar,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow,
} from "@ionic/react";
import { useHistory } from "react-router";
import "../Shared/theme/style.css";
import { MxImg } from "../Shared/services/ImageService";

interface Props {
  linkable?: boolean;
  avatar?: boolean;
  id?: number;
  title?: string;
  date?: string;
  picture?: string;
  clicked: () => void;
}

export default function AnnouncementItem(props: Props) {
  const history = useHistory();

  return (
    <IonItem
      button={props.linkable !== false}
      lines="full"
      onClick={props.clicked}
    >
      {props.avatar !== false && (
        <IonAvatar slot="start">
          <MxImg alt={props.title} src={props.picture} />
        </IonAvatar>
      )}
      <IonLabel>
        <div className="section-header">{props.title}</div>
        <IonGrid className="ion-no-padding">
          <IonRow className="ion-no-padding">
            <IonCol className="ion-no-padding">
              <div className="sub-label">{props.date}</div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonLabel>
    </IonItem>
  );
}
