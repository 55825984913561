import { forwardRef } from "react";
import "./HomeTour.css";
import { BeaconRenderProps } from "react-joyride";

export const tourSteps = [
  {
    content: (
      <h4 style={{ textAlign: "left" }}>
        This is your Maxwell budget. Click here to view your virtual card and to
        order a physical Maxwell card.
      </h4>
    ),
    target: "#the-big-number",
    placementBeacon: "top" as const,
  },
  {
    content: (
      <h4>Here you can find credits and debits from your Maxwell Wallet.</h4>
    ),
    target: "#home-activity-container",
  },
  {
    content: (
      <h4>
        Any announcements from Maxwell and your employer will be visible here.
        Click to see more info.
      </h4>
    ),
    target: "#home-announcements-container",
  },
];

export const tourStyle = {
  options: {
    primaryColor: "rgb(28, 28, 28)",
    zIndex: 1000,
  },
};

export const tourLocale = {
  back: "Back",
  close: "Close",
  last: "Finish",
  next: "Next",
  open: "Open the dialog",
  skip: "Skip",
};

export const BeaconComponent = forwardRef<HTMLSpanElement, BeaconRenderProps>(
  (props, ref) => {
    // Use props and ref as needed
    return (
      <span ref={ref} className="beacon" {...props}>
        <img src="/assets/info-icon.svg" alt="Info Beacon" />
      </span>
    );
  }
);

BeaconComponent.displayName = "BeaconComponent";
