import {
  IonButton,
  IonContent,
  IonIcon,
  IonPage,
  IonSlide,
  IonSlides,
} from "@ionic/react";
import { useEffect, useRef, useState } from "react";
import HeadSubpage from "../../Nav/HeaderSubpage";
import TheBigNumber from "../../Shared/TheBigNumber";
import { chevronBack, chevronForward } from "ionicons/icons";
import isMobile from "../../Shared/services/IsMobile";
import MembersItem from "../../Members/MembersItem";
import { Member } from "../../Shared/types/member";
import MaxwellForm from "../../Shared/services/MaxwellForm";
import memberService from "../../Shared/services/MemberService";
import FavoriteService from "../../Shared/services/FavoriteService";

export default function Regalos_Send_Page() {
  const slidesRef = useRef<any>();
  const [currentSlideIndex, setCurrentSlideIndex] = useState<number>(0);
  const [slideCount, setSlideCount] = useState<number>(2);
  const [search, setSearch] = useState<string>();
  const [members, setMembers] = useState<Member[]>([]);
  const [wallets, setWallets] = useState<any>();
  const [favorites, setFavorites] = useState<any>();
  const [team, setTeam] = useState<Member[]>([]);
  const [showingList, setShowingList] = useState<string>("favorites");

  useEffect(() => {
    memberService.getWallets().then((res) => {
      console.log("wallets", res.data);
      setWallets(res.data.wallets);
      setSlideCount(res.data.wallets.length);
    });

    memberService.getMembers([0]).then((res) => {
      setMembers(res.data);
    });

    memberService.getTeam().then((res) => {
      console.log("team", res.data);
      setTeam(res.data && res.data.teamMembers);
    });

    FavoriteService.getFavorites("Member").then((res) => {
      memberService.getMembers(res.data.favIds).then((res) => {
        setFavorites(res.data);
      });
    });
  }, []);

  function slidePrev() {
    slidesRef.current.slidePrev();
    slidesRef.current.getActiveIndex().then((x: any) => {
      console.log(x);
      setCurrentSlideIndex(x);
    });
    console.log("next");
  }

  function slideNext() {
    slidesRef.current.slideNext();
    slidesRef.current.getActiveIndex().then((x: any) => {
      console.log(x);
      setCurrentSlideIndex(x);
    });
    console.log("next", slidesRef.current);
  }

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className="main-content">
        <div className="main-container leave-room-for-menu ion-padding">
          <div style={{ height: 20 }}></div>
          <h1>Make someone&apos;s day.</h1>
          <p>Select a teammate and send Regalos.</p>
          <div style={{ position: "relative" }}>
            {isMobile() != true && (
              <>
                {currentSlideIndex != 0 && (
                  <div
                    style={{
                      position: "absolute",
                      top: "calc(50% - 35px)",
                      left: 0,
                      fontSize: 65,
                      zIndex: 2,
                    }}
                    onClick={() => slidePrev()}
                  >
                    <IonIcon icon={chevronBack} />
                  </div>
                )}
                {currentSlideIndex != slideCount - 1 && (
                  <div
                    style={{
                      position: "absolute",
                      top: "calc(50% - 35px)",
                      right: 0,
                      fontSize: 65,
                      zIndex: 2,
                    }}
                    onClick={() => slideNext()}
                  >
                    <IonIcon icon={chevronForward} />
                  </div>
                )}
              </>
            )}
            <IonSlides
              ref={slidesRef}
              pager={true}
              options={{}}
              style={{
                marginLeft: -24,
                marginRight: -24,
                paddingBottom: 16,
              }}
            >
              {wallets &&
                wallets.length > 0 &&
                wallets.map((wallet: any) => {
                  return (
                    <IonSlide
                      key={wallet.id}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div>
                        <TheBigNumber
                          total={wallet.balance}
                          title={wallet.id ? "spot" : wallet.name}
                        />
                      </div>{" "}
                      {wallet.id && (
                        <div
                          style={{
                            fontSize: 14,
                            marginTop: -20,
                            paddingBottom: 20,
                          }}
                        >
                          {wallet.name}
                        </div>
                      )}
                    </IonSlide>
                  );
                })}
            </IonSlides>
          </div>

          <div style={{ paddingTop: 20 }}>
            <div>
              {" "}
              {team && team.length > 0 && (
                <>
                  <IonButton
                    color={showingList == "favorites" ? "dark" : "light"}
                    onClick={() => {
                      setShowingList("favorites");
                    }}
                  >
                    Favorites
                  </IonButton>

                  <IonButton
                    color={showingList == "team" ? "dark" : "light"}
                    onClick={() => {
                      setShowingList("team");
                    }}
                  >
                    Team
                  </IonButton>
                </>
              )}
            </div>
            {showingList == "favorites" && (
              <>
                <h4>Your Favorites</h4>
                {favorites &&
                  favorites.length > 0 &&
                  favorites.map((member: any) => {
                    return (
                      <MembersItem
                        key={member.id}
                        member={member}
                        link={"/regalos_send_amount/" + member.id}
                      />
                    );
                  })}
              </>
            )}
            {showingList == "team" && (
              <>
                <h4>Your Team</h4>
                {team &&
                  team.length > 0 &&
                  team.map((member: any) => {
                    return (
                      <MembersItem
                        key={member.id}
                        member={member}
                        link={"/regalos_send_amount/" + member.id}
                      />
                    );
                  })}
              </>
            )}
          </div>
          <div style={{ paddingTop: 10 }}>
            <h4>All</h4>{" "}
            <MaxwellForm.Search
              value={search}
              onChange={(e) => {
                setSearch(e);
              }}
            />
            {members && (
              <div style={{ paddingTop: 8 }}>
                {members.map((member: any) => {
                  if (search) {
                    let searchAble = member.firstName && member.firstName + " ";
                    searchAble += member.lastName && member.lastName + " ";
                    searchAble +=
                      member.preferredName && member.preferredName + " ";

                    console.log(searchAble);
                    if (
                      searchAble
                        .toLowerCase()
                        .includes(search.toLocaleLowerCase())
                    ) {
                      return (
                        <MembersItem
                          key={member.id}
                          member={member}
                          link={"/regalos_send_amount/" + member.id}
                        />
                      );
                    } else {
                      return null;
                    }
                  } else {
                    return (
                      <MembersItem
                        key={member.id}
                        member={member}
                        link={"/regalos_send_amount/" + member.id}
                      />
                    );
                  }
                })}
              </div>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
