import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  useIonViewDidEnter,
} from "@ionic/react";

import "../Shared/theme/style.css";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";

import _ from "lodash";
import MemberService from "../Shared/services/MemberService";
import { MxImg } from "../Shared/services/ImageService";
import RegalosService from "../Shared/services/RegalosService";
import FavoriteService from "../Shared/services/FavoriteService";
import { Member } from "../Shared/types/member";
import { Regalos } from "../Shared/types/regalos";
import RegalosItem from "../Regalos/RegalosItem";
import HeadSubpage from "../Nav/HeaderSubpage";

export default function MemberPage() {
  const history = useHistory();
  const { id } = useParams<any>();
  const [favorite, _favorite] = useState<boolean>();
  const [member, _member] = useState<Member>();
  const [regalos, _regalos] = useState<Array<Regalos>>();

  useEffect(() => {
    MemberService.getMembers([id]).then((res: any) => {
      if (res.data[0].isFavorite) {
        _favorite(true);
      } else {
        _favorite(false);
      }
      _member(res.data[0]);
      RegalosService.getByMemberRegalos(id).then((res) => {
        _regalos(res.data);
      });
    });
  }, []);

  function List() {
    return regalos?.map((reg: Regalos, index: number) => {
      return (
        <RegalosItem
          key={index}
          activity={reg}
          onUpdated={() => {
            setTimeout(() => {
              RegalosService.getAllRegalos().then((res) => {
                _regalos(res.data);
              });
            }, 500);
          }}
        />
      );
    });
  }

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className="main-content">
        <div className="main-container leave-room-for-menu ion-padding">
          <div style={{ textAlign: "center", position: "relative" }}>
            <div
              style={{
                justifySelf: "center",
                height: 200,
                width: 200,
                borderRadius: 10,
                border: "1px solid black",
                overflow: "hidden",
                marginLeft: "calc(50% - 100px)",
              }}
            >
              {member && member.picture && (
                <MxImg
                  style={{ height: "100%", width: "100%", objectFit: "cover" }}
                  src={member?.picture}
                />
              )}
            </div>
            <h3>
              {member?.firstName} {member?.lastName}
            </h3>
            <IonGrid className="ion-no-padding">
              <IonRow>
                <IonCol></IonCol>
              </IonRow>
            </IonGrid>

            <IonButton
              style={{ marginTop: 30 }}
              expand="block"
              color="dark"
              onClick={() => {
                history.push("/regalos-send/" + id);
              }}
            >
              Send Regalos
            </IonButton>
            {!favorite ? (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 10,
                  fontSize: 36,
                }}
                onClick={() => {
                  _favorite(true);
                  FavoriteService.Favorite(id, 6);
                }}
                color="light"
              >
                <i className="bi bi-heart"></i>
              </div>
            ) : (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  right: 10,
                  fontSize: 36,
                }}
                onClick={() => {
                  _favorite(false);
                  FavoriteService.Unfavorite(id, 6);
                }}
                color="dark"
              >
                <i className="bi bi-heart-fill"></i>
              </div>
            )}
          </div>

          <div>{regalos && regalos[0] && List()}</div>
        </div>
      </IonContent>
    </IonPage>
  );
}
