import {
  IonContent,
  IonPage,
  IonSlide,
  IonSlides,
  useIonViewDidEnter,
  useIonViewWillLeave,
} from "@ionic/react";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { useRecoilState } from "recoil";
import HeadSubpage from "../Nav/HeaderSubpage";
import { balanceVisibleState } from "../Shared/services/ActivityService";
import FavoriteService from "../Shared/services/FavoriteService";
import ImageService from "../Shared/services/ImageService";
import PartnerService, {
  favoritesPartnersState,
} from "../Shared/services/PartnerService";
import Util, { featureFlagState } from "../Shared/services/UtilService";
import "../Shared/theme/style.css";
import { Partner as PartnerType } from "../Shared/types/partner";
import { PartnerItem } from "../Shared/types/partnerItem";
import PartnerItemCheckout from "./PartnerItemCheckout";

export default function Partner() {
  const [segment, _segment] = useState<any>("card");
  const [count, _count] = useState(0);
  const [favorite, _favorite] = useState<boolean>();
  const [favorites, _favorites] = useRecoilState<any>(favoritesPartnersState);
  const [balanceVisible, _balanceVisible] =
    useRecoilState<any>(balanceVisibleState);
  const [market, _market] = useState<PartnerType>();
  const history = useHistory();
  const location = useLocation<any>();
  const { id } = useParams<any>();
  const [featureFlags, setFeatureFlags] = useRecoilState(featureFlagState);

  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    loop: true,
    autoplay: false,
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      clickable: true,
    },
  };

  useEffect(() => {
    Util.featureFlags().then((res) => {
      setFeatureFlags(res);
    });
  }, []);

  useIonViewDidEnter(() => {
    _balanceVisible(true);
    PartnerService.getPartner(id).then((res) => {
      //console.log(res.data);
      _market(res.data);
      _favorite(favorites.favIds.includes(res.data.id));
    });
  });

  useIonViewWillLeave(() => {
    _balanceVisible(false);
  });

  function FavoriteIcon() {
    return (
      <div>
        {!favorite ? (
          <div
            style={{
              position: "absolute",
              top: 16,
              right: 20,
              fontSize: 34,
            }}
          >
            <div
              onClick={() => {
                _favorite(true);
                FavoriteService.Favorite(id, 1).then((res) => {
                  FavoriteService.getFavorites("Partner").then((res) => {
                    _favorites(res.data);
                  });
                });
              }}
              color="light"
            >
              <i className="bi bi-heart"></i>
            </div>
          </div>
        ) : (
          <div
            style={{
              position: "absolute",
              top: 16,
              right: 20,
              fontSize: 34,
            }}
          >
            <div
              onClick={() => {
                _favorite(false);
                FavoriteService.Unfavorite(id, 1).then((res) => {
                  FavoriteService.getFavorites("Partner").then((res) => {
                    _favorites(res.data);
                  });
                });
              }}
              color="dark"
            >
              <i className="bi bi-heart-fill"></i>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className="main-content">
        {market && (
          <div className="main-container">
            <div
              style={{
                backgroundImage: `url(${market?.picture})`,
                height: 260,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                borderBottom: "0px solid #000",
                position: "relative",
              }}
            >
              <FavoriteIcon />
            </div>
            <div style={{ padding: 24 }}>
              <div style={{ textAlign: "center" }}>
                <h3>{market.title}</h3>
                {/* Favorite a partner */}
              </div>
              {market.description}
              <br />
              <br />{" "}
            </div>
            {market.slides && (
              <IonSlides pager={true} options={slideOpts}>
                {market.slides.split(",").map((slide: any, index: number) => {
                  return (
                    <IonSlide key={index} className="partner-slide">
                      <img src={ImageService.Url(slide)} />
                    </IonSlide>
                  );
                })}
              </IonSlides>
            )}

            <div
              style={{
                padding: 24,
                height: 240,
                marginLeft: -24,
                marginRight: -24,
              }}
            >
              {market.partnerItems && !market.itemsAsButtons ? (
                <div className="horizontal-container ">
                  {market &&
                    market.partnerItems &&
                    _.orderBy(market.partnerItems, "price").map(
                      (item: PartnerItem, index: number) => {
                        return (
                          <PartnerItemCheckout
                            key={item.id}
                            item={item}
                            partner={market}
                          />
                        );
                      }
                    )}
                </div>
              ) : (
                <div
                  style={{
                    paddingLeft: 24,
                    paddingRight: 24,
                    textAlign: "center",
                  }}
                >
                  {market.partnerItems?.map((item: PartnerItem) => {
                    return (
                      <div style={{ width: "100%" }} key={item.id}>
                        <PartnerItemCheckout
                          isButton={true}
                          item={item}
                          partner={market}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
}
