import * as _ from "lodash";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import "../Shared/theme/style.css";
import { favoritesPartnersState } from "../Shared/services/PartnerService";
import FavoriteService from "../Shared/services/FavoriteService";
import Card from "../Shared/Card";

interface Props {
  partners: any;
  favorited: () => void;
}

export default function PartnerFavorites(props: Props) {
  const [favorites, _favorites] = useRecoilState<any>(favoritesPartnersState);

  const cardWidth = 150;
  const cardHeight = 150;
  const history = useHistory();

  useEffect(() => {
    FavoriteService.getFavorites("Partner").then((res) => {
      _favorites(res.data);
    });
  }, []);

  function FavoriteList(favs: any) {
    if (favs !== null) {
      const favdata = _.orderBy(favs, ["date", "dsc"]);
      return favdata.map((favorite: any, index: number) => {
        let sf = false;
        if (favorites.favIds.includes(favorite.id)) {
          sf = true;
        }
        return (
          <Card
            key={index}
            id={favorite.id}
            title={favorite.title}
            // date={benefit.updatedDatemmddyyyy}
            picture={favorite.picture}
            showFavorite={true}
            favorite={sf}
            categoryId={1}
            amount={favorite.amount}
            clicked={() => {
              history.push("/partner/" + favorite.id);
            }}
            favorited={() => {
              _favorites({
                favIds: favorites.favIds.filter((x: any) => x != favorite.id),
              });
              props.favorited();
            }}
            width={cardWidth}
            height={cardHeight}
          />
        );
      });
    }
  }

  return (
    <div className="">
      <div className="favorites-header padding" style={{ paddingLeft: 22, fontWeight:500 }}>
        <span className="rubik">Your Favorites</span>
      </div>
      <div className="favorite-cards hide-scrollbar">
        <div className="hide-scrollbar" style={{ width: "100vw" }}>
          <div className="horizontal-container  hide-scrollbar">
            {props.partners.length != 0 ? (
              FavoriteList(props.partners)
            ) : (
              <div
                style={{
                  border: "1px solid black",
                  borderBottom: "3px solid black",
                  borderRight: "2px solid black",
                  width: cardWidth,
                  height: cardHeight,
                  borderRadius: 10,
                  textAlign: "center",
                  overflow: "hidden",
                  wordBreak: "break-word",
                  backgroundColor: "#eee",

                  boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.75)",
                  fontWeight: "bold",
                  paddingTop: 30,
                }}
              >
                <i
                  className="bi bi-heart"
                  style={{ color: "black", fontSize: 30 }}
                ></i>
                <br />
                Your favorites <br /> will appear here.
                <br />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
