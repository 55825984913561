import { Route, Redirect } from "react-router-dom";

function ProtectedRoute(props: any) {
  const token = localStorage.getItem("token");
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route path={props.path}>
      {token ? props.children : <Redirect to="/login" />}
    </Route>
  );
}

export default ProtectedRoute;
