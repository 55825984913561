import { useEffect, useRef, useState } from "react";
import { IonButton, IonContent, IonPage } from "@ionic/react";
import "../Shared/theme/style.css";
import { useRecoilState } from "recoil";
import vipService, { vipsState } from "../Shared/services/VIPService";
import MaxwellForm from "../Shared/services/MaxwellForm";

interface Props {
  prevStep?: any;
  nextStep?: any;
  gotoStep: (goStep: number) => void;
  handleChange: (input: any, e: any) => void;
  values?: any;
}

export default function OBAddVip(props: Props) {
  const [vips, _vips] = useRecoilState<any>(vipsState);
  const [firstName, _firstName] = useState<any>();
  const [lastName, _lastName] = useState<any>();
  const [relationship, _relationship] = useState<any>();
  const [genderId, _genderId] = useState<any>();
  const [birthdate, _birthdate] = useState<any>();
  const [interests, _interests] = useState<any>();
  const [child, _child] = useState<any>();
  const [dependent, _dependent] = useState<any>();
  const [curatedGifts, _curatedGifts] = useState<any>();
  const [anything, _anything] = useState<any>();
  const [vip, _vip] = useState<any>();

  // Form Validation Handling
  const [formErrors, _formErrors] = useState<any>([""]);
  function HandleInputErrors(errs: any) {
    const errors = formErrors.filter(
      (x: any) => x !== "" //|| x !== "Missing Required Fields"
    );
    if (errs.valid) {
      _formErrors(errors.filter((x: any) => x !== errs.error));
    } else {
      _formErrors([...errors, errs.error]);
    }
  }

  useEffect(() => {
    //console.log("values", props.values.s1199_EditVIP);
    if (props.values && props.values.s1199_EditVIP) {
      const existingVip = props.values.s1199_EditVIP;
      _vip(existingVip);
      _firstName(existingVip.firstName);
      _lastName(existingVip.lastName);
      _relationship(existingVip.relationship);
      _genderId(existingVip.genderId);
      _birthdate(existingVip.birthdate);
      _interests(existingVip.interests);
      _child(existingVip.child);
      _dependent(existingVip.dependent);
      _curatedGifts(existingVip.curatedGifts);
      _anything(existingVip.anything);
    }
  }, []);

  const modal = useRef<HTMLIonModalElement>(null);

  const Skip = (e: { preventDefault: () => void }) => {
    modal.current?.dismiss();
    //console.log("Skip Clicked");
  };

  return (
    <IonPage>
      <div className="main-container">
        {/* <OBHeader prevStep={() => props.gotoStep(11)} /> */}

        <IonContent>
          <div
            style={{
              paddingTop: 60,
              width: "100%",
            }}
          >
            <div
              style={{
                width: "90%",
                marginRight: "auto",
                marginLeft: "auto",
              }}
            >
              <MaxwellForm.Input
                label="First Name of VIP"
                required={true}
                value={firstName}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _firstName(value);
                }}
              />

              <MaxwellForm.Input
                label="Last Name of VIP"
                required={true}
                value={lastName}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _lastName(value);
                }}
              />

              <MaxwellForm.Input
                label="Relationship"
                required={true}
                value={relationship}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _relationship(value);
                }}
              />

              <MaxwellForm.Select
                label="What gender does your VIP identify with?"
                options={props.values.genders}
                value={genderId}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _genderId(value);
                }}
              />

              <MaxwellForm.DateTime
                label="Date of Birth"
                value={birthdate}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _birthdate(value);
                }}
              />

              <MaxwellForm.Input
                label="VIP interests (optional)"
                value={interests}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _interests(value);
                }}
              />

              <MaxwellForm.Input
                label="Anything else you would like us to know? (optional)"
                value={anything}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _anything(value);
                }}
              />

              <div style={{ marginTop: 40 }}>
                <IonButton
                  disabled={formErrors.length > 0}
                  onClick={() => {
                    //save
                    const newVip: any = {
                      firstName: firstName,
                      lastName: lastName,
                      relationship: relationship,
                      genderId: genderId,
                      birthdate: birthdate,
                      interests: interests,
                      child: child,
                      dependent: dependent,
                      curatedGifts: curatedGifts,
                      anything: anything,
                    };
                    if (vip && vip.id != null) {
                      newVip.id = vip.id;
                      vipService.putVIP(vip.id, newVip).then((res) => {
                        props.gotoStep(11);
                      });
                    } else {
                      vipService.postVIP(newVip).then((res) => {
                        props.gotoStep(11);
                      });
                    }
                  }}
                  expand="block"
                  color="light"
                >
                  {vip && vip.id !== null ? "Update" : "Save"}
                </IonButton>
              </div>
              <div style={{ marginTop: 30 }}>
                {vip && vip.id !== null && (
                  <IonButton expand="block" color="light">
                    Delete
                  </IonButton>
                )}
              </div>
              <IonButton
                className="backButton"
                expand="block"
                onClick={() => props.gotoStep(11)}
                color="light"
                style={{}}
              >
                Back
              </IonButton>
            </div>
          </div>
        </IonContent>
      </div>
    </IonPage>
  );
}
