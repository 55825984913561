import { IonContent, IonPage } from "@ionic/react";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router";
import { useRecoilState } from "recoil";
import HeadSubpage from "../Nav/HeaderSubpage";
import BenefitService, {
  benefitsState,
} from "../Shared/services/BenefitService";
import EmployerService, {
  employerState,
} from "../Shared/services/EmployerService";
import FavoriteService, {
  favoritesBenefitsState,
} from "../Shared/services/FavoriteService";
import MemberService, { memberState } from "../Shared/services/MemberService";
import Util, { featureFlagState } from "../Shared/services/UtilService";
import BenefitsFavorites from "./BenefitsFavorites";
import BenefitsHero from "./BenefitsHero";
import BenefitsList from "./BenefitsList";

const Benefits: React.FC = () => {
  const [member, _member] = useRecoilState<any>(memberState);
  const [benefits, _benefits] = useRecoilState<any>(benefitsState);
  const [favorites, _favorites] = useRecoilState<any>(favoritesBenefitsState);
  const [employer, _employer] = useRecoilState<any>(employerState);
  const history = useHistory();
  const location = useLocation();
  const [featureFlags, setFeatureFlags] = useRecoilState(featureFlagState);

  useEffect(() => {
    if (featureFlags == null) {
      Util.featureFlags().then((res) => {
        setFeatureFlags(res);
      });
    }

    BenefitService.getBenefits().then((res) => {
      _benefits(res.data);
      //console.log("getBenefits", res.data);
    });

    if (!member || !member.id) {
      MemberService.getMember().then((res) => {
        _member(res.data);
      });
    }

    EmployerService.currentEmployer().then((res) => {
      //console.log("currentEmployer", res.data);
      _employer(res.data);
    });

    FavoriteService.getFavorites("Benefit").then((res) => {
      try {
        _favorites(res.data);
        //console.log("favorites", res.data);
      } catch {
        //console.log("no favorites");
      }
    });

    const unlisten = history.listen(() => {
      //console.log("history.listen");
      FavoriteService.getFavorites("Benefit").then((res) => {
        try {
          _favorites(res.data);
          //console.log("favorites", res.data);
        } catch {
          //console.log("no favorites");
        }
      });
    });

    return () => {
      unlisten();
    };
  }, [history]);

  function isFavorite(element: any, index: any, array: any) {
    return favorites.favIds.includes(element.id);
  }

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className="main-content">
        {member && benefits && favorites && (
          <div className="main-container">
            <BenefitsHero />
            <BenefitsFavorites
              favorited={() => {
                setTimeout(() => {
                  BenefitService.getBenefits().then((res) => {
                    _benefits(res.data);
                  });
                }, 1000);
              }}
              benefits={benefits.filter(isFavorite)}
              favorites={favorites}
            />
            <div
              style={{ paddingLeft: 22, paddingRight: 16, marginBottom: -20 }}
            >
              <p>
                My <b>{employer?.name} </b>
                Benefits
              </p>
            </div>

            {benefits && (
              <BenefitsList benefits={benefits} favorites={favorites} />
            )}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Benefits;
