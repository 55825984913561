import { IonItem, IonLabel } from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router";
import "../Shared/theme/style.css";

interface Props {
  linkable?: boolean;
  avatar?: boolean;
  pet: any;
  index?: number;
}

export default function PetItem(props: Props) {
  const [modal, _modal] = useState(false);
  const history = useHistory();

  return (
    <IonItem
      key={props.index}
      onClick={() => {
        history.push("/pet/" + props.pet.id, { pet: props.pet });
      }}
      lines="full"
    >
      <IonLabel>
        <div className="section-header">
          {props.pet.petTypeId == 3 && <i className="fas fa-fish"></i>}
          {props.pet.petTypeId == 2 && <i className="fas fa-dog"></i>}
          {props.pet.petTypeId == 1 && <i className="fas fa-cat"></i>}{" "}
          {props.pet.name}
        </div>
      </IonLabel>
    </IonItem>
  );
}
