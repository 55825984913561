import { useRef } from "react";
import {
  IonButton,
  IonContent,
  IonPage,
  IonIcon,
  IonText,
  IonModal,
  IonItem,
  IonTitle,
} from "@ionic/react";
import { add, person } from "ionicons/icons";
import "../Shared/theme/style.css";
import React, { useEffect } from "react";
import { useRecoilState } from "recoil";
import WizardProgress from "./WizardProgress";
import vipService, { vipsState } from "../Shared/services/VIPService";

interface Props {
  prevStep?: any;
  nextStep?: any;
  gotoStep: (goStep: number) => void;
  handleChange: (input: any, e: any) => void;
  editVIP: (state: any, value: any) => void;
  values?: any;
}

export default function OBVips(props: Props) {
  const [vips, _vips] = useRecoilState<any>(vipsState);

  useEffect(() => {
    vipService.getVIPs().then((res) => {
      _vips(res.data);
    });
  }, []);

  function VIPsList() {
    if (vips && vips.length > 0) {
      return vips.map((vip: any, index: number) => {
        return (
          <IonItem
            onClick={() => {
              props.editVIP("s1199_EditVIP", vip);
              props.gotoStep(11999);
            }}
            key={index}
          >
            <IonIcon slot="start" icon={person} />
            <IonText>
              {vip.firstName} {vip.lastName}
            </IonText>
          </IonItem>
        );
      });
    }
  }
  const modal = useRef<HTMLIonModalElement>(null);

  const AddVip = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    props.editVIP("s1199_EditVIP", null);
    props.gotoStep(11999);
  };
  const Continue = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    props.nextStep();
    modal.current?.dismiss();
  };
  const Confirm = (e: { preventDefault: () => void }) => {
    props.gotoStep(11999);
    modal.current?.dismiss();
  };

  const Skip = (e: { preventDefault: () => void }) => {
    props.gotoStep(12);
    modal.current?.dismiss();
  };

  const Back = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    props.prevStep();
  };

  return (
    <IonPage>
      <div className="main-container">
        <IonContent>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "90%",
                marginRight: "auto",
                marginLeft: "auto",
                marginTop: 40,
              }}
            >
              <WizardProgress
                list={[
                  {
                    icon: "bi-clipboard-heart",
                    complete: true,
                    clicked: () => props.prevStep(),
                  },
                  { icon: "bi-people", complete: true },
                  { icon: "bi-question" },
                ]}
              />
              <IonText>
                {/* <h1 style={{ textAlign: "center" }}> */}
                <h1 style={{ paddingTop: 30 }}>
                  Who are your &ldquo;Very Important People&rdquo;?
                </h1>
                <p>
                  We will remember your loved ones, clients and other VIP&apos;s
                  birthdays, anniversaries or other important dates.
                </p>
                <p>
                  If they are your dependents get reimbursed for child or adult
                  care.
                </p>
              </IonText>
              <div className="mw-full-width">
                <IonButton
                  style={{ marginBottom: 30 }}
                  onClick={AddVip}
                  expand="block"
                  color="light"
                >
                  <IonIcon slot="start" icon={add}></IonIcon>
                  Add Vip
                </IonButton>
                <IonModal
                  // keepContentsMounted={true}
                  className="SkipVIPModal"
                  trigger="open-modal"
                  ref={modal}
                >
                  <IonContent className="ion-padding">
                    <div className="mw-full-width">
                      <IonTitle className="mw-center">
                        Are you sure you don&apos;t want to add any VIPS?
                      </IonTitle>
                      <div className="mw-div-center mw-width-60">
                        <IonButton
                          onClick={Confirm}
                          color="light"
                          class="mw-float-left mw-width-40 ion-color ion-color-light ios button button-solid ion-activatable ion-focusable"
                        >
                          ADD VIPS
                        </IonButton>
                        <IonButton
                          onClick={Skip}
                          color="dark"
                          class="mw-float-right mw-width-40 ion-color ion-color-dark ios button button-solid ion-activatable ion-focusable"
                        >
                          SURE
                        </IonButton>
                      </div>
                    </div>
                  </IonContent>
                </IonModal>
                {VIPsList()}
                {vips && vips.length > 0 && (
                  <IonButton
                    style={{ marginTop: 40 }}
                    onClick={Continue}
                    expand="block"
                    color="dark"
                  >
                    Continue
                  </IonButton>
                )}
                {vips && vips.length <= 0 && (
                  <IonButton
                    style={{ marginTop: 40 }}
                    id="open-modal"
                    expand="block"
                    color="dark"
                    onClick={Skip}
                  >
                    Skip
                  </IonButton>
                )}
                <IonButton
                  className="backButton"
                  expand="block"
                  onClick={Back}
                  color="light"
                  style={{ marginTop: 40 }}
                >
                  Back
                </IonButton>
              </div>
            </div>
          </div>
        </IonContent>
      </div>
    </IonPage>
  );
}
