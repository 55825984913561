import {
  IonAvatar,
  IonButtons,
  IonMenuToggle,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { useState } from "react";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import "../Shared/theme/style.css";
import NotificationBell from "../Notifications/NotificationBell";
import {
  balanceVisibleState,
  showBackState,
} from "../Shared/services/ActivityService";
import ImageService, { MxImg } from "../Shared/services/ImageService";

interface Props {
  linkable?: boolean;
  avatar?: boolean;
  member: any;
  showLogo?: boolean;
  returnTo?: string;
  logo?: string;
}

export default function MobileNavbar(props: Props) {
  const [modal, _modal] = useState(false);
  const [tapped, _tapped] = useState(0);
  const [timeout, _timeout] = useState<any>(null);
  const [balanceVisible, _balanceVisible] =
    useRecoilState<any>(balanceVisibleState);
  const [showBack, _showBack] = useRecoilState(showBackState);
  const history = useHistory();

  function tap() {
    // cancel previous timeout
    clearTimeout(timeout);

    if (tapped == 2) {
      _tapped(0);
    } else {
      _tapped(tapped + 1);
    }
    _timeout(setTimeout(() => _tapped(0), 5000));
  }

  return (
    <>
      <IonToolbar style={{ borderBottom: "1px black solid" }}>
        <IonTitle className="ion-text-center" style={{ fontWeight: 500 }}>
          {balanceVisible && props.member && props.member.balance != null ? (
            <>
              {tapped == 0 ? (
                <div onClick={tap} style={{ fontSize: 24 }}>
                  $
                  {(
                    Math.floor(
                      props.member.balance + props.member.regalosBalance
                    ) / 100
                  ).toFixed(2)}
                </div>
              ) : tapped == 1 ? (
                <>
                  <div>💳</div>
                  <div onClick={tap} style={{ fontSize: 14 }}>
                    ${(Math.floor(props.member.balance) / 100).toFixed(2)}
                  </div>
                </>
              ) : (
                tapped == 2 && (
                  <>
                    <div>🎁</div>
                    <div onClick={tap} style={{ fontSize: 14 }}>
                      $
                      {(Math.floor(props.member.regalosBalance) / 100).toFixed(
                        2
                      )}
                    </div>
                  </>
                )
              )}
            </>
          ) : (
            <img
              style={{ maxHeight: 50, width: "auto", height: "auto" }}
              src={
                props.logo
                  ? ImageService.Url(props.logo)
                  : "/assets/Maxwell-150_dpi-black-logo.png"
              }
            />
          )}
        </IonTitle>
        {!showBack ? (
          <IonMenuToggle slot="start" autoHide={false} menu="menu">
            <IonAvatar style={{ margin: 5, width: 50, height: 50 }}>
              {props.member && (
                <MxImg
                  alt="profile"
                  src={
                    props.member.picture
                      ? props.member.picture
                      : "https://api.maxwell.community/api/Image/File/DefaultProfilePicture.png"
                  }
                />
              )}
            </IonAvatar>
          </IonMenuToggle>
        ) : (
          <div
            onClick={() => {
              if (history.location.pathname.includes("member")) {
                history.replace("/regalos");
              } else {
                props.returnTo
                  ? history.replace(props.returnTo)
                  : history.goBack();
              }
            }}
            style={{
              fontSize: 28,
              paddingTop: 14,
              paddingLeft: 10,
              height: 60,
            }}
          >
            <i className="bi bi-arrow-left-square"></i>
          </div>
        )}
        <IonButtons slot="end">{!showBack && <NotificationBell />}</IonButtons>
      </IonToolbar>
    </>
  );
}
