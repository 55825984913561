import { IonButton, IonContent, IonPage } from "@ionic/react";
import "../Shared/theme/style.css";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import OrderService from "../Shared/services/OrderService";
import HeadSubpage from "../Nav/HeaderSubpage";
import AddFundsForm from "./AddFundsForm";

export default function AddFunds() {
  const history = useHistory();
  const [stripePromise, _stripePromise] = useState<any>(null);
  const [clientSecret, _clientSecret] = useState<any>(null);
  const [fees, _fees] = useState<any>(null);

  const { amount } = useParams<{ amount: string }>();

  useEffect(() => {
    const pk = process.env.REACT_APP_STRIPE_PK;
    if (pk) {
      _stripePromise(loadStripe(pk));
    }
  }, []);

  useEffect(() => {
    const value = parseFloat(amount.toString());
    const stripeValue = value * 1.029 + 30;
    const calcFee = Math.round(stripeValue * 0.029 + 30);
    _fees(calcFee);
    console.log(calcFee + value);
    OrderService.postPaymentIntent(calcFee + value).then((res) => {
      console.log(res.data);
      _clientSecret(res.data);
    });
  }, []);

  return (
    <IonPage>
      {amount && stripePromise && clientSecret && (
        <Elements stripe={stripePromise} options={{ clientSecret }}>
          <IonContent fullscreen className="main-content">
            <div className="main-container">
              <HeadSubpage />
              <div style={{ padding: 24, textAlign: "left", marginTop: 50 }}>
                <h2>Add Funds to Your Maxwell Account</h2>

                <h1>${amount && parseInt(amount) / 100}</h1>

                {/* Stripe accept payment element */}
                <AddFundsForm
                  fees={fees}
                  onPaymentSuccess={() => {
                    history.goBack();
                    setTimeout(() => {
                      history.goBack();
                    }, 100);
                  }}
                />

                <div style={{ marginTop: 20 }}>
                  <IonButton
                    expand="block"
                    color="light"
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </IonButton>
                </div>
              </div>
            </div>
          </IonContent>
        </Elements>
      )}
    </IonPage>
  );
}
