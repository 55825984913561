import React from "react";
import JSConfetti from "js-confetti";
import Confetti from "react-confetti";

const MxConfetti = {
  addConfetti: (string: string, emojis?: any) => {
    const jsConfetti = new JSConfetti();
    console.log(string);

    if (string.toLocaleLowerCase().includes("birthday")) {
      if (emojis) {
        jsConfetti.addConfetti({ emojis: emojis });
      } else {
        jsConfetti.addConfetti();
      }
      setTimeout(() => {
        if (emojis) {
          jsConfetti.addConfetti({ emojis: emojis });
        } else {
          jsConfetti.addConfetti({
            confettiRadius: 8,
          });
        }
      }, 300);

      setTimeout(() => {
        if (emojis) {
          jsConfetti.addConfetti({ emojis: emojis });
        } else {
          jsConfetti.addConfetti({ confettiNumber: 1000 });
        }
      }, 1000);
    }

    if (string.toLocaleLowerCase().includes("bonus")) {
      return (
        <Confetti recycle={false} numberOfPieces={1000} tweenDuration={30000} />
      );
    }
    return null;
  },
};

export default MxConfetti;
