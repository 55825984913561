import axios from "axios";
import { atom } from "recoil";
import { RegalosMessage } from "../types/regalosMessage";
const APIURL = process.env.REACT_APP_API_HOST;

export const regalosFavoriteState = atom({
  key: "regalosFavoriteState", // unique ID (with respect to other atoms/selectors)
  default: [],
});

export const regalosActivityState = atom({
  key: "regalosActivityState", // unique ID (with respect to other atoms/selectors)
  default: [],
});

const RegalosService = {
  //   async getRegalos(memberid: number) {
  //     return axios.get(`${APIURL}/Regalos/GetRegalos/${memberid}`);
  //   },
  async getAllRegalos(page?: number) {
    return axios.get(
      `${APIURL}/Regalos/GetAllRegalos` + (page ? `?page=` + page : "")
    );
  },
  async getMemberRegalos() {
    return axios.get(`${APIURL}/Regalos/GetMemberRegalos`);
  },
  async getByMemberRegalos(id: any) {
    return axios.get(`${APIURL}/Regalos/GetByMemberRegalos/` + id);
  },
  async getRegalos(id: number) {
    return axios.get(`${APIURL}/Regalos/GetRegalos/${id}`);
  },
  // PostTransfer - take money from regalos and move to maxcard
  async postTransfer(regalos: any) {
    return axios.post(`${APIURL}/Regalos/PostTransfer`, { amount: regalos });
  },
  async postRegalos(regalos: any) {
    return axios.post(`${APIURL}/Regalos/PostRegalos`, regalos);
  },
  async UpdateRegalos(id: number, regalos: any) {
    return axios.put(`${APIURL}/Regalos/PutRegalos/` + id, regalos);
  },
  async deleteRegalos(id: number) {
    return axios.delete(`${APIURL}/Regalos/DeleteRegalos/${id}`);
  },
  async getRegalosMessage(id: number) {
    return axios.get(`${APIURL}/RegalosMessages/GetRegalosMessage/${id}`);
  },
  async putRegalosMessage(id: number, regalos: any) {
    return axios.put(
      `${APIURL}/RegalosMessages/PutRegalosMessage/${id}`,
      regalos
    );
  },
  async postRegalosMessage(message: RegalosMessage) {
    return axios.post(`${APIURL}/RegalosMessages/PostRegalosMessage`, message);
  },
  async deleteRegalosMessage(id: number) {
    return axios.delete(`${APIURL}/RegalosMessages/DeleteRegalosMessage/${id}`);
  },
  // heart a regalos
  async putRegalosHeart(id: number) {
    return axios.put(`${APIURL}/Regalos/PutRegalosHeart/${id}`);
  },
  // unheart a regalos
  async putRegalosUnheart(id: number) {
    return axios.put(`${APIURL}/Regalos/PutRegalosUnheart/${id}`);
  },
  //PostRegalosFromWallet
  async postRegalosFromWallet(regalos: any) {
    return axios.post(`${APIURL}/Regalos/PostRegalosFromWallet`, regalos);
  },
  // UpdateRegalosTitle
  async UpdateRegalosTitle(id: number, titleId: number) {
    return axios.put(`${APIURL}/Regalos/UpdateRegalosTitle`, {
      id: id,
      titleId: titleId,
    });
  },
};

export default RegalosService;
