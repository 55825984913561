import {
  IonButton,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonLabel,
  IonRow,
  IonToggle,
} from "@ionic/react";
import PlasticCard from "./PlasticCard";
import { refresh, wallet } from "ionicons/icons";
import memberService from "../Shared/services/MemberService";
import { useState } from "react";

interface Props {
  member: any;
  physicalCard: any;
  cards: any;
  hasPhysicalCard: boolean;
  showReplaceCardModal: (e: boolean) => void;
  showOrderCardModal: (e: boolean) => void;
}

export default function SlidePhysicalCard(props: Props) {
  const [cardActive, setCardActive] = useState(
    props.physicalCard.status == "active"
  );
  return (
    <>
      <div className="slide-card">
        <h3
          style={{
            textAlign: "left",
          }}
        >
          Physical Card
        </h3>
        <IonGrid className="ion-no-padding">
          <IonRow>
            <IonCol sizeMd="6">
              <div style={{ minWidth: 200 }}>
                <PlasticCard
                  amount={props.member.balance}
                  last4={props.physicalCard?.last4}
                  type={"physical"}
                  name={props.member.firstName + " " + props.member.lastName}
                />
              </div>
            </IonCol>
            <IonCol sizeMd="6">
              <div style={{ paddingTop: 14 }}>
                <IonButton
                  onClick={() => {
                    props.showReplaceCardModal(true);
                  }}
                  expand="block"
                  color="dark"
                >
                  <IonIcon icon={refresh} />
                  &nbsp;&nbsp; Replace Card
                </IonButton>
              </div>
              <div style={{ paddingTop: 14 }}>
                {props.physicalCard && (
                  <IonItem>
                    <IonLabel>
                      Card {cardActive ? "Active" : "Inactive"}
                    </IonLabel>
                    <IonButton
                      onClick={(e) => {
                        // console.log("e", e.target.checked);
                        if (!cardActive) {
                          setCardActive(true);
                          memberService
                            .activateCard(props.physicalCard.id)
                            .then((res: any) => {
                              // console.log("res", res);
                            });
                        } else {
                          setCardActive(false);
                          memberService
                            .deactivateCard(props.physicalCard.id)
                            .then((res: any) => {
                              // console.log("res", res);
                            });
                        }
                      }}
                      slot="end"
                      color={"dark"}
                      expand="block"
                    >
                      {!cardActive ? "Activate Card" : "Deactivate Card"}
                    </IonButton>
                  </IonItem>
                )}
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </div>
    </>
  );
}
