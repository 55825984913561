import {
  IonButton,
  IonContent,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
} from "@ionic/react";
import "../Shared/theme/style.css";
import { useState } from "react";
import { useHistory } from "react-router";

export default function SignUp() {
  const [segment, _segment] = useState<any>("card");
  const history = useHistory();
  return (
    <IonPage>
      <IonContent fullscreen className="main-content">
        <div className="main-container">
          <div style={{ padding: 24, textAlign: "center", marginTop: 75 }}>
            <img
              style={{ width: 210, height: "auto" }}
              src="/assets/Maxwell-150_dpi-black-logo.png"
            />
            <div style={{ paddingTop: 50 }}>
              <h3>Create Your Account</h3>
              <IonItem>
                <IonLabel position="stacked">Email Address</IonLabel>
                <IonInput type="text" placeholder="name@example.com" />
              </IonItem>
              <div style={{ marginTop: 64 }}>
                <IonButton
                  onClick={() => {
                    history.replace("/onboarding");
                  }}
                  expand="block"
                  color="dark"
                >
                  Sign Up
                </IonButton>
              </div>
              <div style={{ marginTop: 24 }}>
                <IonButton
                  onClick={() => {
                    history.replace("/login");
                  }}
                  expand="block"
                  size="small"
                >
                  Login
                </IonButton>
              </div>
            </div>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
