import {
  IonAvatar,
  IonCol,
  IonGrid,
  IonItem,
  IonPopover,
  IonRow,
} from "@ionic/react";
import { animated, useInView } from "@react-spring/web";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import ImageService, { MxImg } from "../Shared/services/ImageService";
import { memberState } from "../Shared/services/MemberService";
import RegalosService from "../Shared/services/RegalosService";
import "../Shared/theme/style.css";
import { Member } from "../Shared/types/member";
import { Regalos } from "../Shared/types/regalos";

interface Props {
  activity: Regalos;
  onUpdated: () => void;
  showPopover: boolean;
  setShowPopover: (regalosTag: any, x: number, y: number) => void;
}

export default function RegalosItem(props: Props) {
  const history = useHistory();
  const [hearted, _hearted] = useState(false);
  const [member, _member] = useRecoilState<any>(memberState);

  useEffect(() => {
    _hearted(props.activity.isHearted);
  }, []);

  function HeartedList() {
    return props.activity.heartedMembers.map(
      (member: Member, index: number) => {
        return (
          <IonAvatar
            key={index}
            style={{
              width: 30,
              height: 30,
              marginLeft: -16,
              display: "inline-block",
              boxShadow: "0px 0px 5px #00000033",
            }}
          >
            {member && (
              <MxImg
                alt="Silhouette of mountains"
                src={
                  member.picture
                    ? member.picture
                    : "https://api.maxwell.community/api/Image/File/DefaultProfilePicture.png"
                }
              />
            )}
          </IonAvatar>
        );
      }
    );
  }

  function isMine() {
    return (
      props.activity.senderMember?.id === member?.id ||
      props.activity.receiverMember?.id === member?.id
    );
  }

  const [annRef, inView] = useInView(
    () => ({
      from: {
        scale: 0.95,
      },
      to: {
        scale: 1,
      },
    }),
    {
      rootMargin: "0% 0%",
    }
  );

  function LikedHeart() {
    return (
      <>
        {props.activity.isHearted || hearted ? (
          <i
            onClick={() => {
              // remove member from heartedMembers
              const newRegalos: any = structuredClone(props.activity);
              newRegalos.isHearted = false;
              const newMembers = [];
              for (const m of newRegalos.heartedMembers) {
                if (m.id && m.id != member.id) {
                  newMembers.push(m);
                }
              }

              newRegalos.heartedMembers = newMembers;

              // _regalos(newRegalos);
              props.onUpdated();
              setTimeout(() => {
                _hearted(false);
                props.onUpdated();
              }, 100);
              RegalosService.putRegalosUnheart(props.activity.id);
            }}
            className="icon bi-heart-fill"
          ></i>
        ) : (
          <i
            onClick={() => {
              // add member to heartedMembers
              console.log("newRegalos", props.activity);
              const newRegalos = structuredClone(props.activity);
              newRegalos.isHearted = true;
              newRegalos.heartedMembers.push(member);

              // _regalos(newRegalos);

              props.onUpdated();
              setTimeout(() => {
                _hearted(true);
                props.onUpdated();
              }, 100);

              RegalosService.putRegalosHeart(props.activity.id);
            }}
            className="icon bi-heart"
          ></i>
        )}
      </>
    );
  }

  return (
    <>
      <div style={{ paddingTop: 24, backgroundColor: "#fcfcfc" }}>
        <animated.div
          ref={annRef}
          onClick={() => {
            history.push(
              "/regalos-message/" + props.activity.id,
              props.activity
            );
          }}
          style={{ width: 65, display: "inline-block", ...inView }}
        >
          <IonAvatar style={{ width: 55, height: 55 }} slot="start">
            {props.activity.senderMember && (
              <MxImg
                alt="Silhouette of mountains"
                src={
                  props.activity.senderMember.picture
                    ? props.activity.senderMember.picture
                    : "https://api.maxwell.community/api/Image/File/DefaultProfilePicture.png"
                }
              />
            )}
          </IonAvatar>
        </animated.div>
        <div
          style={{
            width: "calc(100% - 81px)",
            display: "inline-block",
            verticalAlign: "top",
          }}
        >
          <>
            <IonGrid className="ion-no-padding">
              <div style={{ display: "flex" }}>
                <div
                  style={{ flex: 1 }}
                  onClick={() => {
                    history.push(
                      "/regalos-message/" + props.activity.id,
                      props.activity
                    );
                  }}
                >
                  <div>
                    <span style={{ fontWeight: 500 }}>
                      {props.activity.senderMember?.preferredName ??
                        props.activity.senderMember?.firstName}
                    </span>
                    &nbsp;to&nbsp;
                    <span style={{ fontWeight: 500 }}>
                      {props.activity.receiverMember?.preferredName ??
                        props.activity.receiverMember?.firstName}
                    </span>
                    &nbsp;
                  </div>
                  <div className="sub-label">
                    {moment(props.activity.createdDate).fromNow()}
                  </div>
                </div>
                <div style={{ fontWeight: "bold", textAlign: "right" }}>
                  {props.activity.regalosTag &&
                    props.activity.regalosTag.id && (
                      <div
                        onClick={(e) => {
                          props.setShowPopover(
                            props.activity.regalosTag,
                            e.clientX,
                            e.clientY
                          );
                        }}
                        style={{
                          width: 44,
                          height: 44,
                          borderRadius: 5,
                          border: "0px solid black",
                          boxShadow: "0px 0px 5px #00000033",
                          backgroundColor:
                            props.activity.regalosTag.color || "black",
                          backgroundImage:
                            props.activity.regalosTag.icon &&
                            `url(${ImageService.Url(
                              props.activity.regalosTag.icon
                            )})`,
                          backgroundSize: "contain",
                          backgroundPosition: "center center",
                        }}
                      ></div>
                    )}
                </div>
              </div>
              <IonRow
                onClick={() => {
                  history.push(
                    "/regalos-message/" + props.activity.id,
                    props.activity
                  );
                }}
                className="ion-no-padding"
              >
                <IonCol className="ion-no-padding"></IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <div style={{ fontWeight: "bold" }} className="sub-label">
                    {!props.activity.isPublic && (
                      <i
                        style={{ fontSize: 10 }}
                        className="fas fa-eye-slash"
                      ></i>
                    )}
                    &nbsp;
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </>
        </div>
        <IonItem lines="full">
          <IonGrid>
            <IonRow
              onClick={() => {
                history.push(
                  "/regalos-message/" + props.activity.id,
                  props.activity
                );
              }}
            >
              <IonCol>
                {props.activity &&
                props.activity.note &&
                props.activity.note.length > 64
                  ? props.activity.note
                      .substring(0, 64)
                      .slice(
                        0,
                        props.activity.note.substring(0, 64).lastIndexOf(" ")
                      )
                  : props.activity.note}

                {props.activity &&
                  props.activity.note &&
                  props.activity.note.length > 60 &&
                  "..."}
              </IonCol>
            </IonRow>
            <div style={{ paddingTop: 8, display: "flex" }}>
              <div style={{ textAlign: "right", fontWeight: "bold", flex: 1 }}>
                {props.activity &&
                  props.activity.heartedMembers &&
                  HeartedList()}
                &nbsp; &nbsp;
                <LikedHeart />
                <div
                  style={{
                    textAlign: "right",
                    fontWeight: "bold",
                    verticalAlign: "bottom",
                    fontSize: 10,
                    display: "inline-block",
                  }}
                >
                  {/* &nbsp;likes */}
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", alignSelf: "flex-end" }}>
                  &nbsp;&nbsp;{" "}
                  {props.activity.messages &&
                  props.activity.messages.length > 0 ? (
                    <i className="icon bi-chat-fill"></i>
                  ) : (
                    <i className="icon bi-chat"></i>
                  )}{" "}
                  &nbsp;
                </div>
                <div
                  style={{
                    textAlign: "right",
                    fontWeight: "bold",
                    fontSize: 10,
                    alignSelf: "flex-end",
                  }}
                >
                  {/* comments */}
                </div>
              </div>
            </div>
          </IonGrid>
        </IonItem>
      </div>
    </>
  );
}
