import {
  IonButton,
  IonContent,
  IonPage,
  IonSlide,
  IonSlides,
} from "@ionic/react";
import "./theme/style.css";
import { useHistory } from "react-router";

export default function AddToHome() {
  const history = useHistory();

  return (
    <IonPage>
      <IonContent>
        <div style={{ textAlign: "center", marginTop: 20, padding: 22 }}>
          <div
            style={{
              width: 100,
              height: 100,
              backgroundColor: "white",
              borderRadius: 10,
              border: "1px solid black",
              borderBottom: "3px solid black",
              borderRight: "2px solid black",
              boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.75)",
              margin: "auto",

              padding: 10,
            }}
          >
            <div
              style={{
                width: "100%",
                height: "100%",
                backgroundImage: `url(${"/assets/Maxwell-150_dpi-black-logo.png"})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            ></div>
          </div>
          <h1>Add To Home Screen</h1>
          <div>From Safari on your Apple device</div>
          <div style={{ fontSize: "small", color: "grey", paddingTop: 4 }}>
            Add to home is only supported with in Safari on Apple devices
          </div>
          <IonSlides pager={true} style={{ marginLeft: -22, marginRight: -22 }}>
            <IonSlide style={{ height: 460, flexDirection: "column" }}>
              <div>
                <img
                  style={{
                    height: 360,
                    width: "auto",
                    border: "1px solid black",
                    borderBottom: "3px solid black",
                    borderRight: "2px solid black",
                    borderRadius: 10,
                  }}
                  src="/images/iOS_1.png"
                />
              </div>
              <div style={{ padding: 10 }}>1/3</div>
            </IonSlide>
            <IonSlide style={{ height: 460, flexDirection: "column" }}>
              <div>
                <img
                  style={{
                    height: 360,
                    width: "auto",
                    border: "1px solid black",
                    borderBottom: "3px solid black",
                    borderRight: "2px solid black",
                    borderRadius: 10,
                  }}
                  src="/images/iOS_2.png"
                />
              </div>
              <div style={{ padding: 10 }}>2/3</div>
            </IonSlide>
            <IonSlide style={{ height: 460, flexDirection: "column" }}>
              <div>
                <img
                  style={{
                    height: 360,
                    width: "auto",
                    border: "1px solid black",
                    borderBottom: "3px solid black",
                    borderRight: "2px solid black",
                    borderRadius: 10,
                  }}
                  src="/images/iOS_3.png"
                />
              </div>
              <div style={{ padding: 10 }}>3/3</div>
            </IonSlide>
          </IonSlides>
          <div>
            <IonButton
              style={{ marginTop: 20 }}
              color="dark"
              expand="block"
              onClick={() => {
                history.goBack();
              }}
            >
              Close
            </IonButton>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
}
