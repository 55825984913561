import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonItem,
  IonLabel,
  IonModal,
  IonPage,
  IonRow,
  IonSelect,
  IonSelectOption,
  useIonViewWillLeave,
} from "@ionic/react";
import _, { round } from "lodash";
import Lottie from "lottie-react";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import HeadSubpage from "../Nav/HeaderSubpage";
import cartLottie from "../Shared/lottie/KectpQrW65.json";
import MaxwellForm from "../Shared/services/MaxwellForm";
import memberService, { memberState } from "../Shared/services/MemberService";
import OrderService from "../Shared/services/OrderService";
import "../Shared/theme/style.css";
import { Address } from "../Shared/types/address";
import { PartnerItem } from "../Shared/types/partnerItem";

function Checkout() {
  const [member, _member] = useRecoilState<any>(memberState);
  const [membership, _membership] = useState<boolean>(false);
  const [loading, _loading] = useState<boolean>(true);
  const [total, _total] = useState<number>(0);
  const [balance, _balance] = useState<number>(0);
  const [regalos, _regalos] = useState<number>(0);
  const [addresses, _addresses] = useState<Array<any>>();
  const [paymentLoading, _paymentLoading] = useState<boolean>(false);
  const [address, _address] = useState<any>();
  const [used, _used] = useState<any>(0);
  const [usedRegalos, _usedRegalos] = useState<any>(0);
  const [due, _due] = useState<number>();
  const [cart, _cart] = useState<Array<PartnerItem>>();
  const [payModalOpen, _payModalOpen] = useState<boolean>(false);
  const [handling, _handling] = useState<number>(0);
  const [tax, _tax] = useState<number>(0);
  const history = useHistory();

  useEffect(() => {
    let c: any = [];
    const localCart = localStorage.getItem("cart");
    let t = 0;

    if (localCart) {
      c = JSON.parse(localCart);
      console.log("localCart", c);
      t = _.sumBy(c, "price") * 0.01;
      const h = _.sumBy(c, "handlingFee") * 0.01;
      let tx = 0;
      for (const i of c) {
        if (i.isTaxed) {
          tx += i.price * 0.07 * 0.01;
        }
      }
      _tax(tx);
      _handling(h);
      _cart(c);
      _total(t + h + tx);
      _due(t);
    }

    memberService.getMember().then((res) => {
      //console.log("member", res.data);
      const b = res.data.balance * 0.01;
      const r = res.data.regalosBalance * 0.01;
      _member(res.data);
      if (res.data && b) {
        _balance(b);
        //console.log("balance", b);
      }
      if (res.data && r) {
        _regalos(r);
      }
      _addresses(res.data.addresses);
      // set default address
      if (res.data.addresses && res.data.addresses.length > 0) {
        _address(res.data.addresses[0]);
      }

      // if membership, set used to amount of membership
      //console.log("c", c);

      _loading(false);

      //
    });
  }, []);

  useIonViewWillLeave(() => {
    _payModalOpen(false);
  });

  function available() {
    const y = balance > usedRegalos ? balance - usedRegalos : balance;
    return balance > total ? total - usedRegalos : y;
  }
  function after() {
    let send = 0;
    if (balance - available() == 0) {
      send = 0;
    } else {
      send = balance - available();
    }
    if (usedRegalos == total) {
      send = balance;
    }
    return send;
  }

  function availableRegalos() {
    const y = regalos > used ? regalos - used : regalos;
    return regalos > total ? total - used : y;
  }

  function afterRegalos() {
    let send = 0;
    if (regalos - availableRegalos() == 0) {
      send = 0;
    } else {
      send = regalos - availableRegalos();
    }
    if (used == total) {
      send = regalos;
    }
    return send;
  }

  function currency(number: number) {
    return _.round(number, 2).toFixed(2);
  }

  function List() {
    if (cart && cart[0]) {
      return cart.map((item: PartnerItem, index: number) => {
        return (
          <IonItem key={index}>
            <IonLabel>{item.name}</IonLabel>
            <IonLabel slot="end">
              ${item.price && currency(item.price * 0.01)}
              {item.membership && <div style={{ fontSize: 10 }}>Monthly</div>}
            </IonLabel>
          </IonItem>
        );
      });
    } else {
      return <h2>Cart Is Empty</h2>;
    }
  }

  return (
    <IonPage>
      <HeadSubpage />
      <IonContent fullscreen className="main-content ">
        <div className="main-container leave-room-for-menu ion-padding">
          <div style={{ paddingTop: 20 }}>
            <h4>Your Order</h4>
          </div>
          {!loading ? (
            <>
              {List()}
              {(cart && cart[0] && (
                <IonGrid className="ion-no-padding">
                  <IonRow style={{ paddingTop: 15 }}>
                    <IonCol>Handling</IonCol>
                    <IonCol style={{ textAlign: "right" }}>
                      ${currency(handling)}
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ paddingTop: 5 }}>
                    <IonCol>Tax</IonCol>
                    <IonCol style={{ textAlign: "right" }}>
                      ${currency(tax)}
                    </IonCol>
                  </IonRow>
                  <IonRow
                    style={{ fontWeight: 500, fontSize: 18, paddingTop: 5 }}
                  >
                    <IonCol>Total</IonCol>
                    <IonCol style={{ textAlign: "right" }}>
                      ${currency(total)}
                    </IonCol>
                  </IonRow>
                  <IonRow style={{ marginTop: 44 }}>
                    <h3>Payment Options</h3>
                  </IonRow>
                  <IonRow>
                    <IonCol>
                      <IonItem>
                        <IonLabel>
                          <i className="bi bi-credit-card-fill"></i>
                          &nbsp; Maxwell Card
                          <div style={{ fontSize: 12 }}>
                            ${currency(balance)}
                            <span style={{ color: "#696969" }}>
                              &nbsp;balance
                            </span>
                            <br />${currency(balance - used)}
                            <span style={{ color: "#696969" }}>
                              &nbsp;after
                            </span>
                          </div>
                        </IonLabel>
                        <MaxwellForm.ItemDollars
                          max={due}
                          onChange={(value) => _used(value)}
                          value={used}
                        />
                      </IonItem>

                      <IonItem>
                        <IonLabel>
                          <i className="bi bi-credit-card"></i>
                          &nbsp; Regalos
                          <div style={{ fontSize: 12 }}>
                            ${currency(regalos)}
                            <span style={{ color: "#696969" }}> balance</span>
                            <br />${currency(regalos - usedRegalos)}
                            <span style={{ color: "#696969" }}> after</span>
                          </div>
                        </IonLabel>
                        <MaxwellForm.ItemDollars
                          onChange={(value) => _usedRegalos(value)}
                          value={usedRegalos}
                        />
                      </IonItem>
                    </IonCol>
                  </IonRow>
                  {!membership && (
                    <IonRow
                      style={{ fontWeight: 500, fontSize: 18, paddingTop: 16 }}
                    >
                      <IonCol>Due</IonCol>
                      <IonCol
                        style={{
                          textAlign: "right",
                          color:
                            parseFloat(used) + parseFloat(usedRegalos) ==
                            _.round(total, 2)
                              ? "black"
                              : "red",
                        }}
                      >
                        $
                        {currency(
                          total - parseFloat(used) - parseFloat(usedRegalos)
                        )}
                      </IonCol>
                    </IonRow>
                  )}
                  {addresses && (
                    <IonRow>
                      <IonCol>
                        <div style={{ marginBottom: 0, marginTop: 50 }}>
                          <IonItem>
                            <IonLabel>Select Address</IonLabel>
                            <IonSelect
                              value={address}
                              selectedText={address?.name}
                              onIonChange={(e) => {
                                _address(e.detail.value);
                              }}
                            >
                              {addresses &&
                                addresses.map((address: Address, index) => {
                                  return (
                                    <IonSelectOption
                                      key={index}
                                      value={address.id}
                                    >
                                      {address.name}
                                    </IonSelectOption>
                                  );
                                })}
                            </IonSelect>
                          </IonItem>
                        </div>
                      </IonCol>
                    </IonRow>
                  )}
                  <IonRow style={{ paddingTop: 30 }}>
                    <IonCol>
                      {parseFloat(usedRegalos) <= regalos &&
                      parseFloat(used) <= balance &&
                      parseFloat(used) + parseFloat(usedRegalos) ==
                        _.round(total, 2) ? (
                        <>
                          <IonButton
                            disabled={address == null || paymentLoading}
                            onClick={async () => {
                              _paymentLoading(true);
                              _payModalOpen(true);
                              const detailsJson =
                                localStorage.getItem("cart-details");

                              const checkout: any = {
                                fromRegalos: usedRegalos
                                  ? round(usedRegalos * 100)
                                  : 0,
                                total: round(total * 100),
                                partnerItems: cart,
                                addressId: address.id,
                                details: detailsJson ? detailsJson : null,
                              };
                              // create stripe payment intent
                              if (
                                window.Stripe &&
                                process.env.REACT_APP_STRIPE_PK
                              ) {
                                // create order on database
                                OrderService.postCheckout(checkout)
                                  .then(async (res) => {
                                    localStorage.removeItem("cart");
                                    if (
                                      window.Stripe &&
                                      process.env.REACT_APP_STRIPE_PK
                                    ) {
                                      const stripe = window.Stripe(
                                        process.env.REACT_APP_STRIPE_PK
                                      );

                                      stripe
                                        .confirmCardPayment(res.data)
                                        .then((piRes) => {
                                          console.log("payment success", piRes);
                                          if (
                                            piRes &&
                                            piRes.paymentIntent &&
                                            piRes.paymentIntent.status ==
                                              "succeeded"
                                          ) {
                                            const checkoutSuccess: any = {
                                              fromRegalos: usedRegalos
                                                ? round(usedRegalos * 100)
                                                : 0,
                                              total: round(total * 100),
                                              partnerItems: cart,
                                              addressId: address.id,
                                              details: detailsJson
                                                ? detailsJson
                                                : null,
                                              paymentIntentId:
                                                piRes.paymentIntent.id,
                                            };

                                            OrderService.postCheckoutSuccess(
                                              checkoutSuccess
                                            );
                                            _payModalOpen(false);
                                            setTimeout(() => {
                                              history.replace(
                                                "/checkout-success"
                                              );
                                            }, 500);
                                          }
                                          if (piRes.error) {
                                            console.log(piRes.error);
                                            alert(piRes.error.message);
                                            _paymentLoading(false);
                                            _payModalOpen(false);
                                          }
                                        })
                                        .catch((err) => {
                                          console.log(err);
                                          alert(err.message);
                                          _paymentLoading(false);
                                          _payModalOpen(false);
                                        });
                                    }
                                  })
                                  .catch((err) => {
                                    console.log(err);
                                    alert(err.message);
                                    _paymentLoading(false);
                                    _payModalOpen(false);
                                  });
                              }

                              // history.replace("/checkout-success");
                            }}
                            expand="block"
                            color="dark"
                          >
                            {address ? (
                              <>
                                {!membership
                                  ? "Pay $" + total.toFixed(2)
                                  : "Submit Order"}
                              </>
                            ) : (
                              <>Select Address</>
                            )}
                          </IonButton>
                        </>
                      ) : (
                        <>
                          {regalos < parseFloat(usedRegalos) ||
                          balance + regalos < total ? (
                            <h4 style={{ textAlign: "center", color: "red" }}>
                              Insufficient Funds
                            </h4>
                          ) : (
                            <h4 style={{ textAlign: "center", color: "#777" }}>
                              Select Payment Options
                            </h4>
                          )}
                        </>
                      )}
                    </IonCol>
                  </IonRow>
                  <IonRow>
                    <IonCol style={{ paddingTop: 20 }}>
                      <div
                        onClick={() => {
                          localStorage.removeItem("cart");
                          history.replace("/market");
                        }}
                        style={{
                          cursor: "pointer",
                          textAlign: "center",
                          fontWeight: "bold",
                          textDecoration: "underline",
                        }}
                      >
                        Cancel
                      </div>
                    </IonCol>
                  </IonRow>
                </IonGrid>
              )) || (
                <>
                  <Skeleton height={120} />
                  <Skeleton height={10} />
                  <Skeleton height={60} />
                  <Skeleton height={10} />
                  <Skeleton height={70} />
                  <Skeleton height={70} />
                  <Skeleton height={70} />
                </>
              )}
            </>
          ) : (
            <>
              <Skeleton height={120} />
              <Skeleton height={10} />
              <Skeleton height={60} />
              <Skeleton height={10} />
              <Skeleton height={70} />
              <Skeleton height={70} />
              <Skeleton height={70} />
              <Skeleton height={10} />
              <Skeleton height={70} />
              <div style={{ textAlign: "center", padding: 20 }}>
                <Skeleton height={40} width={200} />
              </div>
            </>
          )}
          {!cart && !loading && (
            <IonButton
              color="light"
              expand="block"
              onClick={() => {
                history.replace("/market");
              }}
            >
              Back to Marketplace
            </IonButton>
          )}
        </div>
        <IonModal isOpen={payModalOpen}>
          <IonContent fullscreen className="main-content ">
            <div className="main-container leave-room-for-menu ion-padding">
              <div style={{ paddingTop: 20, textAlign: "center" }}>
                <Lottie animationData={cartLottie} loop={true} />
                <h1>Submitting Order!</h1>
                <div style={{ paddingTop: 30 }}>We are sending your order.</div>
                <div style={{ paddingTop: 30 }}>
                  We will review and reach out if needed.
                </div>
                <div style={{ fontWeight: "bold", paddingTop: 30 }}>
                  Here&apos;s to making <br />
                  life/work with you.
                </div>
                <div style={{ paddingTop: 30 }}></div>
              </div>
            </div>
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
}

export default Checkout;
