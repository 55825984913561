import {
  IonButton,
  IonButtons,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonModal,
  IonRow,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import { giftOutline, pencilSharp } from "ionicons/icons";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import "../Shared/theme/style.css";
import SpecialOccasionItem from "./SpecialOccasionItem";
import vipService from "../Shared/services/VIPService";

interface Props {
  linkable?: boolean;
  avatar?: boolean;
  dependent?: any;
  index?: any;
}

export default function DependentItem(props: Props) {
  const [modal, _modal] = useState(false);
  const [newName, _newName] = useState<any>();
  const [newDate, _newDate] = useState<any>();
  const [specials, _specials] = useState<any>();
  const history = useHistory();

  const birthdayItem = {
    id: 0,
    name: "Birthday*",
    datemmddyyyy: props.dependent.birthdatemmddyyyy,
  };

  useEffect(() => {
    // call api or anything
    vipService.getSpecialOccasions(props.dependent.id).then((res) => {
      _specials(res.data);
    });
  }, []);

  function specList(specials: any) {
    if (specials !== null) {
      const specdata = _.orderBy(specials, ["name", "asc"]);
      return specdata.map((spec: any, index: number) => {
        return (
          <SpecialOccasionItem
            key={index}
            specoc={spec}
            deleteBtn={true}
            reload={(e) => {
              const newSpecs = specials.filter((s: any) => s.id !== e);
              _specials(newSpecs);
            }}
          />
        );
      });
    }
  }

  return (
    <IonItem
      lines="none"
      className="ion-no-padding ion-no-lines"
      key={props.index}
    >
      <IonLabel>
        <IonGrid className="ion-no-padding">
          <IonRow
            onClick={() => {
              history.push("/dependent/" + props.dependent.id, {
                dependent: props.dependent,
              });
            }}
          >
            <IonCol>
              <div className="section-header">{props.dependent.name}</div>
              <div className="sub-label">{props.dependent.relationship}</div>
            </IonCol>
            <IonCol style={{ textAlign: "right" }}>
              <IonIcon
                color="primary"
                style={{ fontSize: 22 }}
                icon={pencilSharp}
              />
            </IonCol>
          </IonRow>
          <IonRow style={{ paddingTop: 16, width: "100%", textAlign: "right" }}>
            <IonCol style={{ width: "100%", textAlign: "right" }}>
              <IonButton
                onClick={() => {
                  _modal(true);
                }}
                expand="full"
                color="light"
              >
                <IonIcon icon={giftOutline} /> &nbsp; Special Occasions
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
        <IonModal isOpen={modal} onWillDismiss={(ev) => _modal(false)}>
          <IonHeader>
            <IonToolbar>
              <IonTitle>Special Occasions</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => _modal(false)}>Done</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonCard
              style={{
                border: "1px solid black",
                borderBottom: "3px solid black",
                borderRight: "2px solid black",
              }}
            >
              <IonCardContent>
                <IonItem>
                  <IonLabel position="stacked">Add New Date</IonLabel>
                  <IonInput
                    type="text"
                    placeholder="Enter Name"
                    value={newName}
                    onIonChange={(e) => {
                      _newName(e.target.value);
                    }}
                  ></IonInput>
                </IonItem>
                <IonItem>
                  <IonInput
                    type="date"
                    placeholder="Enter Date"
                    value={newDate}
                    onIonChange={(e) => {
                      _newDate(e.target.value);
                    }}
                  ></IonInput>
                </IonItem>

                <IonButton
                  style={{ marginTop: 16 }}
                  expand="block"
                  disabled={!newName || !newDate}
                  color={"dark"}
                  size="small"
                  slot="end"
                  onClick={(e) => {
                    const nd = {
                      id: 0,
                      vipId: props.dependent.id,
                      name: newName,
                      date: new Date(newDate).toISOString(),
                    };
                    if (
                      newName !== null &&
                      newDate !== null &&
                      newName !== undefined &&
                      newDate !== undefined
                    ) {
                      vipService.postSpecialOccasion(nd).then((res) => {
                        const newSpecs = specials;
                        newSpecs.push(res.data);
                        _specials(newSpecs);
                        _newName("");
                        _newDate("");
                      });
                    }
                  }}
                >
                  Add
                </IonButton>
              </IonCardContent>
            </IonCard>

            {specials && (
              <SpecialOccasionItem deleteBtn={false} specoc={birthdayItem} />
            )}
            {specials && specList(specials)}
          </IonContent>
        </IonModal>
      </IonLabel>
    </IonItem>
  );
}
