import _ from "lodash";
import { useHistory } from "react-router";
import { useRecoilState } from "recoil";
import { favoritesBenefitsState } from "../Shared/services/FavoriteService";
import "../Shared/theme/style.css";
import Card from "../Shared/Card";

interface Props {
  benefits: any;
  favorites: any;
  favorited: () => void;
}

export default function BenefitsFavorites(props: Props) {
  // console.log("Favlist Benefits:", props.benefits);
  // console.log("Favlist Favorites:", props.favorites);
  // console.log("FavList Favorites:", props.favorites.favIds);
  const [favorites, _favorites] = useRecoilState<any>(favoritesBenefitsState);

  const history = useHistory();
  const cardWidth = 150;
  const cardHeight = 150;

  function FavoriteList(favs: any) {
    if (favs !== null) {
      const favdata = _.orderBy(favs, ["date", "dsc"]);
      //TODO
      // console.log(favdata);
      return favdata.map((favorite: any, index: number) => {
        let sf = false;
        //TODO
        // console.log(favorite);

        if (favorites && favorites.favIds.includes(favorite.id)) {
          sf = true;
        } else {
          return null;
        }
        return (
          <Card
            key={index}
            id={favorite.id}
            title={favorite.name}
            // date={benefit.updatedDatemmddyyyy}
            picture={favorite.picture}
            showFavorite={true}
            favorite={favorites.favIds?.includes(favorite.id)}
            categoryId={2}
            favorited={() => {
              _favorites({
                favIds: favorites.favIds.filter((x: any) => x != favorite.id),
              });
              props.favorited();
            }}
            clicked={() => {
              history.push("/benefit/" + favorite.id);
            }}
            width={cardWidth}
            height={cardHeight}
          />
        );
      });
    }
  }

  return (
    <div className="">
      <div
        className="benefits-header padding"
        style={{ paddingLeft: 22, fontWeight: 500 }}
      >
        <span className="rubik">Your Favorites</span>
      </div>
      <div className="favorite-cards hide-scrollbar">
        <div className="hide-scrollbar" style={{ width: "100vw" }}>
          <div className="horizontal-container hide-scrollbar">
            {props.benefits.length != 0 ? (
              FavoriteList(props.benefits)
            ) : (
              <div
                style={{
                  border: "1px solid black",
                  borderBottom: "3px solid black",
                  borderRight: "2px solid black",
                  width: 150,
                  height: 150,
                  borderRadius: 10,
                  textAlign: "center",
                  overflow: "hidden",
                  wordBreak: "break-word",
                  backgroundColor: "#eee",
                  boxShadow: "0px 0px 2px 0px rgba(0,0,0,0.75)",
                  fontWeight: 500,
                  paddingTop: 30,
                }}
              >
                <i
                  className="bi bi-heart"
                  style={{ color: "black", fontSize: 30 }}
                ></i>
                <br />
                Your favorites <br /> will appear here.
                <br />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
