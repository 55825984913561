import _ from "lodash";
import "../Shared/theme/style.css";
import { useRef } from "react";

interface Props {
  color?: string;
  amount?: number | null;
  name: string;
  last4?: string;
  type: string;
}

export default function PlasticCard(props: Props) {
  const refCardNumber = useRef<HTMLDivElement>(null);

  return (
    <div style={{ position: "relative", maxWidth: 400 }}>
      <img
        src={props.type == "regalos" ? "/assets/card.png" : "/assets/card.png"}
        style={{ width: "100%" }}
      />

      <div
        className="plastic-1"
        style={{
          color: "white",
          fontSize: 22,
          position: "absolute",
          top: 28,
          left: 34,
        }}
      >
        ${props.amount && (props.amount * 0.01).toFixed(2)}
      </div>
      {props.type == "virtual" && (
        <>
          <div
            className="plastic-1"
            id={"card-number"}
            style={{
              color: "white",
              fontSize: 8,
              position: "absolute",
              bottom: 58,
              left: 34,
              width: 180,
            }}
          >
            <div style={{ fontSize: 16, letterSpacing: 2 }} ref={refCardNumber}>
              **** **** **** {props.last4 ? props.last4 : "****"}
            </div>
          </div>
          <div
            id={"card-exp"}
            className="plastic-1"
            style={{
              textAlign: "left",
              color: "white",
              position: "absolute",
              bottom: 28,
              left: 34,
              fontSize: 14,
              width: 60,
            }}
          >
            EXP
          </div>
          <div
            className="plastic-1"
            id={"card-cvc"}
            style={{
              textAlign: "left",
              color: "white",
              position: "absolute",
              bottom: 28,
              left: 120,
              fontSize: 14,
              width: 60,
            }}
          >
            CVC
          </div>
          {/* <div
            className="plastic-1"
            id={"card-number-copy"}
            style={{
              textAlign: "left",
              color: "white",
              position: "absolute",
              bottom: 56,
              right: 20,
              fontSize: 14,
              width: 60,
            }}
          ></div> */}
        </>
      )}

      {props.type == "physical" && (
        <>
          <div
            className="plastic-1"
            id={"p-card-number"}
            style={{
              color: "white",
              fontSize: 8,
              position: "absolute",
              bottom: 58,
              left: 34,
            }}
          >
            <div style={{ fontSize: 16, letterSpacing: 2 }}>
              **** **** **** {props.last4 ? props.last4 : "****"}
            </div>
          </div>
          <div
            id={"p-card-exp"}
            className="plastic-1"
            style={{
              textAlign: "left",
              color: "white",
              position: "absolute",
              bottom: 28,
              left: 34,
              fontSize: 14,
            }}
          >
            EXP
          </div>
          <div
            className="plastic-1"
            id={"p-card-cvc"}
            style={{
              textAlign: "left",
              color: "white",
              position: "absolute",
              bottom: 28,
              left: 120,
              fontSize: 14,
            }}
          >
            CVC
          </div>
        </>
      )}

      {props.type == "regalos" && (
        <>
          <div
            className="plastic-1"
            id={"p-card-number"}
            style={{
              color: "white",
              fontSize: 8,
              position: "absolute",
              bottom: 58,
              left: 34,
            }}
          ></div>
          <div
            id={"p-card-exp"}
            className="plastic-1"
            style={{
              textAlign: "left",
              color: "white",
              position: "absolute",
              bottom: 28,
              left: 34,
              fontSize: 14,
            }}
          >
            Regalos
          </div>
        </>
      )}
      {/* 
      <div
        style={{
          textAlign: "right",
          color: "white",
          fontWeight: "bold",
          position: "absolute",
          bottom: 20,
          left: 10,
        }}
      >
        {props.name}
      </div> */}
    </div>
  );
}
