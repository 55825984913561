import { IonButton, IonContent, IonPage, IonText } from "@ionic/react";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import "../Shared/theme/style.css";
import WizardProgress from "./WizardProgress";
import memberService from "../Shared/services/MemberService";
import MaxwellForm from "../Shared/services/MaxwellForm";
import { DebouncedAutocomplete } from "./_components/debounce-autocomplete";

interface Props {
  prevStep?: any;
  nextStep?: any;
  handleChange: (input: any, e: any) => void;
  values?: any;
  linkable?: boolean;
}

export default function OBAddress(props: Props) {
  const [address1, _address1] = useState<any>();
  const [address2, _address2] = useState<any>();
  const [city, _city] = useState<any>();
  const [country, _country] = useState<any>();
  const [state, _state] = useState<any>();
  const [zip, _zip] = useState<any>();

  const [member, _member] = useState<any>();

  const states = [
    { id: "AL", name: "AL" },
    { id: "AK", name: "AK" },
    { id: "AZ", name: "AZ" },
    { id: "AR", name: "AR" },
    { id: "CA", name: "CA" },
    { id: "CZ", name: "CZ" },
    { id: "CO", name: "CO" },
    { id: "CT", name: "CT" },
    { id: "DE", name: "DE" },
    { id: "DC", name: "DC" },
    { id: "FL", name: "FL" },
    { id: "GA", name: "GA" },
    { id: "GU", name: "GU" },
    { id: "HI", name: "HI" },
    { id: "ID", name: "ID" },
    { id: "IL", name: "IL" },
    { id: "IN", name: "IN" },
    { id: "IA", name: "IA" },
    { id: "KS", name: "KS" },
    { id: "KY", name: "KY" },
    { id: "LA", name: "LA" },
    { id: "ME", name: "ME" },
    { id: "MD", name: "MD" },
    { id: "MA", name: "MA" },
    { id: "MI", name: "MI" },
    { id: "MN", name: "MN" },
    { id: "MS", name: "MS" },
    { id: "MO", name: "MO" },
    { id: "MT", name: "MT" },
    { id: "NE", name: "NE" },
    { id: "NV", name: "NV" },
    { id: "NH", name: "NH" },
    { id: "NJ", name: "NJ" },
    { id: "NM", name: "NM" },
    { id: "NY", name: "NY" },
    { id: "NC", name: "NC" },
    { id: "ND", name: "ND" },
    { id: "OH", name: "OH" },
    { id: "OK", name: "OK" },
    { id: "OR", name: "OR" },
    { id: "PA", name: "PA" },
    { id: "PR", name: "PR" },
    { id: "RI", name: "RI" },
    { id: "SC", name: "SC" },
    { id: "SD", name: "SD" },
    { id: "TN", name: "TN" },
    { id: "TX", name: "TX" },
    { id: "UT", name: "UT" },
    { id: "VT", name: "VT" },
    { id: "VI", name: "VI" },
    { id: "VA", name: "VA" },
    { id: "WA", name: "WA" },
    { id: "WV", name: "WV" },
    { id: "WI", name: "WI" },
    { id: "WY", name: "WY" },
  ];

  const countries = [
    { id: "US", name: "US" },
    // { id: "MX", name: "MX" },
    // { id: "CA", name: "CA" },
  ];

  useEffect(() => {
    memberService.getMember().then((res) => {
      _member(res.data);
    });

    memberService.getAddresses().then((res) => {
      if (res.data && res.data[0]) {
        const address = res.data[0];
        _address1(address.address1);
        _address2(address.address2);
        _city(address.city);
        _state(address.state);
        _zip(address.zip);
        _country(address.country);
      }
    });
  }, []);

  // Form Validation Handling
  const [formErrors, _formErrors] = useState<any>([""]);
  function HandleInputErrors(errs: any) {
    const errors = formErrors.filter(
      (x: any) => x !== "" //|| x !== "Missing Required Fields"
    );
    if (errs.valid) {
      _formErrors(errors.filter((x: any) => x !== errs.error));
    } else {
      _formErrors([...errors, errs.error]);
    }
  }

  const history = useHistory();
  const Continue = (e: { preventDefault: () => void }) => {
    memberService
      .postAddress({
        name: "Home",
        address1,
        address2,
        city,
        state,
        zip,
        country: country || "US",
      })
      .then((res) => {
        if (res.data) {
          memberService.balanceMember();
          e.preventDefault();
          props.nextStep();
        }
      });
  };

  const Back = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    props.prevStep();
  };

  return (
    <IonPage>
      <div className="main-container">
        {/* <OBHeader prevStep={props.prevStep} /> */}
        <IonContent>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "90%",
                marginRight: "auto",
                marginLeft: "auto",
                paddingTop: 30,
              }}
            >
              <WizardProgress
                list={[
                  {
                    icon: "bi-telephone",
                    complete: true,
                    clicked: () => props.prevStep(1),
                  },
                  {
                    icon: "bi-person",
                    complete: true,
                    clicked: () => props.prevStep(2),
                  },
                  { icon: "bi-house", complete: true },

                  { icon: "bi-camera" },
                  { icon: "bi-lightning" },
                  { icon: "bi-list-ul" },
                  { icon: "bi-person-gear" },
                ]}
              />
              <IonText>
                <h1 style={{ paddingTop: 30 }}>Where is home?</h1>
                <h3>
                  Almost there! We need your address so we know where to send
                  stuff.
                </h3>
              </IonText>

              <DebouncedAutocomplete
                label="Address"
                placeholder="Enter address"
                value={address1}
                validation={(data) => {
                  HandleInputErrors(data);
                }}
                onChange={(value) => {
                  _address1(value.address1);
                  value.address2 && _address2(value.address2);
                  value.city && _city(value.city);
                  value.state && _state(value.state);
                  value.zipCode && _zip(value.zipCode);
                  value.country && _country(value.country);
                }}
                required
              />

              <MaxwellForm.Input
                label="Address 2"
                placeholder="Enter address line 2"
                value={address2}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _address2(value);
                }}
                autoCapitalize="words"
              />

              <MaxwellForm.Input
                label="City"
                placeholder="Enter city"
                value={city}
                required={true}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _city(value);
                }}
                autoCapitalize="words"
              />

              <MaxwellForm.Select
                label="State"
                options={states}
                value={state}
                required={true}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _state(value);
                }}
              />

              <MaxwellForm.Input
                label="Zip Code"
                placeholder="Enter zip code"
                value={zip}
                required={true}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _zip(value);
                }}
              />

              <MaxwellForm.Select
                label="Country"
                options={countries}
                value={country}
                required={true}
                validation={(data: any) => {
                  HandleInputErrors(data);
                }}
                onChange={(value: string) => {
                  _country(value);
                }}
              />

              <IonButton
                style={{ marginTop: 40 }}
                onClick={Continue}
                disabled={formErrors.length !== 0 ? true : false}
                expand="block"
                color="dark"
              >
                Continue
              </IonButton>
              <IonButton
                style={{ marginTop: 20 }}
                className="backButton"
                expand="block"
                onClick={Back}
                color="light"
              >
                Back
              </IonButton>
              {formErrors.map((error: any) => {
                return (
                  <IonText key={error} color="danger" className="mw-center">
                    <p>{error}</p>
                  </IonText>
                );
              })}
            </div>
          </div>
        </IonContent>
      </div>
    </IonPage>
  );
}
